import React from 'react'
import Loading from './Loading';

const ConfirmationModal = ({
  title,
  body,
  cancelButtonLabel,
  confirmButtonLabel,
  cancelButtonFn,
  confirmButtonFn,
  isLoading,
  id
}) => {
  return (
    <div className="modal show" id={`my-modal-${id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={
        {display: 'block'}
    }>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`title-modal-${id}`}>
              {title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
              onClick={cancelButtonFn}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {body} </div>
          <div className="modal-footer">
            {
            isLoading && <Loading/>
          }
            <button type="button" className="btn btn-secondary" data-dismiss="modal"
              onClick={cancelButtonFn}>
              {cancelButtonLabel}</button>
            <button type="button" className="btn btn-primary"
              onClick={confirmButtonFn}>
              {confirmButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
