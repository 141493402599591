import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const RentAndYieldChart = ({data}) => {

  // Example data
data = [
  { year: '2018', rentPrice: 450, yield: 5.0 },
  { year: '2019', rentPrice: 450, yield: 5.2 },
  { year: '2020', rentPrice: 500, yield: 5.1 },
  { year: '2021', rentPrice: 550, yield: 5.8 },
  { year: '2022', rentPrice: 600, yield: 6.2 },
  { year: '2023', rentPrice: 600, yield: 6.5 },
];

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        data={data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis yAxisId="left" label={{ value: 'Rent Price (€)', angle: -90, position: 'insideLeft' }} />
        <YAxis yAxisId="right" orientation="right" label={{ value: 'Yield (%)', angle: -90, position: 'insideRight' }} />
        <Tooltip />
        <Legend />
        
        {/* Rent Price Line */}
        <Line yAxisId="left" type="monotone" dataKey="rentPrice" stroke="#8884d8" activeDot={{ r: 8 }} name="Rent Price (€)" />

        {/* Yield Line */}
        <Line yAxisId="right" type="monotone" dataKey="yield" stroke="#82ca9d" name="Yield (%)" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RentAndYieldChart;
