import React from 'react'
import TimelineEvent from './TimelineEvent.js';
import '../../../styles/timeline.css';

// Timeline Component
const Timeline = ({ events }) => {
  return (
    <div className="timeline-container">
      <div className="timeline-bar">
        {/* Add months or any desired markers */}
        <span>Jan</span>
        <span>Feb</span>
        <span>Mar</span>
        <span>Apr</span>
        <span>May</span>
        <span>Jun</span>
        <span>Jul</span>
        <span>Aug</span>
        <span>Sep</span>
        <span>Oct</span>
        <span>Nov</span>
        <span>Dec</span>
      </div>

      <div className="events-container">
        {events.map((event, index) => (
          <TimelineEvent key={index} event={event} />
        ))}
      </div>
    </div>
  );
};

export default Timeline
