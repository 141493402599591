import React, { useRef, useEffect } from 'react';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { GOOGLE_API_KEY } from '../../../Config.js';
import UnitCard from '../../unit/UnitCard.js';
import ReactDOMServer from 'react-dom/server';
import Loading from '../Loading.js';

const loaderOptions: LoaderOptions = {
  apiKey: GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['places'],
};

const MapWithPins = ({ data, isLoaded }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loader = new Loader(loaderOptions);

    loader.load().then(() => {
      initializeMap();
    });
  }, []);

  const initializeMap = () => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 48.1657, lng: 11.4515 }, // Center of Europe (approximately)
      zoom: 4.1,
    });

    let infoWindow = null;
    data.forEach((unit) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: unit.address + ' ' + unit.city.name}, (results, status) => {
        if (status === 'OK' && results.length > 0) {
          const marker = new window.google.maps.Marker({
            position: results[0].geometry.location,
            map,
            title: unit.identifier,
          });

          marker.addListener('click', () => {
            if (infoWindow != null) {
              infoWindow.close();
            }
            infoWindow = new window.google.maps.InfoWindow({
              content: '',
            });
            const unitComponent = <UnitCard {...unit} identifier_image_url={null} />;
            const content = ReactDOMServer.renderToString(unitComponent);
            infoWindow.setContent(content);
            infoWindow.open({
              //@ts-ignore
              anchor: marker
            });
          });
        }
      });
    });
  };

  if (!isLoaded) {
    return <Loading/>;
  }

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
};

export default MapWithPins;