import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'

const RealEstateAgentAddPage = () => {
  return (
    <>
      <PageHeading heading="Add real estate agent"/>
      <Form formID="real-estate-agent-add"
        successMessage={'Real estate agent was added'}/>
    </>
  )
}

export default RealEstateAgentAddPage
