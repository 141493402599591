import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import TopNavigationItem from './TopNavigationItem.js';
import TopNavigationItemWithDropdown from './TopNavigationItemWithDropdown.js';
import {LIST_NOTIFICATION} from '../../../graph-ql/notification.js';
import NotificationContainer from '../notification/NotificationContainer.js';
// import TaskList from '../../task/TaskList.js';
import RecordIncomeAction from '../action/RecordIncomeAction.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import { doorIcon, postboxIcon, tasksIcon, justifyIcon } from '../../../assets/svg/icons.js';
// import { LIST_TASK } from '../../../graph-ql/unit_task.js';
import logo from '../../../assets/images/logo.png'
import { Link } from 'react-router-dom';

const TopNavigation = () => {
  const [notifications, setNotifications] = useState([]);
  // const [tasks, setTasks] = useState([]);
  
  const [shouldShowNotification, setShouldShowNotification] = useState(false);
  // const [shouldShowTasks, setShouldShowTasks] = useState(false);

  const loggedInUser = useSelector((state) => state.loggedInUser);
  const isLoggedIn = loggedInUser.isLoggedIn;

  const graphQLClient = GraphQLClientWithCredentials();

  const fetchNotifications = async () => {
    try {
      const response = await graphQLClient.request(LIST_NOTIFICATION);
      if (response.notification_list && response.notification_list.length) {
        setNotifications(response.notification_list);
      }
    } catch (err) {}
  };

  // const fetchTasks = async () => {
  //   try {
  //     const response = await graphQLClient.request(LIST_TASK);
  //     if (response.unit_task_list && response.unit_task_list.length) {
  //       setTasks(response.unit_task_list);
  //     }
  //   } catch (err) {}
  // };

  const quickActionItems = [{
      label: 'Record income',
      value: <RecordIncomeAction/>},];

  return (
    <>
      <div className="d-flex top-nav-container">
        <Link to='/'>
          <img src={logo} className="logo" />
        </Link>
        <ul className="top-nav nav nav-pills">
          <TopNavigationItem requiresLogin={false}
            label="Register"
            path="register"
            isLoggedIn={isLoggedIn}/>
          <TopNavigationItem requiresLogin={false}
            label="Login"
            path="login"
            isLoggedIn={isLoggedIn}/>
          <TopNavigationItem requiresLogin={true}
            label={doorIcon}
            path="logout"
            isLoggedIn={isLoggedIn}/>
          {/* <TopNavigationItem requiresLogin={true}
            label={tasksIcon}
            onCompleteLoading={fetchTasks}
            handleClick={
              () => !shouldShowTasks ? setShouldShowTasks(true) : setShouldShowTasks(false)
            }
            isLoggedIn={isLoggedIn}/> */}
          <TopNavigationItem requiresLogin={true}
            label={postboxIcon}
            path="notifications"
            isLoggedIn={isLoggedIn}
            onCompleteLoading={fetchNotifications}
            hasBadging={
              notifications.length > 0
            }
            handleClick={
              () => !shouldShowNotification ? setShouldShowNotification(true) : setShouldShowNotification(false)
            }/>
          <TopNavigationItemWithDropdown requiresLogin={true}
            label={justifyIcon}
            isLoggedIn={isLoggedIn}
            items={quickActionItems}/>
        </ul>
      </div>
      {notifications.length > 0 && shouldShowNotification && <NotificationContainer data={notifications}/>}
      {/* {tasks.length > 0 && shouldShowTasks && <TaskList data={tasks}/>} */}
    </>
  )
}

export default TopNavigation
