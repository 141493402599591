import React, { useState } from "react";
import IgnoreAction from "../common/action/IgnoreAction.js"
import RenewTenancyContractAction from "../common/action/RenewTenancyContractAction.js";
import PutOnMarketAction from "../common/action/PutOnMarketAction.js";
import ConfirmRentReceivedAction from "../common/action/ConfirmRentReceivedAction.js";
import MarkTenantAsCheckedOutAction from "../common/action/MarkTenantAsCheckedOutAction.js";
import MarkTaskAsDoneAction from "../common/action/MarkTaskAsDoneAction.js";
import GraphQLClientWithCredentials from "../../services/GraphQLClientWithCredentials.js";
import { DO_TASK } from "../../graph-ql/unit_task.js";
import Loading from "../common/Loading.js";

const TaskActions = ({
  taskType,
  taskID,
  isVisibleHandler
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleActionClick = async (action_type) => {
    setSubmitLoading(true);
    const graphQLClient = GraphQLClientWithCredentials();
    let mutationData = {
      'id': taskID,
      'action_type': action_type,
    };
    
    let result = null;
    try {
      result = await graphQLClient.request(DO_TASK, mutationData);
      
      setSubmitLoading(false);
      if (result !== null) {
        isVisibleHandler(false);
        
      }
    } catch (error) {
      console.error('Error performing mutation:', error);
      setSubmitLoading(false);
      throw error;
    }
  }

  const actions = {
    'tenancy_contract_expires': [
      <RenewTenancyContractAction type="button" taskID={taskID} key="renew-tenancy" />,
      <IgnoreAction taskID={taskID} key="ignore" handleClick={() => handleActionClick('ignore')} />,
      <MarkTaskAsDoneAction taskID={taskID} key="mark-task-as-done" handleClick={() => handleActionClick('mark-task-as-done')} />
    ],
    'tenancy_contract_expired': [
      <RenewTenancyContractAction taskID={taskID} key="renew-tenancy" />,
      <MarkTenantAsCheckedOutAction taskID={taskID} key="mark-tenant-checked-out" handleClick={() => handleActionClick('mark_tenant_as_checked_out')}/>,
      <IgnoreAction taskID={taskID} key="ignore" handleClick={() => handleActionClick('ignore')} />,
      <MarkTaskAsDoneAction taskID={taskID} key="mark-task-as-done" handleClick={() => handleActionClick('mark-task-as-done')} />
    ],
    'no_tenant': [
      <PutOnMarketAction taskID={taskID} key="put-on-market" handleClick={() => handleActionClick('put_on_market')}/>,
      <IgnoreAction taskID={taskID} key="ignore" handleClick={() => handleActionClick('ignore')}/>,
      <MarkTaskAsDoneAction taskID={taskID} key="mark-task-as-done" handleClick={() => handleActionClick('mark-task-as-done')} />
    ],
    'confirm_receipt_of_rent': [
      <ConfirmRentReceivedAction taskID={taskID} key="confirm-rent-received" handleClick={() => handleActionClick('confirm_rent_received')} />,
      <IgnoreAction taskID={taskID} key="ignore" handleClick={() => handleActionClick('ignore')}/>,
      <MarkTaskAsDoneAction taskID={taskID} key="mark-task-as-done" handleClick={() => handleActionClick('mark-task-as-done')} />
    ],
    'custom': [
      <IgnoreAction taskID={taskID} key="ignore" handleClick={() => handleActionClick('ignore')}/>,
      <MarkTaskAsDoneAction taskID={taskID} key="mark-task-as-done" handleClick={() => handleActionClick('mark-task-as-done')} />
    ]
  };

  if (submitLoading) {
    return <Loading />
  }

  return actions[taskType];
};


export default TaskActions;
