import React from 'react';
import SingleComponentWithLoading from './common/SingleComponentWithLoading.js';
import LineChartComponent from './common/chart/LineChartComponent.js';

const IncomeChart = ({data, loading, error, goal = 0, showForecast, showExpenses, title, className, label, showLegend = true}) => {
  return <SingleComponentWithLoading data={data}
    error={error}
    loading={loading}
    goal={goal}
    showExpenses={showExpenses}
    showForecast={showForecast}
    title={title}
    className={className}
    showLegend={showLegend}
    label={label}
    componentToRender={LineChartComponent}/>
};

export default IncomeChart;
