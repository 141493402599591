import {gql} from 'graphql-request';

// todo make this a non mutation
export const RUN_PROPCHECKER = gql `query propchecker(
  $neighbourhood_id: ID!,
  $price: Int,
) {
  propchecker(
    neighbourhood_id: $neighbourhood_id,
    price: $price
  ) {
      id,
      neighbourhood_id,
      price,
      number_of_rooms,
      annual_yield
  }
}`;
