import React from 'react'
import TooltipComponent from '../common/TooltipComponent.js'
import { movedOutIcon } from '../../assets/svg/icons.js'
import { formatDate } from '../../services/Format.js'

const TenantMovedOutIndicator = ({moveOutDate}) => {
  return (
    <TooltipComponent 
      tooltipTriggerText={movedOutIcon}
      tooltipText={"Moved out on " + formatDate(moveOutDate)}
      icon='none'
    />
  )
}

export default TenantMovedOutIndicator