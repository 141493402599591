import React, {useState, useEffect} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import { useParams } from 'react-router-dom'
import { formatPriceInEUR } from '../../services/Format.js'
import { getConsecutiveDates } from '../../services/Time.js'
import RouteActionType from '../../enums/RouteActionType.js'

const TenantMutatePage = ({tenantID, action, extraData, formSubmitCallbackFn}) => {
  let {id} = useParams(); 
  if (extraData['main-page'] === 'unit-tenant') {
    if (tenantID !== null) {
      id = tenantID;
    } else {
      id = null;
    }
  }

  const [recordPastIncomeInfo, setRecordPastIncomeInfo] = useState('');
  const [rentDueDayFromStartDate, setRentDueDayFromStartDate] = useState(null);
  const [endDateFromStartDate, setEndDateFromStartDate] = useState(null);
  const [depositValueFromRentPrice, setDepositValueFromRentPrice] = useState(null);
  const [rentPrice, setRentPrice] = useState(null);
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (rentDueDayFromStartDate !== null && startDate !== null) {
      const futureDateObj = new Date(startDate);
      futureDateObj.setFullYear((new Date(startDate)).getFullYear() + 1);
      setEndDateFromStartDate(futureDateObj);
    }
    setDepositValueFromRentPrice(rentPrice);
  }, [rentDueDayFromStartDate, startDate, rentPrice]);

  const onElementUpdate = (formData, lastChangedElement) => {
    if (lastChangedElement && lastChangedElement.target.name === 'rent_price') {
      setRentPrice(formData['rent_price']);
    }
    
    const startDate = formData['contract_start_date'];
    if (startDate === undefined) {
      setRecordPastIncomeInfo('');
      return;
    }

    if (lastChangedElement && lastChangedElement.target.name === 'contract_start_date') {
      setStartDate(startDate);
      setRentDueDayFromStartDate((new Date(startDate)).getDate());
    } else if (lastChangedElement && lastChangedElement.target.name === 'rent_due_day') {
      setRentDueDayFromStartDate(formData['rent_due_day']);
    }

    if (formData['record_past_income'] !== 'on') {
      setRecordPastIncomeInfo('');
      return;
    }

    const rentPrice = parseInt(formData['rent_price']);
    if (rentPrice <= 0 || isNaN(rentPrice)) {
      setRecordPastIncomeInfo('');
      return;
    }

    const rentDueDay = parseInt(formData['rent_due_day']);
    if (rentDueDay <= 0 || isNaN(rentDueDay)) {
      setRecordPastIncomeInfo('');
      return;
    }

    const dates = getConsecutiveDates(rentDueDay, (new Date(startDate)).toLocaleDateString('en-GB'));
    if (!dates.length) {
      setRecordPastIncomeInfo('');
      return;
    }
    setRecordPastIncomeInfo('Will record ' + formatPriceInEUR(dates.length * rentPrice) + ' total income');
  }

  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update tenant' : 'Add tenant'}/>
      <Form 
        objectID={parseInt(id) || 0}
        formID={'tenant-mutate'}
        successMessage={action === RouteActionType.edit ? 'Tenant data was updated' : 'Tenant was added'}
        extraData={extraData}
        onChangeHandler={onElementUpdate}
        formSubmitCallback={formSubmitCallbackFn}
        formInfoElements={{frm_el_record_past_income: recordPastIncomeInfo}}
        updatedFormValues={{
          frm_el_rent_due_day: rentDueDayFromStartDate,
          frm_el_contract_end_date: endDateFromStartDate,
          frm_el_deposit_value: depositValueFromRentPrice,
        }}
      />
    </>
  )
}

export default TenantMutatePage
