import React from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'

const UnitUtilityMutatePage = ({id, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading="Select utilities for this property" />
      <Form 
        formID="unit-utility-mutate"
        successMessage={'Utilities were updated'}
        objectID={parseInt(id) || 0}
        extraData={extraData}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitUtilityMutatePage
