import React from 'react'
import PageHeading from '../components/common/PageHeading.js'
import DashboardContainer from '../containers/DashboardContainer.js'

const DashboardPage = () => {
  return (
    <>
      <PageHeading heading="Account overview"/>
      <DashboardContainer/>
    </>
  )
}

export default DashboardPage
