import React from 'react'
import { useNavigate } from 'react-router-dom';
import { formatPriceInEUR } from '../../services/Format';
import UnitViewListingsSimple from './view/listing/UnitViewListingsSimple.js';
import UnitIdentifier from './fragments/UnitIdentifier.js';
import { houseIcon } from '../../assets/svg/icons.js';

const UnitsWithNoTenantsList = ({data, listings, isListingsLoaded}) => {
  const navigate = useNavigate();
  if (data === null || data === undefined || data.length === 0) {
    return null;
  }

  const addListingHandler = (id) => {
    navigate('/property/listing/add/' + id);
  }

  const addTenantHandler = (id) => {
    navigate('/property/tenant/add/' + id);
  }

  const actions = [
    {
      showCondition: (isListed) => {
        return isListed ? false : true;
      },
      key: 'put-on-market',
      label: 'Put on market',
      handleClick: (id) => {
        
      }
    }, {
      showCondition: (isListed) => {
        return isListed ? false : true;
      },
      key: 'find-real-estate-agents',
      label: 'Find real estate agents',
      handleClick: (id) => {
        navigate('/real-estate-agents');
      }
    },
    {
      showCondition: (isListed) => {
        return isListed ? true : false;
      },
      key: 'view-listings',
      label: 'View listings',
      handleClick: (id) => {
        navigate('/property/view/' + id);
      }
    },
  ];

  return (
    <div className="container-fluid d-flex">
      {data.map(unit => {
        if (unit.current_number_of_tenants <= 0) {
          return <div className="card me-2 col-6" key={unit.id}>
            <div className="d-flex card-body">
              <div className="me-2">
                {unit.identifier_image_url !== null && <img 
                  src={unit.identifier_image_url}
                  className="img-fluid rounded-start"
                  alt="..."
                  style={{maxWidth: "100px"}}
                />}
              </div>
              <div>
                <p>
                  <span className="me-1">{houseIcon}</span>
                  <UnitIdentifier key={unit.id} id={unit.id} identifier={unit.identifier} />
                </p>
                <p><i>Found a tenant? <a href="" onClick={() => addTenantHandler(unit.id)}>Add them now</a>.</i></p>
                {unit.potential_income > 0 && <p>Potential income: {formatPriceInEUR(unit.potential_income)}</p>}
                {isListingsLoaded && !listings.hasOwnProperty(unit.id) && <p><i>Not listed. <a href="" onClick={() => addListingHandler(unit.id)}>Add a new listing</a> now to optimise earnings.</i></p>}
                {isListingsLoaded && listings.hasOwnProperty(unit.id) && <UnitViewListingsSimple listings={listings[unit.id]} />}
              </div>
              <div className="ms-auto p-2">
                <div className="nav-item dropdown position-relative">
                  <a
                    className="position-absolute top-100 start-50 translate-middle mt-1"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                    </svg>
                  </a>
                  <ul className="dropdown-menu">
                    {
                    actions.map(action => {
                      return action.showCondition(listings.hasOwnProperty(unit.id)) && <li key={
                        action.key
                      }>
                        <a className="dropdown-item"
                          onClick={
                            () => action.handleClick(unit.id)
                        }>
                          {
                          action.label
                        }</a>
                      </li>
                  })
                  } 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      })}
    </div>
  )
}

export default UnitsWithNoTenantsList