import React, {useState, useEffect} from 'react'

const FormSelectElement = ({
  name, 
  initialValue, 
  onChangeHandler, 
  options, 
  id,
  additionalClassName,
  datatype,
  isDisabled,
}) => {
  const [value, setValue] = useState(initialValue !== null ? initialValue : (options.hasOwnProperty(name) && options[name].length > 0 ? options[name][0].value : ''));

  useEffect(() => {
    setValue(initialValue !== null ? initialValue : (options.hasOwnProperty(name) && options[name].length > 0 ? options[name][0].value : ''));
  }, [initialValue]);

  if (!options.hasOwnProperty(name) || options[name] === undefined || options[name].length === 0) {
    return;
  }

  const optionsElement = options[name].map(v => {
    return (
      <option key={
          v.value
        }
        value={
          v.value
      }>
        {
        v.label
      }</option>
    )
  });

  return (
    <select name={name}
      onChange={(e) => {setValue(e.target.value); onChangeHandler(e)}}
      value={value}
      data-type={datatype}
      className={`form-select ${additionalClassName}`}
      id={id}
      disabled={isDisabled}>
      {optionsElement} 
    </select>
  )
}

export default FormSelectElement
