import React, {useState} from 'react'
import {formatDate} from '../../../../services/Format.js';
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import {DELETE_UNIT_INVENTORY} from '../../../../graph-ql/unit_inventory.js';
import UnitInventoryTimeToReplace from '../../fragments/UnitInventoryTimeToReplace';
import { useFileDownload } from '../../../../hooks/useFileDownload.js';

const UnitInventoryListTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();
  const downloadFile = useFileDownload();

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_INVENTORY, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const actions = [
    {
      key: 'view-inventory-invoice',
      label: 'Download invoice',
      handleClick: (id) => {
        const data = {
          id: id,
          type: 'unit_inventory',
          identifier: 'invoice',
        };
    
        downloadFile(data);
      }
    },
    {
      key: 'view-inventory-warranty-certificate',
      label: 'Download warranty certificate',
      handleClick: (id) => {
        const data = {
          id: id,
          type: 'unit_inventory',
          identifier: 'warranty-certificate',
        };
    
        downloadFile(data);
      }
    },
    // {
    //   key: 'find-replacement',
    //   label: 'Find replacement',
    //   handleClick: () => {
    //     console.log('find replacement');
    //   }
    // },
    // {
    //   key: 'find-handyman-to-fix',
    //   label: 'Find handyman to fix',
    //   handleClick: () => {
    //     console.log('Find handyman to fix');
    //   }
    // },
    {
      key: 'edit-inventory-details',
      label: 'Edit details',
      handleClick: (id) => {
        editHandlerFn(id);
      }
    },
    {
      key: 'delete-inventory',
      label: 'Delete record',
      handleClick: (id) => {
        handleOpenModal(id);
      }
    },
  ]

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are no inventory items!'
      messageStringEnd='to this property'
      callToActionString='Add your first inventory item'
      callToActionFn={addHandlerFn}
    />
  }

  return <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th scope="col">Item</th>
        <th scope="col">Description</th>
        <th scope="col">Purchase date</th>
        <th scope="col">Time to replace</th>
        <th scope="col">Came with property?</th>
        <th scope="col">Number of maintenance events</th>
        <th scope="col">Maintenance expenses</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>{
      data.map(item => {
        return !hiddenComponents.includes(item.id) && <tr key={
            item.id
          }>
          {visibleModal === item.id && 
            <ConfirmationModal
              key={`confirmation-modal-${item.id}`}
              id={item.id}
              title="Delete?" 
              body="This operation can't be undone." 
              cancelButtonLabel="Cancel" 
              confirmButtonLabel="Delete"
              cancelButtonFn={handleCloseModal}
              confirmButtonFn={
                async () => await handleDeleteObject(item.id)
              }
              isLoading={isDeletionLoading}
            />
          }
          <td>{item.title}</td>
          <td>{item.description}</td>
          <td>{formatDate(item.purchase_timestamp)}</td>
          <td><UnitInventoryTimeToReplace value={item.expected_days_until_replacement_needed}/></td>
          <td>{item.came_with_unit ? 'Yes' : 'No'}</td>
          <td>xxx</td>
          <td>xxx</td>
          <td>
            <div className="nav-item dropdown position-relative">
              <a
                className="position-absolute top-100 start-50 translate-middle mt-1"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false">
                {threeDotMenuIcon}
              </a>
              <ul className="dropdown-menu">
                {actions.map(action => {
                  return <li key={action.key}>
                    <a 
                      className="dropdown-item"
                      onClick={() => action.handleClick(item.id)}
                    >{action.label}</a>
                  </li>
                })} 
              </ul>
            </div>
          </td>
        </tr>
    })
    }</tbody>
  </table>
}

export default UnitInventoryListTable