import {gql} from 'graphql-request';

export const DELETE_UNIT_TENANT = gql `mutation unit_tenant_delete(
  $id: ID!
) {
  unit_tenant_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_TENANT = gql `query unit_tenant_list(
	$unit_id: ID, 
	$rent_due_day: Int,
	$move_out_date: String,
) {
  unit_tenant_list(
		unit_id: $unit_id,
		rent_due_day: $rent_due_day,
		move_out_date: $move_out_date
	) {
    id, 
		tenant {
			id, 
			name, 
			email, 
			phone, 
			profile_type, 
			profile_type_display,
			length_of_stay_in_months,
			remaining_contract_period_in_months,
			forecasted_remaining_income,
			monthly_income,
			monthly_income_weight,
			current_tenancy_contract_url,
			government_document_url,
			units {
				id,
				identifier,

			}
		}, 
		contract_start_date, 
		contract_end_date, 
		move_out_date, 
		rent_price, 
		deposit_value, 
		rent_due_day,
		has_pets
  }
}`;

export const VIEW_UNIT_TENANT = gql `query view_unit_tenant(
  $id: ID!
){
  unit_tenant(id: $id) {
    id, 
		unit {
			id, 
			identifier, 
			address, 
			purchase_timestamp, 
			purchase_price, 
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants, 
			image_urls
		}, 
		tenant {
			id, 
			name, 
			email, 
			phone, 
			profile_type, 
			profile_type_display
		}, 
		contract_start_date, 
		contract_end_date, 
		move_out_date, 
		rent_price, 
		deposit_value, 
		rent_due_day
  }
}`

// START MANUAL SECTION

// END MANUAL SECTION