import React from 'react';
import ServiceProviderList from '../components/service-provider/ServiceProviderList.js';
import GraphQLRequest from '../services/GraphQLRequest.js';
import {LIST_SERVICE_PROVIDER} from '../graph-ql/service_provider.js';

const ServicesContainer = ({queryParams}) => {
  return <GraphQLRequest 
    query={LIST_SERVICE_PROVIDER}
    queryParams={queryParams}
    componentToRender={ServiceProviderList}/>
}

export default ServicesContainer
