import React from 'react'
import LinkWithModal from '../modal/LinkWithModal.js'
import Form from '../../../services/Form.js'

const MarkTenantAsCheckedOutAction = ({objectID, postProcessorFn}) => {

  const content = () => {
    return <Form 
      formID="unit-tenant-mark-as-checked-out"
      successMessage={'Tenant was checked out'}
      objectID={objectID}
      formSubmitCallback={postProcessorFn}
    />
  }

  return (
    <a 
      className="dropdown-item"
    >
      <span>
        <LinkWithModal 
          label="Check out tenant"
          content={content}
        />
      </span>   
    </a>
  );
}
export default MarkTenantAsCheckedOutAction
