import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {formatPriceInEUR} from '../../../services/Format';

const CustomTooltip = ({active, payload, showExpenses}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="m-0 label p-1" style={{backgroundColor: '#FFFFFF'}}>
          <p className="m-0" style={{color: 'green'}}>Income: {`${formatPriceInEUR(payload[0].value)}`}</p>
          {showExpenses && <p className="m-0" style={{color: 'red'}}>Expenses: {`${formatPriceInEUR(payload[1].value)}`}</p>}
        </div>
      </div>
    );
  }

  return null;
};

const BarChartComponent = ({data, showExpenses = false}) => {
  return (
    <BarChart width={500}
      height={300}
      data={data}
      margin={
        {
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }
    }>
      <CartesianGrid strokeDasharray="3 3"/>
      <XAxis dataKey="name"/>
      <YAxis/>
      <Tooltip content={<CustomTooltip showExpenses={showExpenses}/>}/>
      <Legend/>
      <Bar 
        dataKey="income" 
        fill="#4CAF4F"
        name="Income"
      />
      {showExpenses && <Bar 
        dataKey="expenses" 
        fill="#F44336"
        name="Expenses"
      />}
    </BarChart>
  )
}

export default BarChartComponent
