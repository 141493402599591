import React from 'react';
import TenantCard from './TenantCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import {DELETE_TENANT} from '../../graph-ql/tenant.js';

const TenantList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data
    }
    error={error}
    loading={loading}
    componentToRender={TenantCard}
    deleteGQL={DELETE_TENANT}/>
};

export default TenantList;
