import React from 'react';
import ServiceProviderCard from './ServiceProviderCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import { DELETE_SERVICE_PROVIDER } from '../../graph-ql/service_provider.js';

const ServiceProviderList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data.service_provider_list
    }
    error={error}
    loading={loading}
    componentToRender={ServiceProviderCard}
    deleteGQL={DELETE_SERVICE_PROVIDER}/>
};

export default ServiceProviderList;
