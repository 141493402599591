export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const convertToIncomeData = (json, forecast) => {
  const incomeByMonthTmp = {};
  json.forEach(item => {
    const month = new Date(item.billing_timestamp).toLocaleString('en-US', {month: 'short'});
    const income = item.value;

    if (incomeByMonthTmp[month]) {
      incomeByMonthTmp[month] += income;
    } else {
      incomeByMonthTmp[month] = income;
    }
  });

  let incomeByMonth = {};
  months.map(month => {
    incomeByMonth[month] = incomeByMonthTmp[month] !== undefined ? incomeByMonthTmp[month] : 0;
  })

  let result = Object.keys(incomeByMonth).map(month => ({
    month, 
    income: incomeByMonth[month], 
    forecast: forecast
  }));

  result.sort((a, b) => {
    return months.indexOf(a.month) - months.indexOf(b.month);
  });

  return result;
}

export const getIncomeByMonth = (json) => {
  const incomeByMonthTmp = {};

  json.forEach(item => {
    const month = new Date(item.billing_timestamp).toLocaleString('en-US', {month: 'short'});
    const income = item.value;

    if (incomeByMonthTmp[month]) {
      incomeByMonthTmp[month] += income;
    } else {
      incomeByMonthTmp[month] = income;
    }
  });

  let incomeByMonth = {};
  months.map(month => {
    incomeByMonth[month] = incomeByMonthTmp[month] !== undefined ? incomeByMonthTmp[month] : 0;
  });
  
  return incomeByMonth;
}

export const getIncomeForCurrentMonthByUnit = (json) => {
  const currentMonthIncomeByUnit = {};
  const currentMonth = new Date().toLocaleString('en-US', {month: 'long'});

  json.forEach(item => {
    const month = new Date(item.billing_timestamp).toLocaleString('en-US', {month: 'short'});
    const income = item.value;

    if (currentMonth === month) {
      if (currentMonthIncomeByUnit[item.unit.identifier]) {
        currentMonthIncomeByUnit[item.unit.identifier] += income;
      } else {
        currentMonthIncomeByUnit[item.unit.identifier] = income;
      }
    }
  });

  return currentMonthIncomeByUnit;
}