import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import ReportsContainer from '../../containers/ReportsContainer.js'

const ReportsPage = () => {
  return (
    <>
      <PageHeading heading="Reports"/>
      <ReportsContainer />
    </>
  )
}

export default ReportsPage
