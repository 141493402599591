import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import {LIST_UNIT_TENANT} from '../../../graph-ql/unit_tenant.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import UnitTenantListTable from './tenant/UnitTenantListTable.js';
import TenantMutatePage from '../../../pages/tenant/TenantMutatePage.js';
import RouteActionType from '../../../enums/RouteActionType.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';

const UnitViewTenants = ({unit_id}) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPastTenants, setShowPastTenants] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [unitTenantID, setUnitTenantID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');
  
  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      let queryParams = {unit_id: unit_id};
      if (!showPastTenants) {
        queryParams['move_out_date'] = null;
      }
      const response = await graphQLClient.request(LIST_UNIT_TENANT, queryParams);
      setData(response.unit_tenant_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData, showPastTenants]);

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  const editHandler = (unit_tenant_id) => {
    setUnitTenantID(unit_tenant_id);
    setSubpage('edit');
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-8 align-self-center">
          {subpage === 'list' && <div className="form-check form-switch mb-4 mt-4">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => {setShowPastTenants(e.target.checked); setIsLoaded(false);}}/>
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show past tenants</label>
          </div>}
        </div>
        <div className="col-4 align-self-center">
          <UnitViewContentButtons 
            addButtonLabel={'Add tenants'} 
            viewButtonLabel={'View tenants'} 
            cancelButtonLabel={cancelButtonLabel}
            subpage={subpage} 
            setSubpageFn={setSubpage}
          />
        </div>
      </div>
      {subpage === 'list' && isLoaded && <UnitTenantListTable 
        unit_id={unit_id}
        data={data} 
        editHandlerFn={editHandler}
        addHandlerFn={() => setSubpage('add')}
      />}
      {subpage === 'list' && !isLoaded && <Loading />}
      {subpage === 'add' && <TenantMutatePage 
        action={RouteActionType.add} 
        extraData={{'main-page': 'unit-tenant', 'unit-id': unit_id}}
        formSubmitCallbackFn={refreshData} 
      />}
      {subpage === 'edit' && <TenantMutatePage 
        action={RouteActionType.edit} 
        extraData={{'main-page': 'unit-tenant', 'unit-id': unit_id}} 
        tenantID={unitTenantID}
        formSubmitCallbackFn={() => {
          refreshData(); 
          setCancelButtonLabel('View tenants');
        }}
      />}
    </>
  )
}

export default UnitViewTenants
