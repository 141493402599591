import {gql} from 'graphql-request';

export const LIST_NEIGHBOURHOOD = gql `query neighbourhood_list($city_id: ID){
  neighbourhood_list
    (city_id: $city_id){
    id, 
		city {
			id, 
			name
		}, 
		name, 
		postcode, 
		latitude, 
		longitude
  }
}`;

export const VIEW_NEIGHBOURHOOD = gql `query view_neighbourhood(
  $id: ID!
){
  neighbourhood(id: $id) {
    id, 
		city {
			id, 
			name
		}, 
		name, 
		postcode, 
		latitude, 
		longitude
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION