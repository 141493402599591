import React from 'react'
import { threeDotMenuIcon } from '../../../assets/svg/common.js'

const CardTitleWithThreeDotMenu = ({id, title, actions, icon}) => {
  return (
    <div className="d-flex mb-2">
      <h5 className="card-title">{title}</h5>
      {icon && <span className="ms-1">{icon}</span>}
      <div className="ms-auto p-2">
        <div className="nav-item dropdown position-relative">
          <a
            className="position-absolute top-100 start-50 translate-middle mt-1"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false">
            {threeDotMenuIcon}
          </a>
          <ul className="dropdown-menu">
            {
            actions.map(action => {
              return <li key={
                action.key
              }>
                <a className="dropdown-item"
                  onClick={
                    () => action.handleClick(id)
                }>
                  {
                  action.label
                }</a>
              </li>
          })
          } 
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CardTitleWithThreeDotMenu