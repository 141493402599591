import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'

const ServiceProviderAddPage = () => {
  return (
    <>
      <PageHeading heading="Add service provider"/>
      <Form formID="service-provider-add"
        successMessage={'Service provider was added'}/>
    </>
  )
}

export default ServiceProviderAddPage
