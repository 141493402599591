import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import {LIST_UNIT_LISTING} from '../../../graph-ql/unit_listing.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import UnitListingListTable from './listing/UnitListingListTable.js';
import RouteActionType from '../../../enums/RouteActionType.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';
import UnitListingMutatePage from '../../../pages/property/listing/UnitListingMutatePage.js';

const UnitViewListings = ({unit_id}) => {
  const [listingID, setListingID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');

  const graphQLClient = GraphQLClientWithCredentials();
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_LISTING, {unit_id: unit_id});
      setData(response.unit_listing_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData]);

  const editHandler = (id) => {
    setListingID(id);
    setSubpage('edit');
  }

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  return (
    <>
      <UnitViewContentButtons 
        addButtonLabel={'Add listing'} 
        viewButtonLabel={'View listings'}
        cancelButtonLabel={cancelButtonLabel} 
        subpage={subpage} 
        setSubpageFn={setSubpage}
      />
      {subpage === 'list' && isLoaded && <UnitListingListTable 
        data={data} 
        editHandlerFn={editHandler}
        addHandlerFn={() => setSubpage('add')}
      />}
      {subpage === 'list' && !isLoaded && <Loading />}
      {subpage === 'add' && <UnitListingMutatePage 
        formSubmitCallbackFn={refreshData} 
        action={RouteActionType.add} 
        extraData={{'unit-id': unit_id}} 
      />}
      {subpage === 'edit' && <UnitListingMutatePage 
        formSubmitCallbackFn={() => {
          refreshData(); 
          setCancelButtonLabel('View listings');
        }} 
        action={RouteActionType.edit} 
        extraData={{'unit-id': unit_id}} 
        id={listingID}
      />}
    </>

  )
}

export default UnitViewListings
