import React, {useState, useEffect} from 'react'
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import Loading from '../common/Loading.js';
import {gql} from 'graphql-request';
import { Calendar, DateObject } from "react-multi-date-picker"
import IncomeCalendarDetails from './IncomeCalendarDetails.js';

const GQL = gql `query unit_tenant_list{
  unit_tenant_list {
    id,
    unit {
      identifier
    },
		rent_due_day,
    contract_start_date,
    contract_end_date,
  }
}`;

const MonthlyIncomeCalendar = () => {
  const graphQLClient = GraphQLClientWithCredentials();
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [earliestContractStartDate, setEarliestContractStartDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    setIsLoaded(false);
    let dataArrayResult = [];
    let daysWithMonths = [];
    const fetchData = async () => {
      const response = await graphQLClient.request(GQL);

      let contract_start_dates = response.unit_tenant_list.map(item => {
        return item.contract_start_date;
      }).filter((element) => element !== null);
      contract_start_dates = [...new Set(contract_start_dates)];

      for (let i = 0; i < response.unit_tenant_list.length; i++) {
        let item = response.unit_tenant_list[i];
        if (item.contract_start_date === null || item.contract_end_date === null) {
          continue;
        }

        const dates = sortArrayByCurrentMonth(
          generateMonthlyDates(
            item.contract_start_date, 
            item.contract_end_date, 
            item.rent_due_day,
          ),
        );
        for (const x in dates) {
          daysWithMonths.push(new DateObject().set({year: dates[x].year, month: dates[x].month, day: dates[x].day })); 
          dataArrayResult.push(dates[x].year + '-' + dates[x].month + '-' + dates[x].day);         
        }
      }

      setEarliestContractStartDate(getEarliestDate(contract_start_dates));
      const currentYear = (new Date()).getFullYear();
      setCurrentYear(currentYear);
      setLatestDate(new DateObject().set({year: currentYear + 1}));

      setDataArray(dataArrayResult);
      setData(daysWithMonths);
      setIsLoaded(true);
    };

    fetchData();
  }, []);

  const generateMonthlyDates = (startDateString, endDateString, dayOfMonth) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const monthlyDates = [];

    let currentDate = new Date(startDate);

    while (currentDate < endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
      const day = dayOfMonth;

      monthlyDates.push({ year, month, day });

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Reverse the array to get dates in descending order
    return monthlyDates.reverse();
  }

  const sortArrayByCurrentMonth = (dateArray) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
  
    // Check if the current month is present in the array
    const hasCurrentMonth = dateArray.some((date) => date.year === currentYear && date.month === currentMonth);
  
    // If the current month is present, move it to the front
    if (hasCurrentMonth) {
      return [
        { year: currentYear, month: currentMonth, day: 1 }, // You can use any day, as it's just a placeholder
        ...dateArray.filter((date) => !(date.year === currentYear && date.month === currentMonth)),
      ];
    }
  
    // If the current month is not present, return the array as is
    return dateArray;
  };

  const fetchPaydayDetails = (input) => {
    setSelectedDate(input);
  }

  const getEarliestDate = (dates) => {
    const result = dates.map((dateString) => new Date(dateString));
    return Math.min(...result);
  };

  if (!isLoaded) {
    return <Loading/>;
  }

  return (
    <>
      <div className="d-flex mt-3">
        <div className="me-1">
          <Calendar
            value={data}
            multiple
            readOnly
            highlightToday={false}
            startYear={currentYear}
            minDate={earliestContractStartDate}
            maxDate={latestDate}
            mapDays={({ date }) => {
              let isPayday = dataArray.includes(date.year + '-' + date.month.number + '-' + date.day);
              if (isPayday) {
                const isInThePast = (new Date()) > date;
                const backgroundColor = isInThePast ? 'green' : 'gray';
                return {
                  style: {backgroundColor: backgroundColor},
                  onClick: () => fetchPaydayDetails(date)
                }
              }
            }}
          />
        </div>
        <div className="ps-3 w-100">
          <IncomeCalendarDetails selectedDate={selectedDate} />
        </div>
      </div>
    </>
    
  )
}

export default MonthlyIncomeCalendar