import React from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType';

const UnitInsuranceMutatePage = ({id, action, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update insurance' : 'Add insurance'}/>
      <Form 
        formID="unit-insurance-mutate"
        successMessage={action === RouteActionType.edit ? 'Insurance data was updated' : 'Insurance was added'}
        objectID={parseInt(id) || 0}
        extraData={extraData}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitInsuranceMutatePage
