import React, {useEffect, useState} from 'react'
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import Loading from '../Loading.js';

const CityFilter = ({query, handleChange, dataItemKey}) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        setLoading(true);
        const data = await graphQLClient.request(query);
        let cities_ = [];
        let visited = [];
        cities_ = data[dataItemKey].map(item => {
          if (visited.includes(item.city.id)) {
            return null;
          }
          visited.push(item.city.id);
          return {
            id: item.city.id, 
            name: item.city.name
          };
        });
        setCities(cities_.filter((element) => element !== null));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error performing mutation:', error);
        throw error;
      }
    }

    fetchCities();
  }, []);

  if (loading) {
    return <Loading/>
  }

  return (
    <select className="form-select" name="city_id" onChange={handleChange}>
      <option value="0">All Cities</option>
      {Object.entries(cities).map(([key]) => {
        return <option key={cities[key].id} value={cities[key].id}>{cities[key].name}</option>
      })}
    </select>
  )
}

export default CityFilter