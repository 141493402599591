import React, {useState, useEffect} from 'react'

const FormTextElement = ({
  name, 
  initialValue, 
  placeholder, 
  id, 
  onChangeHandler,  
  additionalClassName,
  datatype,
  isDisabled,
  isHidden,
}) => {
  if (initialValue === null || initialValue === undefined) {
    initialValue = '';
  }
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (isHidden) {
    return;
  }

  return (
    <input type="text"
      autoComplete="off"
      placeholder={placeholder}
      value={value}
      onChange={
        (e) => {setValue(e.target.value); onChangeHandler(e);}
      }
      name={name}
      data-type={datatype}
      className={`form-control ${additionalClassName}`}
      id={id}
      disabled={isDisabled}
    />
  )
}

export default FormTextElement
