import React from 'react'
import BarChartComponent from '../common/chart/BarChartComponent.js';
import { formatPriceInEUR } from '../../services/Format';

const UnitDistribution = ({data}) => {
  const titleComponent = <h3>Distribution of income by property in the current month</h3>;
  if (!Object.keys(data).length) {
    return <>
      {titleComponent}
      <div className="alert alert-info mt-3" role="alert">
        There is no data for this month yet!
      </div>
    </>;
  }
  return <>
    {titleComponent}
    <BarChartComponent data={
      Object.keys(data).map(identifier => {
        return {name: identifier, income: formatPriceInEUR(data[identifier])};
      })
    }/>
  </>
}

export default UnitDistribution