import {GraphQLClient} from 'graphql-request';
import {API_URL} from '../Config.js';

const GraphQLClientWithCredentials = () => {
  return new GraphQLClient(API_URL, {
    credentials: 'include',
  });
}

export default GraphQLClientWithCredentials
