import {gql} from 'graphql-request';

export const DELETE_UNIT_INCOME = gql `mutation unit_income_delete(
  $id: ID!
) {
  unit_income_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_INCOME = gql `query unit_income_list($unit_id: ID){
  unit_income_list
    (unit_id: $unit_id){
    id, 
		tenant {
			id, 
			name, 
			email, 
			phone, 
			profile_type, 
			profile_type_display
		}, 
		billing_timestamp, 
		value
  }
}`;

export const VIEW_UNIT_INCOME = gql `query view_unit_income(
  $id: ID!
){
  unit_income(id: $id) {
    id, 
		unit {
			id, 
			identifier, 
			address, 
			purchase_timestamp, 
			purchase_price, 
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants, 
			image_urls
		}, 
		tenant {
			id, 
			name, 
			email, 
			phone, 
			profile_type, 
			profile_type_display
		}, 
		billing_timestamp, 
		value
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION