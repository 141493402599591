import React from 'react'

const UnitNavigationDropdownListItem = ({page, currentPage}) => {
  if (!page.hasOwnProperty('dropdownItems')) {
    return null;
  }
  const className_ = 'nav-link dropdown-toggle' + (
  currentPage === page.key ? ' active' : ''
);
  return (
    <li className="nav-item dropdown">
      <a className={className_}
        data-bs-toggle="dropdown"
        role="button"
        aria-expanded="false">
        {
        page.label
      }</a>
      <ul className="dropdown-menu">
        {
        page.dropdownItems.map(dropdownItem => {
          return <li key={
            dropdownItem.key
          }>
            <a className="nav-link dropdown-item"
              onClick={
                dropdownItem.handleClick
            }>
              {
              dropdownItem.label
            }</a>
          </li>
      })
      } </ul>
    </li>
  )
}

export default UnitNavigationDropdownListItem
