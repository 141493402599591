import React from 'react'
import '../../../styles/timeline.css';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

// Event Component
const TimelineEvent = ({ event }) => {
  const { title, date, description } = event;

  // Calculate position on the timeline based on date (float between 0 and 1)
  const leftPosition = `calc(${(date * 100).toFixed(2)}% - 40px)`;

  return (
    <div className="event-container" style={{ left: leftPosition }}>
      <div className="event-line"></div>
      <OverlayTrigger 
        key="top" 
        placement="top"
        overlay={<Tooltip>{description}</Tooltip>}
      >
        <div
          className="event-bubble"
          data-tip
          data-for={`tooltip-${title}`}
        >{title}</div>
      </OverlayTrigger>
    </div>
  );
};

export default TimelineEvent