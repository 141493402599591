import React from 'react'
import { isEmptyString } from '../../../services/String.js'

const CardContentItem = ({label, text}) => {
  return (
    <p className="mb-1">
      <span className="me-1">{label}</span>
      {!Array.isArray(text) && <span className="ms-1">{!isEmptyString(text) ? text : <i>n/a</i>}</span>}
      {Array.isArray(text) && text.map((component, index) => (
        <span key={index}>
          {component}
          {index < text.length - 1 && ', '}
        </span>
      ))}
    </p>
  )
}

export default CardContentItem