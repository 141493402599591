import React from 'react'
import UnitNavigationRegularListItem from './UnitNavigationRegularListItem.js';

const UnitListNavigationTabs = ({currentPage, handleClick, id}) => {
  const pages = [
    {
      key: 'card',
      label: 'Card view'
    },
    {
      key: 'list',
      label: 'List view'
    },
    {
      key: 'map',
      label: 'Map view'
    },
  ];

  return (
    <ul className="nav nav-underline">
      {
      pages.map(page => {
        return <div key={
          page.key
        }>
          <UnitNavigationRegularListItem page={page}
            handleClick={handleClick}
            currentPage={currentPage}/>
        </div>
    })
    } </ul>
  )
}

export default UnitListNavigationTabs
