import React from 'react'
import PageHeading from '../components/common/PageHeading.js'

const ErrorPage = () => {
  return (
    <PageHeading heading="Error"/>
  )
}

export default ErrorPage
