import React, {useState} from 'react'
import {createPortal} from 'react-dom';
import ModalContent from './ModalContent.js';

const LinkWithModal = ({label, content}) => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const ModalContainer = () => {
    return showModal && createPortal (
      <ModalContent onClose={closeModal}
        content={content}/>,
      document.body
    );
  }

  return (
    <>
      <a onClick={openModal}>
        {label}</a>
      <ModalContainer showModal={showModal}
        closeModal={closeModal}
        content={content}/>
    </>
  );
}
export default LinkWithModal
