// export const API_URL = "http://dev.api.propertypal:8888/";
// export const API_URL = "http://localhost:8888/property-management-app/api/";
// export const UPLOAD_URL = "http://localhost:8888/property-management-app/upload/";
// export const DOWNLOAD_URL = "http://localhost:8888/property-management-app/download/";

export const API_URL = "https://api.sumeo.app/";
export const UPLOAD_URL = "http://localhost:8888/property-management-app/upload/";
export const DOWNLOAD_URL = "http://localhost:8888/property-management-app/download/";

export const GOOGLE_API_KEY = "AIzaSyD5XEBg-T-5SKvgi6btNxiMWW6tRA-1d_A";

export const ENABLE_GK_CHECKS = true;