import {gql} from 'graphql-request';

export const GET_STATS = gql `{
  dashboard {
    unit_count,
    total_investment,
    monthly_income,
    annual_yield,
    avg_investment,
    lifetime_income,
    ytd_income,
    lifetime_expenses,
    ytd_expenses,
    lifetime_net_profit,
    ytd_net_profit,
    active_tenants_count,
    progress_to_goal,
    monthly_income_goal,
    occupancy_rate,
    last_year_monthly_income,
    last_year_monthly_expenses,
  }
}`;

export const GET_INCOME = gql `{
  unit_income_list {
      unit {
        identifier
      },
      billing_timestamp,
      value
  }
}`
