import React, {useState} from 'react'
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import ConfirmationModal from '../common/ConfirmationModal.js';
import { threeDotMenuIcon } from '../../assets/svg/common.js';
import {formatPriceInEUR, formatPercentage, formatMonths} from '../../services/Format.js';
import NoContentAlert from '../common/NoContentAlert.js';
import ClickAction from '../common/action/ClickAction.js';
import { DELETE_UNIT } from '../../graph-ql/unit';
import UnitIdentifier from './fragments/UnitIdentifier.js';
import { useNavigate } from 'react-router-dom';
import Loading from '../common/Loading.js';

const UnitListTable = ({data, addHandlerFn, isLoaded}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();
  const navigate = useNavigate();
  const editHandlerFn = (id) => {
    navigate('/property/edit/' + id);
  }

  if (!isLoaded) {
    return <Loading />
  }

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are currently no properties!'
      messageStringEnd=''
      callToActionString='Add a property'
      callToActionFn={addHandlerFn}
    />
  }

  const getActions = (id) => {
    return [
      <ClickAction label="Edit details" handleClickFunc={() => editHandlerFn(id)} />,
      <ClickAction label="Delete record" handleClickFunc={() => handleOpenModal(id)} />
    ];
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  return <>
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">Identifier</th>
          <th scope="col">Monthly income</th>
          <th scope="col">Annual yield</th>
          <th scope="col">Monthly income weight</th>
          <th scope="col">Annual fees</th>
          <th scope="col">Total investment</th>
          <th scope="col">Occupancy rate</th>
          <th scope="col">Time in portfolio</th>
          <th scope="col">Number of tenants</th>
          <th scope="col">Tenant retention rate</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>{
        data.map(unit => {
          return !hiddenComponents.includes(unit.id) && (<>
            <tr key={unit.id}>
              {visibleModal === unit.id && 
                <ConfirmationModal 
                  key={`confirmation-modal-${unit.id}`}
                  id={unit.id}
                  title="Delete?"
                  body="This operation can't be undone." 
                  cancelButtonLabel="Cancel" 
                  confirmButtonLabel="Delete"
                  cancelButtonFn={handleCloseModal}
                  confirmButtonFn={
                    async () => await handleDeleteObject(unit.id)
                  }
                  isLoading={isDeletionLoading}
                />
            }
              <td><UnitIdentifier identifier={unit.identifier} id={unit.id}/></td>
              <td>{formatPriceInEUR(unit.monthly_income)}</td>
              <td>{formatPercentage(unit.expected_anual_yield)}</td>
              <td>{formatPercentage(unit.monthly_income_weight)}</td>
              <td>{formatPriceInEUR(unit.yearly_fees)}</td>
              <td>{formatPriceInEUR(unit.total_investment)}</td>
              <td>{formatPercentage(unit.occupancy_rate)}</td>
              <td>{formatMonths(unit.time_in_portfolio_in_years * 12)}</td>
              <td>{unit.current_number_of_tenants}</td>
              <td>{formatPercentage(unit.tenant_retention_rate)}</td>
              <td>
                <div className="nav-item dropdown position-relative">
                  <a
                    className="position-absolute top-100 start-50 translate-middle mt-1"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false">
                    {threeDotMenuIcon}
                  </a>
                  <ul className="dropdown-menu">{getActions(unit.id).map(action => <li>{action}</li>)} </ul>
                </div>
              </td>
            </tr></>)
        })
      }</tbody>
    </table>
    <span className="float-end">Export <a href="">csv</a> | <a href="">xlsx</a></span>
  </>
}

export default UnitListTable