import React, {useState, useEffect, useCallback} from 'react'
import { xFileIcon } from '../../../../assets/svg/icons.js';
import useFileUpload from '../../../../hooks/useFileUpload.js';

const FormFileElement = ({
  name, 
  id, 
  isHidden,
  onChangeHandler,
  accept,
  initialValue,
}) => {
  const [files, setFiles] = useState([]);
  const [seenFiles, setSeenFiles] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const {uploadFiles} = useFileUpload();

  if (initialValue === null || initialValue === undefined) {
    initialValue = '';
  }
  
  useEffect(() => {
    const initialFiles = [];
    initialValue.split('|').forEach(url => {
      if (url) {
        let filename = url.split('/').pop();
        initialFiles.push({name: filename, url: url, isStoredOnServer: true});
      }
    });
    setFiles(initialFiles);
  }, [initialValue]);

  const uploadFilesCall = useCallback(async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const result = await uploadFiles(selectedFiles, name);

    if (result && result[name]) {
      setUploadedFiles(prev => ({
        ...prev[name],
        ...result[name]
      }));
    }
    
  }, [uploadFiles, setUploadedFiles], name);
  
  if (isHidden) {
    return null;
  }

  const removeFile = (file) => {
    let newFiles = [];
    let newSeenFiles = [];
    let toBeDeleted_ = [];
    
    for (let i = 0; i < files.length; i++) {
      if (files[i].name !== file.name) {
        newSeenFiles.push(files[i].name);
        newFiles.push(files[i]); 
      }
    }
    
    if (file.hasOwnProperty('isStoredOnServer') && file.isStoredOnServer) {
      toBeDeleted_.push(file.name);
    }

    setFiles(newFiles);
    setSeenFiles(newSeenFiles);
    setToBeDeleted(toBeDeleted_);

    return newFiles;
  }

  const onFileChange = (e) => {
    let files_ = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const fileName = e.target.files[i].name;
      if (!seenFiles.includes(fileName)) {
        files_.push(e.target.files[i]);
        setSeenFiles(prevSeenFiles => [fileName, ...prevSeenFiles]);
      }
    }
    setFiles(prevFiles => [...prevFiles, ...files_]);
    uploadFilesCall(e);
  }

  return <div className="clearfix">
    {/* todo make 'multiple' an argument */}
    <input type="file" 
      style={{display: 'none'}} 
      accept={accept.join(', ')} 
      name={name} 
      id={id} 
      className="form-control" 
      defaultValue="" 
      onChange={(e) => {onFileChange(e); onChangeHandler(e);}} 
      multiple 
    />
    <input type="hidden" name={name + "_deleted"} id={id + "_deleted"} value={toBeDeleted.join('|')} />
    <input type="hidden" name={name + "_added"} id={id + "_added"} value={typeof uploadedFiles === 'object' ? Object.values(uploadedFiles).join('|') : null} />
    <label htmlFor={id} className="btn btn-info">Choose files</label>
    {files.length > 0 && <div className="mt-2">
      <i>Selected files</i>
      <ul className="list-group mt-1">
      {files.map(file => {
        return <li className="list-group-item list-group-item-action" key={file.name}>
          <a onClick={() => {const newFiles = removeFile(file); const x = {target: {name: name, files: newFiles}}; onChangeHandler(x);}}>{xFileIcon}</a>
          {file.hasOwnProperty('url') ? <a href={file.url} target="_blank" className='ms-2'>{file.name}</a> : <span className="ms-2">{file.name}</span>}
        </li>
      })}
      </ul>
    </div>}
  </div>
}

export default FormFileElement
