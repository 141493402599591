import { useState, useCallback } from 'react';
import { UPLOAD_URL } from '../Config.js';

const useFileUpload = () => {
  const [fileNames, setFileNames] = useState({});

  const addItems = (inputName, newItems) => {
    setFileNames((prevItems) => ({
      ...prevItems,
      [inputName]: [...(prevItems[inputName] || []), ...newItems]
    }));
  };

  const uploadFiles = useCallback(async (files, inputName) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    try {
      const response = await fetch(UPLOAD_URL, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      if (data.success) {
        addItems(inputName, data.data);
        return { [inputName]: data.data };
      } else {
        return { [inputName]: [] };
      }
    } catch (error) {
      console.error('Error: ', error);
      return { [inputName]: [] };
    }
  }, []);

  return { fileNames, uploadFiles };
};

export default useFileUpload;