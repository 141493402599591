import React from 'react';
import NotaryCard from './NotaryCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import { DELETE_NOTARY } from '../../graph-ql/notary.js';

const NotaryList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data
    }
    error={error}
    loading={loading}
    componentToRender={NotaryCard}
    deleteGQL={DELETE_NOTARY}/>
};

export default NotaryList;
