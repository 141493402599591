import React, {useState} from 'react'
import {Link} from 'react-router-dom';

const LeftNavigationItem = ({urlPath, itemName}) => {

  const correctURLPath = '/' + urlPath;

  const [activeItem, setActiveItem] = useState('dashboard');

  const handleClick = (value) => {
    if (value === activeItem) {
      return;
    }
    setActiveItem(value);
  };

  return (
    <li className="nav-item">
      <Link onClick={
          () => handleClick(urlPath)
        }
        className={
          `nav-link ${
            activeItem === urlPath ? 'active' : ''
          }`
        }
        to={correctURLPath}>
        {itemName}</Link>
    </li>
  )
}

export default LeftNavigationItem
