import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import {SAVE_SETTINGS} from '../../graph-ql/setting.js';

const SettingsPage = () => {
  return (
    <>
      <PageHeading heading="Settings"/>
      <Form formID="settings-set"
        mutation={SAVE_SETTINGS}
        mutationName="replace_setting"
        successMessage={'Settings were updated'}/>
    </>
  )
}

export default SettingsPage
