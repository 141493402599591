import {gql} from 'graphql-request';

export const DELETE_UNIT_CONTACT = gql `mutation unit_contact_delete(
  $id: ID!
) {
  unit_contact_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_CONTACT = gql `query unit_contact_list($unit_id: ID){
  unit_contact_list
    (unit_id: $unit_id){  
    id,
		name,
    phone,
    email,
    door_number
  }
}`;

// START MANUAL SECTION



// END MANUAL SECTION