import React from 'react';
import GraphQLRequest from '../services/GraphQLRequest.js';
import {GET_STATS} from '../graph-ql/dashboard.js';
import ReportsOverview from '../components/reports/ReportsOverview.js';

const ReportsContainer = () => {
  return <GraphQLRequest query={GET_STATS}
    componentToRender={ReportsOverview}/>
}

export default ReportsContainer
