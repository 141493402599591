import React from 'react'
import FormSubmitButton from './buttons/FormSubmitButton.js';

const FormButton = ({
  name,
  type,
  label,
  isDisabled,
  showAdditionalInfo,
  additionalInfo,
}) => {
  let button;
  switch (type) {
    case 'submit': button = <FormSubmitButton
      name={name}
      label={label}
      isDisabled={isDisabled}
      showAdditionalInfo={showAdditionalInfo}
      additionalInfo={additionalInfo}
      />
      break;

  }

  if (button === undefined) {
    throw new Error('Button of type ' + type + ' is not defined in FormButton')
  }

  return button;
}

export default FormButton
