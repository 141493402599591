import React from 'react'
import LinkWithModal from '../modal/LinkWithModal.js'
import Form from '../../../services/Form.js'

const RecordIncomeAction = () => {

  const content = () => {
    return <Form formID="unit-income-quick-add"
      successMessage={'Income was recorded'}/>
  }

  return (
    <LinkWithModal label="Record income"
      content={content}/>
  );
}
export default RecordIncomeAction
