import React from 'react';
import UnitPurchasePrice from '../unit/fragments/UnitPurchasePrice.js';

const ListingCard = ({id, price}) => {
  return (
    <div className="card mb-3"
      style={
        {maxWidth: "540px"}
    }>
      <div className="row g-0">
        <div className="col-md-4">
          IMG</div>
        <div className="col-md-8">
          <div className="card-body">
            <UnitPurchasePrice price={price}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingCard
