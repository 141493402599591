import React from 'react'

const UnitAddress = ({address}) => {
  return (
    <p className="card-text">
      {address}</p>
  )
}

export default UnitAddress;
