import React, {useState} from 'react'
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import { DELETE_UNIT_INSURANCE } from '../../../../graph-ql/unit_insurance';
import { formatPriceInEUR, formatDate } from '../../../../services/Format';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import ClickAction from '../../../common/action/ClickAction.js';
import DownloadFileAction from '../../../common/action/DownloadFileAction.js';

const UnitInsuranceListTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);
  
  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_INSURANCE, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const getActions = (id) => {
    return [
      <DownloadFileAction 
        label={'Download contract'} 
        data={{
          id: id,
          type: 'unit_insurance',
          identifier: 'contract',
        }} 
      />,
      <ClickAction label="Edit details" handleClickFunc={() => editHandlerFn(id)} />,
      <ClickAction label="Delete record" handleClickFunc={() => handleOpenModal(id)} />
    ];
  }

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There is no insurance!'
      messageStringEnd='to this property'
      callToActionString='Add your first insurance'
      callToActionFn={addHandlerFn}
    />
  }

  return <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th scope="col">Provider name</th>
        <th scope="col">Fee</th>
        <th scope="col">Policy Number</th>
        <th scope="col">Start date</th>
        <th scope="col">End date</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>{
      data.map(item => {
        return !hiddenComponents.includes(item.id) && <tr key={
            item.id
          }>
          {visibleModal === item.id && 
            <ConfirmationModal
              key={`confirmation-modal-${item.id}`}
              id={item.id}
              title="Delete?" 
              body="This operation can't be undone." 
              cancelButtonLabel="Cancel" 
              confirmButtonLabel="Delete"
              cancelButtonFn={handleCloseModal}
              confirmButtonFn={
                async () => await handleDeleteObject(item.id)
              }
              isLoading={isDeletionLoading}
            />
          }
          <td>{item.provider_name}</td>
          <td>{formatPriceInEUR(item.fee)}</td>
          <td>{item.policy_number}</td>
          <td>{formatDate(item.start_date)}</td>
          <td>{formatDate(item.end_date)}</td>
          <td>
            <div className="nav-item dropdown position-relative">
              <a
                className="position-absolute top-100 start-50 translate-middle mt-1"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false">
                {threeDotMenuIcon}
              </a>
              <ul className="dropdown-menu">{getActions(item.id).map(action => <li>{action}</li>)} </ul>
            </div>
          </td>
        </tr>
    })
    }</tbody>
  </table>
}

export default UnitInsuranceListTable