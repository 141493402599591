import React, {useState} from 'react'
import {formatDate, formatPriceInEUR} from '../../../../services/Format.js';
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import {DELETE_UNIT_EXPENSE} from '../../../../graph-ql/unit_expense.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import PieChartWithText from '../../../common/chart/PieChartWithText.js';
import { convertToIncomeData } from '../../../../services/Income.js';
import IncomeChart from '../../../IncomeChart.js';
import { useFileDownload } from '../../../../hooks/useFileDownload.js';
import Timeline from '../../../common/timeline/Timeline.js';

const UnitExpenseListTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();
  const downloadFile = useFileDownload();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_EXPENSE, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const actions = [
    {
      key: 'download-invoice',
      label: 'Download invoice',
      handleClick: (id) => {
        const data = {
          id: id,
          type: 'unit_expense'
        };
    
        downloadFile(data);
      }
    },
    {
      key: 'contact-contractor',
      label: 'Contact contractor',
      handleClick: () => {
        console.log('Contact contractor');
      }
    },
    {
      key: 'edit-expense-details',
      label: 'Edit details',
      handleClick: (id) => {
        editHandlerFn(id);
      }
    },
    {
      key: 'delete-expense',
      label: 'Delete record',
      handleClick: (id) => {
        handleOpenModal(id);
      }
    },
  ]

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are no expenses!'
      messageStringEnd='to this property'
      callToActionString='Add your first expense'
      callToActionFn={addHandlerFn}
    />
  }

  const groupExpensesByType = (expenses) => {
    let counter = {};
    let result = [];

    expenses.forEach(expense => {
      if (!counter.hasOwnProperty(expense.type)) {
        counter[expense.type] = 0;
      }

      counter[expense.type]++;
    });

    const colors = ['#4CAF50', '#FFC107', '#000', '#C00'];

    let i = -1;
    let seen = {};
    expenses.forEach(expense => {
      if (seen.hasOwnProperty(expense.type)) {
        return;
      }
      seen[expense.type] = true;
      i++;
      const r = {
        label: expense.type,
        value: counter[expense.type],
        color: colors[i],
      };
      
      result.push(r);
    });
    
    return result;
  }

  const events = [
    { title: 'Insurance expires', date: 0.03, description: 'Insurance will expire on 14.01.2024' },
    { title: 'Building renovation', date: 0.35, description: 'Next installment due on 28.04.2024' },
    { title: 'IBI due', date: 0.75, description: 'IBI due on 12.09.2024.' }
  ];

  return <>
    <div className="d-flex mt-4">
      <div className='me-4'>
        {data && <PieChartWithText centerLabel="Expenses" data={groupExpensesByType(data)} showPercentage={true} />}
      </div>
      <div className='me-4 mb-5' style={{width: "100%"}}>
        {data && <IncomeChart label="Expenses (€)" title="Expenses over time" data={convertToIncomeData(data)} />}
      </div>
    </div>  
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">Type</th>
          <th scope="col">Description</th>
          <th scope="col">Billing date</th>
          <th scope="col">Value</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>{
        data.map(item => {
          return !hiddenComponents.includes(item.id) && <tr key={
            item.id
          }>
            {visibleModal === item.id && <ConfirmationModal 
              key={`confirmation-modal-${item.id}`}
              id={item.id}
              title="Delete?" 
              body="This operation can't be undone." 
              cancelButtonLabel="Cancel" 
              confirmButtonLabel="Delete"
              cancelButtonFn={handleCloseModal}
              confirmButtonFn={
                async () => await handleDeleteObject(item.id)
              }
              isLoading={isDeletionLoading}
            />}
            <td>{
              item.type_display
            }</td>
            <td>{
              item.description
            }</td>
            <td>{
              formatDate(item.billing_timestamp)
            }</td>
            <td>{
              formatPriceInEUR(item.value)
            }</td>
            <td>
              <div className="nav-item dropdown position-relative">
                <a
                  className="position-absolute top-100 start-50 translate-middle mt-1"
                  data-bs-toggle="dropdown"
                  role="button"
                  aria-expanded="false">
                  {threeDotMenuIcon}
                </a>
                <ul className="dropdown-menu">
                  {
                  actions.map(action => {
                    return <li key={
                      action.key
                    }>
                      <a className="dropdown-item"
                        onClick={
                          () => action.handleClick(item.id)
                      }>
                        {
                        action.label
                      }</a>
                    </li>
                })
                } 
                </ul>
              </div>
            </td>
          </tr>
      })
      }</tbody>
    </table>
    <div className='mt-5'>
      <h3>Recurring expense timeline</h3>
      <Timeline events={events} />
    </div>
  </>
}

export default UnitExpenseListTable