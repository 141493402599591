import {gql} from 'graphql-request';

export const LIST_UNIT_TRANSACTION = gql `query unit_transaction_list(
	$unit_id: ID,
  $type: String,
) {
  unit_transaction_list(
		unit_id: $unit_id,
    type: $type,
	) {
    id, 
		real_estate_agent {
			id, 
			name, 
			email, 
			phone,
      person_of_contact
    },
    notary {
			id, 
			name, 
			email, 
			phone, 
    },
    type,
    timestamp,
    price,
    notary_fee,
    government_tax,
    agency_fee
  }
}`;

// START MANUAL SECTION

// END MANUAL SECTION