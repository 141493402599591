import React from 'react'
import '../../../styles/modal.css';

const ModalContent = ({onClose, content: Component}) => {
  return (
    <div className="myCustomModal">
      <Component/>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default ModalContent
