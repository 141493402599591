import {gql} from 'graphql-request';

export const DO_TASK = gql `mutation unit_task_do(
  $id: ID!, 
	$action_type: String!
) {
  unit_task_do(
    id: $id, 
		action_type: $action_type
  ) {
		data {
			id
		}
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

export const DELETE_TASK = gql `mutation unit_task_delete(
  $id: ID!
) {
  unit_task_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_TASK = gql `query unit_task_list(
	$unit_id: ID,
	$status: String,
) {
  unit_task_list(unit_id: $unit_id, status: $status) {
    id, 
		unit {
			id, 
			identifier, 
		}, 
		title, 
		status, 
		status_display,
		type, 
		urgency, 
		urgency_display,
		due_date,
		type_display,
		urgency_display,
		status_display
  }
}`;

export const VIEW_TASK = gql `query view_unit_task(
  $id: ID!
){
  unit_task(id: $id) {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		unit {
			id, 
			identifier, 
			address, 
			purchase_timestamp, 
			purchase_price, 
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants, 
			image_urls
		}, 
		title, 
		status, 
		type, 
		urgency, 
		due_date
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION