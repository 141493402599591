import React from 'react'

const Loading = ({size = "regular"}) => {
  let classNameProp = '';
  switch (size) {
    case 'small':
      classNameProp = 'spinner-border text-primary spinner-border-sm';
      break;

    case 'regular':
    default:
      classNameProp = 'spinner-border text-primary';
      break;
  }

  return (
    <div className={classNameProp} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export default Loading
