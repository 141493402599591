export const getConsecutiveDates = (dayOfMonth, startDate) => {
  const result = [];
  let startDateObj = new Date(startDate.split('/').reverse().join('-'));
  const today = new Date();

  while (startDateObj <= today) {
    const year = startDateObj.getFullYear();
    const month = startDateObj.getMonth() + 1;
    const numDaysInMonth = new Date(year, month, 0).getDate();

    let dayOfMonth_ = dayOfMonth;
    if (dayOfMonth > numDaysInMonth) {
      dayOfMonth_ = numDaysInMonth;
    }
    
    const date = new Date(year, month - 1, dayOfMonth_);

    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    result.push(formattedDate);

    startDateObj.setMonth(startDateObj.getMonth() + 1);
    dayOfMonth_ = dayOfMonth;
  }

  return result;
}

export const daysPassedSince = (dateString) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInMs = currentDate - givenDate;
  const daysPassed = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return daysPassed;
}
