import React, {useState} from 'react'
import {formatDate, formatPriceInEUR} from '../../../../services/Format.js';
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import {DELETE_UNIT_INCOME} from '../../../../graph-ql/unit_income.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';

const UnitIncomeDetailsView = ({data, editIncomeToUnitHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_INCOME, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const actions = [
    {
       key: 'edit-income-details',
       label: 'Edit details',
       handleClick: (id) => {
        editIncomeToUnitHandlerFn(id);
       }
     },
     {
       key: 'delete-income',
       label: 'Delete record',
       handleClick: (id) => {
         handleOpenModal(id);
       }
     },
   ]

  return <>
    <h3>Income details</h3>
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">Tenant</th>
          <th scope="col">Date</th>
          <th scope="col">Value</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>{
        data.map(item => {
          return !hiddenComponents.includes(item.id) && <tr key={
            item.id
          }>
            {visibleModal === item.id && <ConfirmationModal 
              key={`confirmation-modal-${item.id}`}
              id={item.id}
              title="Delete?" 
              body="This operation can't be undone." 
              cancelButtonLabel="Cancel" 
              confirmButtonLabel="Delete"
              cancelButtonFn={handleCloseModal}
              confirmButtonFn={
                async () => await handleDeleteObject(item.id)
              }
              isLoading={isDeletionLoading}
            />}
            <td>{item.tenant.name}</td>
            <td>{formatDate(item.billing_timestamp)}</td>
            <td>{formatPriceInEUR(item.value)}</td>
            <td>
              <div className="nav-item dropdown position-relative">
                <a
                  className="position-absolute top-100 start-50 translate-middle mt-1"
                  data-bs-toggle="dropdown"
                  role="button"
                  aria-expanded="false">
                  {threeDotMenuIcon}
                </a>
                <ul className="dropdown-menu">
                  {
                  actions.map(action => {
                    return <li key={
                      action.key
                    }>
                      <a className="dropdown-item"
                        onClick={
                          () => action.handleClick(item.id)
                      }>
                        {
                        action.label
                      }</a>
                    </li>
                })
                } 
                </ul>
              </div>
            </td>
          </tr>
      })
      }</tbody>
    </table>
  </>
}

export default UnitIncomeDetailsView