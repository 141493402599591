import React from 'react'
import NotificationItem from './NotificationItem.js'

const NotificationContainer = ({data}) => {
  return (
    <div className="list-group" style={{position: 'fixed', top: '40px', right: '20px', width: '400px'}}>
      {
      data.map(item => {
        return <NotificationItem key={
            item.id
          }
          title={
            item.title
          }
          content={
            item.content
          }/>
      })
    } </div>
  )
}

export default NotificationContainer
