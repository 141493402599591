import React from 'react';
import Dashboard from '../components/dashboard/Dashboard.js';
import GraphQLRequest from '../services/GraphQLRequest.js';
import {GET_STATS} from '../graph-ql/dashboard.js';

const DashboardContainer = () => {
  return <GraphQLRequest query={GET_STATS}
    componentToRender={Dashboard}/>
}

export default DashboardContainer
