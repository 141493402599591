import React, {useState} from 'react'
import UtilitiesContainer from '../../containers/UtilitiesContainer.js'
import PageHeading from '../../components/common/PageHeading.js'
import {useNavigate} from "react-router-dom";
import { LIST_COUNTRIES_WITH_UTILITIES } from '../../graph-ql/country.js';
import CountryFilter from '../../components/common/filter/CountryFilter.js';
import UtilityType from '../../enums/UtilityType.js';
import EnumFilter from '../../components/common/filter/EnumFilter.js';

const UtilitiesPage = () => {
  const [filterData, setFilterData] = useState({});
  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.value === '0') {
      setFilterData((prevSelectedValues) => {
        const { [event.target.name]: _, ...updatedValues } = prevSelectedValues;
        return updatedValues;
      });
    } else {
      setFilterData((prevSelectedValues) => ({
        ...prevSelectedValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const addUtilityHandler = () => {
    navigate('/utility/add')
  }

  return (
    <>
      <PageHeading heading="Utilities"
        buttonLabel={'Add utility'}
        onClickHandler={addUtilityHandler}/>
      <div className="d-flex flex-row">
        <div className="p-2">
          <span>Filter</span>
        </div>
        <div className="p-2">
          <EnumFilter 
            label={"All types of utility"}
            enumData={UtilityType} 
            handleChange={handleChange} 
          />
        </div>
        <div className="p-2">
          <CountryFilter 
            query={LIST_COUNTRIES_WITH_UTILITIES} 
            handleChange={handleChange}
            dataItemKey={'utility_list'}
          />
        </div>
      </div>
      <UtilitiesContainer queryParams={filterData}/>
    </>
  )
}

export default UtilitiesPage
