import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import { useParams } from 'react-router-dom'

const UnitMutatePage = ({submitCallbackFn}) => {
  let {id} = useParams();
  const isEdit = parseInt(id) > 0;
  return (
    <>
      <PageHeading heading={isEdit ? 'Update property' : 'Add property'}/>
      <Form
        objectID={parseInt(id) || 0}
        formID={'unit-mutate'}
        successMessage={isEdit ? 'Property data was updated' : 'Property was added'}
        formSubmitCallback={submitCallbackFn}
      />
    </>
  )
}

export default UnitMutatePage
