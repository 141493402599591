import React from 'react'
import IncomeNavigationRegularListItem from '../income/IncomeNavigationRegularListItem.js';

const TenantNavigationTabs = ({currentPage, handleClick, id}) => {

  const pages = [
    {
      key: 'tenants-list',
      label: 'Tenants list'
    },
    {
      key: 'insights',
      label: 'Insights'
    },
  ];

  return (
    <ul className="nav nav-tabs mb-3">
      {
      pages.map(page => {
        return <div key={
          page.key
        }>
          <IncomeNavigationRegularListItem page={page}
            handleClick={handleClick}
            currentPage={currentPage}/>
        </div>
    })
    } </ul>
  )
}

export default TenantNavigationTabs
