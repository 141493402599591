import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { xCheckedIcon } from '../../../../../assets/svg/icons.js';

const SelectableCard = ({ card, onSelect, isSelected }) => {
  return (
    <Card 
      className={`mb-3 ${isSelected ? 'border-primary' : ''}`} 
      onClick={() => onSelect(card.id)}
      style={{ cursor: 'pointer', position: 'relative', height: '100%', width: '100%' }}
    >
      <Row nogutters="true" className="h-100">
        <Col md={4}>
        {card.image != '' && <Card.Img 
          variant="left" 
          src={card.image} 
          className="img-fluid" 
          style={{ height: '90%', verticalAlign: 'middle', margin: '5px' }} 
        />}
        </Col>
        <Col md={7} className="d-flex flex-column">
          <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title>{card.title}</Card.Title>
            <Card.Text>{card.text}</Card.Text>
          </Card.Body>
        </Col>
        {isSelected && (
          <Col md={1} className="d-flex align-items-end justify-content-center">
            <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
              {xCheckedIcon}
            </div>
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default SelectableCard