import React, {useState} from 'react'
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import {DELETE_UNIT_CONTACT} from '../../../../graph-ql/unit_contact.js';

const UnitContactListTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_CONTACT, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const actions = [
    {
      key: 'send-email',
      label: 'Send email',
      handleClick: () => {
        console.log('send email');
      }
    },
    {
      key: 'contact-whatsapp',
      label: 'Message on Whatsapp',
      handleClick: () => {
        console.log('Contact whatsapp');
      }
    },
    {
      key: 'edit-contact-details',
      label: 'Edit details',
      handleClick: (id) => {
        editHandlerFn(id);
      }
    },
    {
      key: 'delete-contact',
      label: 'Delete record',
      handleClick: (id) => {
        handleOpenModal(id);
      }
    },
  ]

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are no contacts!'
      messageStringEnd='to this property'
      callToActionString='Add your first contact'
      callToActionFn={addHandlerFn}
    />
  }

  return <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Phone</th>
        <th scope="col">Email</th>
        <th scope="col">Door number</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>{
      data.map(item => {
        return !hiddenComponents.includes(item.id) && <tr key={
          item.id
        }>
          {visibleModal === item.id && <ConfirmationModal 
            key={`confirmation-modal-${item.id}`}
            id={item.id}
            title="Delete?" 
            body="This operation can't be undone." 
            cancelButtonLabel="Cancel" 
            confirmButtonLabel="Delete"
            cancelButtonFn={handleCloseModal}
            confirmButtonFn={
              async () => await handleDeleteObject(item.id)
            }
            isLoading={isDeletionLoading}
          />}
          <td>{item.name}</td>
          <td><a target="_blank" href={`http://wa.me/${item.phone}`}>{item.phone}</a></td>
          <td><a target="_blank" href={`mailto:${item.email}`}>{item.email}</a></td>
          <td>{item.door_number}</td>
          <td>
            <div className="nav-item dropdown position-relative">
              <a
                className="position-absolute top-100 start-50 translate-middle mt-1"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false">
                {threeDotMenuIcon}
              </a>
              <ul className="dropdown-menu">
                {
                actions.map(action => {
                  return <li key={
                    action.key
                  }>
                    <a className="dropdown-item"
                      onClick={
                        () => action.handleClick(item.id)
                    }>
                      {
                      action.label
                    }</a>
                  </li>
              })
              } 
              </ul>
            </div>
          </td>
        </tr>
    })
    }</tbody>
  </table>
}

export default UnitContactListTable