import React, {useState} from 'react'
import { formatPriceInEUR } from '../../../../services/Format';

const UnitIncomeTimelineView = ({data}) => {

  const formatData = (data) => {
    const result = {};

    for (let i = 0; i < data.length; i++) {
      let year = parseInt(data[i].billing_timestamp.split("-")[0], 10);
      let month = parseInt(data[i].billing_timestamp.split("-")[1], 10);

      if (!result.hasOwnProperty(year)) {
        result[year] = {};
      }

      if (!result[year].hasOwnProperty(month)) {
        result[year][month] = 0;
      }

      result[year][month] += data[i].value;
    }

    for (let i in result) {
      for (let j = 1; j <= 12; j++) {
        if (!result[i].hasOwnProperty(j)) {
          result[i][j] = 0;
        }
      }
    }

    return result;
  }

  const sumByYear = (data) => {
    const result = {};
  
    // Iterate over each year in the formatted data
    Object.entries(data).forEach(([year, months]) => {
      // Sum all the month values for the given year
      const yearlySum = Object.values(months).reduce((sum, monthValue) => sum + monthValue, 0);
      
      // Store the sum in the result object, grouped by year
      result[year] = yearlySum;
    });
  
    return result;
  };

  const calculateGrandTotal = (data) => {
    let grandTotal = 0;
  
    // Iterate over each year in the formatted data
    Object.values(data).forEach((months) => {
      // Sum all the month values for the given year
      const yearlySum = Object.values(months).reduce((sum, monthValue) => sum + monthValue, 0);
  
      // Add the yearly sum to the grand total
      grandTotal += yearlySum;
    });
  
    return grandTotal;
  };

  const formattedData = formatData(data);
  const yearlyData = sumByYear(formattedData);
  const grandTotal = calculateGrandTotal(formattedData);

  return <table className="table">
    <thead>
      <tr>
        <th scope="col">Year/Month</th>
        <th scope="col">January</th>
        <th scope="col">February</th>
        <th scope="col">March</th>
        <th scope="col">April</th>
        <th scope="col">May</th>
        <th scope="col">June</th>
        <th scope="col">July</th>
        <th scope="col">August</th>
        <th scope="col">September</th>
        <th scope="col">October</th>
        <th scope="col">November</th>
        <th scope="col">December</th>
        <th scope="col" className="text-end highlight">Yearly income</th>
      </tr>
    </thead>
    <tbody>
    {Object.keys(formattedData).map(year => {
      return <tr>
        <th scope="row">{year}</th>
        {Array.from({ length: 12 }, (_, i) => i).map(month => {
          const value = formattedData[year][month + 1];
          return <td className={value === 0 ? 'faded' : ''}>{value === 0 ? '-' : formatPriceInEUR(value)}</td>
      })}
         <td scope="row" className="text-end"><b>{formatPriceInEUR(yearlyData[year])}</b></td>
      </tr>
    })}
      <tr className="highlight">
        <th>Total lifetime income</th>
        <td scope="row" colSpan="13" className="text-end"><b>{formatPriceInEUR(grandTotal)}</b></td>
      </tr>
    </tbody>
  </table>
}

export default UnitIncomeTimelineView