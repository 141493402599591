import React from 'react';
import { formatMonths, formatPriceInEUR, formatPercentage } from '../../services/Format.js';
import { useNavigate } from 'react-router-dom';
import { clockIcon, emailIcon, hourGlassIcon, profileTypeIcon, whatsAppIcon, houseIcon, currencyExchangeIcon, percentIcon, movedOutIcon } from '../../assets/svg/icons.js';
import CardTitleWithThreeDotMenu from '../common/card/CardTitleWithThreeDotMenu.js';
import CardContentItem from '../common/card/CardContentItem.js';
import UnitIdentifier from '../unit/fragments/UnitIdentifier.js';

const TenantCard = (props) => {
  const navigate = useNavigate();
  const redirectToEdit = (id) => {
    navigate('/tenant/edit/' + id);
  }

  const actions = [
    {
      key: 'edit',
      label: 'Edit details',
      handleClick: (id) => {
        redirectToEdit(id);
      }
    }, {
      key: 'delete',
      label: 'Delete record',
      handleClick: (id) => {
        props.handleOpenModal(id);
      }
    },
  ];

  const unitLinks = props.units ? props.units.map(unit => <UnitIdentifier key={unit.id} id={unit.id} identifier={unit.identifier} />) : <i>none</i>;
  const cardIcon = props.remaining_contract_period_in_months === 0 ? movedOutIcon : null;

  return (
    <div className="card mb-3">
      <div className="card-body">
        <CardTitleWithThreeDotMenu id={props.id} title={props.name} actions={actions} icon={cardIcon} />
        <div className="d-flex">
            <div className="w-50">
            <CardContentItem label={profileTypeIcon} text={props.profile_type_display} />
            <CardContentItem label={emailIcon} text={<a target="_blank" href={`mailto:${props.email}`} className="ms-1">{props.email}</a>} />
            <CardContentItem label={whatsAppIcon} text={<a target="_blank" href={`http://wa.me/${props.phone}`} className="ms-1">{props.phone}</a>} />
            <CardContentItem label={clockIcon} text={`Length of stay: ${formatMonths(props.length_of_stay_in_months)}`} />
            <CardContentItem label={hourGlassIcon} text={`Remaining contract duration: ${formatMonths(props.remaining_contract_period_in_months)}`} />
            <CardContentItem label={percentIcon} text={`Has pets: ${props.has_pets ? 'Yes' : 'No'}`} />
            </div>
            <div className="w-50">
              <CardContentItem label={houseIcon} text={unitLinks} />
              <CardContentItem label={currencyExchangeIcon} text={`Monthly rent: ${formatPriceInEUR(props.monthly_income)}`} />
              <CardContentItem label={percentIcon} text={`Monthly income weight: ${formatPercentage(props.monthly_income_weight)}`} />
            </div>
          </div>
      </div>
    </div>
  )
}

export default TenantCard
