import React from 'react'
import { formatPriceInEUR } from '../../services/Format.js';
import DashboardCard from '../dashboard/DashboardCard.js';

const getLastMonth = () => {
  const currentDate = new Date();
  currentDate.setDate(0);
  return currentDate.toLocaleString('en-US', {month: 'long'});
}

const IncomeOverview = ({data}) => {
  const currentMonth = new Date().toLocaleString('en-US', {month: 'long'});
  const lastMonth = getLastMonth();
  
  if (!data.hasOwnProperty(currentMonth)) {
    data[currentMonth] = 0.0;
  }
  if (!data.hasOwnProperty(lastMonth)) {
    data[lastMonth] = 0.0;
  }

  let icon;
  let delta;

  if (data[lastMonth] > data[currentMonth]) {
    delta = -(data[lastMonth] - data[currentMonth]);
    icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
    </svg>;
  } else if (data[lastMonth] < data[currentMonth]) {
    delta = data[currentMonth] - data[lastMonth];
    icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
    </svg>;
  } else {
    delta = 0;
    icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-repeat" viewBox="0 0 16 16">
      <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
    </svg>;
  }

  return <>
    <div className="container text-center">
      <div className="row mt-3">
        <div className="col">
          {
          <DashboardCard title="Last months income" value={
            <>
              <span>{formatPriceInEUR(data[lastMonth])}</span>
              <span>{icon}</span>
            </>
          }
          tooltipText="Monthly income generated from this property" />
        }</div>
        <div className="col">
          {
          <DashboardCard title="Current months income" value={
            <>
              <span>{formatPriceInEUR(data[currentMonth])}</span>
              <span>{icon}</span>
            </>
          }
          tooltipText="bla" />
        } </div>
        <div className="col">
          {
          <DashboardCard title="Delta" value={
            <>
              <span>{formatPriceInEUR(delta)}</span>
              <span>{icon}</span>
            </>
          }
          tooltipText="Fix this" />
        } </div>
      </div>
    </div>
  </>
}

export default IncomeOverview