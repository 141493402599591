import React from 'react';
import TaskCard from './TaskCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';

const TaskList = ({data, error, loading}) => {
  return <ComponentWithLoading data={data}
    error={error}
    loading={loading}
    componentToRender={TaskCard}/>
};

export default TaskList;
