import React from 'react'
import IncomeChart from '../IncomeChart.js'
import UnitDistribution from './UnitDistribution.js'

const FinancialInsights = ({
  data,
  goal = 0,
  currentMonthUnitIncome = null,
  title="Financials over time",
  showLegend=true,
}) => {
  return (
    <>
      {data && <IncomeChart title={title} data={data} goal={goal} showForecast={false} className={'mt-3 mb-5 w-100'} showLegend={showLegend} />}
      {currentMonthUnitIncome && <UnitDistribution data={currentMonthUnitIncome}/>}
    </>
  )
}

export default FinancialInsights