import React, { useState, useEffect } from 'react';
import '../../styles/image_grid.css';
import { leftArrow, rightArrow } from '../../assets/svg/icons';

// Lightbox Component
const Lightbox = ({ image, onClose, onNext, onPrev }) => {

  // Add event listener to detect the Escape and Arrow key presses
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose(); // Close the lightbox if Escape is pressed
      } else if (event.key === 'ArrowRight') {
        onNext(); // Navigate to the next image
      } else if (event.key === 'ArrowLeft') {
        onPrev(); // Navigate to the previous image
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, onNext, onPrev]);

  return (
    <div className="lightbox">
      <span className="close" onClick={onClose}>&times;</span>
      <img className="lightbox-content" src={image} alt="Enlarged View" />
      <a className="prev" onClick={onPrev}>{leftArrow}</a>
      <a className="next" onClick={onNext}>{rightArrow}</a>
    </div>
  );
};

// Main Image Grid Component
const ImageGrid = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Handle image click to open the lightbox
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  // Close the lightbox
  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  // Navigate to the next image
  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Navigate to the previous image
  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div>
      <div className="grid-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className="grid-item"
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>

      {/* Lightbox - only display when an image is selected */}
      {selectedImageIndex !== null && (
        <Lightbox
          image={images[selectedImageIndex]}
          onClose={handleClose}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </div>
  );
};

export default ImageGrid;