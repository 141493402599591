import {gql} from 'graphql-request';

export const DELETE_TENANT = gql `mutation tenant_delete(
  $id: ID!
) {
  tenant_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_TENANT = gql `query tenant_list{
  tenant_list
    {
    id,  
		name, 
		email, 
		phone, 
		profile_type, 
		profile_type_display,
    length_of_stay_in_months,
    remaining_contract_period_in_months,
    forecasted_remaining_income,
    monthly_income,
    monthly_income_weight,
    units {
      id,
      identifier
    },
    has_pets,
  }
}`;

export const VIEW_TENANT = gql `query view_tenant(
  $id: ID!
){
  tenant(id: $id) {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		name, 
		email, 
		phone, 
		profile_type, 
		profile_type_display
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION