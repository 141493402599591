import React, {useCallback, useState, useEffect} from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType.js';
import { formatDateToDDMMYYY, formatPriceInEUR } from '../../../services/Format';
import { findObjectByKey } from '../../../services/Array.js';

const UnitInventoryMutatePage = ({
  id, 
  action, 
  extraData, 
  formSubmitCallbackFn,
}) => {
  const [initialFormData, setInitialFormData] = useState({});
  const [disabledElements, setDisabledElements] = useState([]);
  const [hiddenElements, setHiddenElements] = useState([]);
  const [purchaseTimestampFromUnit, setPurchaseTimestampFromUnit] = useState(null);
  const [didntComeWithUnitInfo, setDidntComeWithUnitInfo] = useState(null);
  const [purchaseTimestampInfo, setPurchaseTimestampInfo] = useState(null);

  useEffect(() => {
    if (!initialFormData.hasOwnProperty('form')) {
      return;
    }

    if (findObjectByKey(initialFormData.form.elements, 'name', 'came_with_unit').value === 'on') {
      setPurchaseTimestampFromUnit(formatDateToDDMMYYY(extraData.unit.purchase_timestamp));
      setDisabledElements(['purchase_timestamp']);
      setPurchaseTimestampInfo('Property acquisition date');
      setHiddenElements(['buy_price']);
    } else {
      if (findObjectByKey(initialFormData.form.elements, 'name', 'buy_price').value > 0) {
        setDidntComeWithUnitInfo('Incurred an expense of ' + formatPriceInEUR(findObjectByKey(initialFormData.form.elements, 'name', 'buy_price').value));
      } else {
        setDidntComeWithUnitInfo('Will record an expense');
      }
    }
  }, [extraData.unit.purchase_timestamp, initialFormData]);
  
  const onElementUpdate = useCallback((formData, lastChangedElement) => {
    if (lastChangedElement !== null) {
      switch(lastChangedElement.target.name) {
        case 'came_with_unit':
          if (formData['came_with_unit'] !== 'on') {
            setPurchaseTimestampFromUnit(formatDateToDDMMYYY(Date.now()));
            setDisabledElements([]);
            setPurchaseTimestampInfo(null);
            setHiddenElements([]);
            maybeSetDidntComeWithUnitInfo(formData);
          } else {
            setPurchaseTimestampFromUnit(formatDateToDDMMYYY(extraData.unit.purchase_timestamp));
            setDisabledElements(['purchase_timestamp']);
            setPurchaseTimestampInfo('Property acquisition date');
            setHiddenElements(['buy_price']);
            setDidntComeWithUnitInfo(null);
          }
          break;

        case 'buy_price':
          maybeSetDidntComeWithUnitInfo(formData);
          break;
      }
      
    } 

    if (lastChangedElement !== null && lastChangedElement.target.name === 'purchase_timestamp') {
      setPurchaseTimestampFromUnit(formData['purchase_timestamp']);
    }
  }, [extraData.unit.purchase_timestamp]);

  const maybeSetDidntComeWithUnitInfo = (formData) => {
    if (parseFloat(formData['buy_price']) > 0) {
      setDidntComeWithUnitInfo('Will record an expense of ' + formatPriceInEUR(formData['buy_price']));
    } else {
      setDidntComeWithUnitInfo('Will record an expense');
    }
  }

  return (
    <>
      <PageHeading heading={action === RouteActionType.add ? 'Add inventory item to property' : 'Edit property inventory item'} />
      <Form 
        formID={'unit-inventory-mutate'}
        successMessage={action === RouteActionType.add ? 'Inventory item was added' : 'Inventory item was updated'}
        objectID={parseInt(id) || 0}
        onChangeHandler={onElementUpdate}
        disabledElements={disabledElements}
        extraData={extraData}
        hiddenElements={hiddenElements}
        formInfoElements={{
          frm_el_came_with_unit: didntComeWithUnitInfo, 
          frm_el_purchase_timestamp: purchaseTimestampInfo,
        }}
        updatedFormValues={{
          frm_el_purchase_timestamp: purchaseTimestampFromUnit,
        }}
        formDataCallback={setInitialFormData}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitInventoryMutatePage
