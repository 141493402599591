import React, {useState} from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType';

const UnitExpenseMutatePage = ({id, action, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update expense' : 'Add expense'}/>
      <Form 
        formID="unit-expense-mutate"
        successMessage={action === RouteActionType.edit ? 'Expense data was updated' : 'Expense was added'}
        objectID={parseInt(id) || 0}
        formSubmitCallback={formSubmitCallbackFn}
        extraData={extraData}
      />
    </>
  )
}

export default UnitExpenseMutatePage
