import React from 'react'

const PieChartWithText = ({ data, centerLabel, showPercentage = false }) => {
  const totalCount = data.reduce((sum, sub) => sum + sub.value, 0);
  const circumference = 2 * Math.PI * 50; // Circumference of the circle (2πr for r = 50)
  const radius = 50; // Radius of the circle
  const centerX = 60; // X center of the circle in SVG
  const centerY = 60; // Y center of the circle in SVG
  const strokeWidth = 5; // Thickness of the border

  let cumulativePercentage = 0;

  const segments = data.map((sub, index) => {
    const { label, value, color } = sub;
    const percentage = (value / totalCount) * 100;
    const startAngle = (cumulativePercentage / 100) * 360;
    const endAngle = ((cumulativePercentage + percentage) / 100) * 360;
    cumulativePercentage += percentage;

    const dashArray = `${(percentage / 100) * circumference} ${circumference}`;

    return (
      <circle
        key={index}
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={-((cumulativePercentage - percentage) / 100) * circumference}
      />
    );
  });

  return (
    <div style={{ position: 'relative', margin: '0 auto' }}>
      <div style={{ position: 'relative', width: '120px', height: '120px' }}>
        <svg width="120" height="120" viewBox="0 0 120 120">
          {/* The base circle */}
          <circle
            cx={centerX}
            cy={centerY}
            r={radius}
            fill="none"
            stroke="#f5f5f5"
            strokeWidth={strokeWidth}
          />
          {segments}
        </svg>
        <div style={{
          position: 'absolute',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '18px',
          textAlign: 'center',
          pointerEvents: 'none', // Ensures the text is clickable

        }}>
          <strong>{totalCount}</strong>
          <p style={{ fontSize: '11px' }}>{centerLabel}</p>
        </div>
      </div>

      {/* Legend */}
      <div style={{ marginTop: '10px', textAlign: 'left' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {data.map((sub, index) => {
            const percentage = ((sub.value / totalCount) * 100).toFixed(0);
            return (
              <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <span style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: sub.color,
                marginRight: '8px',
              }}></span>
              <span>{sub.label.charAt(0).toUpperCase() + sub.label.slice(1)}</span>
              <span className="ms-1">({showPercentage ? `${percentage}%` : sub.value})</span>
            </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default PieChartWithText