import React, {useState, useEffect} from 'react'
import UnitAddress from '../fragments/UnitAddress.js';
import UnitPurchaseDate from '../fragments/UnitPurchaseDate.js';
import { formatPriceInEUR, formatDate } from '../../../services/Format';
import { LIST_UNIT_TRANSACTION } from '../../../graph-ql/unit_transaction';
import Loading from '../../common/Loading.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import { formatMonths } from '../../../services/Format';

const UnitViewDetails = ({unit_id, unit_data}) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_TRANSACTION, {unit_id: unit_id, type: 'buy'});
      setData(response.unit_transaction_list[0]);
      setIsLoaded(true);
    };
    fetchData();
  }, []);


  if (!isLoaded) {
    return <Loading />
  }

  return (
    <>
      <table className="key-value w-100">
        <thead className="table-light">
          <tr><td colSpan={2}>Details</td></tr>
        </thead>
        <tbody>
          <tr>
            <td>Address</td>
            <td><UnitAddress address={unit_data.address} /></td>
          </tr>
        </tbody>
      </table>
      <table className="mt-3 key-value w-100">
        <thead className="table-light">
          <tr>
            <td colSpan={2}>Purchase details</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Purchase date</td>
            <td><UnitPurchaseDate purchaseDate={data.timestamp} /></td>
          </tr>
          <tr>
            <td>Purchase price</td>
            <td>{formatPriceInEUR(unit_data.purchase_price)}</td>
          </tr>
          <tr>
            <td>Government tax</td>
            <td>{formatPriceInEUR(data.government_tax)}</td>
          </tr>
          <tr>
            <td>Purchase fees</td>
            <td>{formatPriceInEUR(unit_data.purchase_fees)}</td>
          </tr>
          <tr>
            <td>Total investment</td>
            <td>{formatPriceInEUR(unit_data.total_investment)}</td>
          </tr>
          <tr>
            <td>Time in portfolio</td>
            <td>{formatMonths(unit_data.time_in_portfolio_in_years * 12)}</td>
          </tr>
        </tbody>
      </table>
      <table className="mt-3 key-value w-100">
        <thead className="table-light">
          <tr>
            <td colSpan={2}>Notary</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{data.notary.name ? data.notary.name : 'N/A'} </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{data.notary.email ? <a target="_blank" href={`mailto:${data.notary.email}`}>{data.notary.email}</a> : 'N/A'} </td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>{data.notary_fee ? formatPriceInEUR(data.notary_fee) : 'N/A'}</td>
          </tr>
        </tbody>
      </table>
      <table className="mt-3 key-value w-100">
        <thead className="table-light">
          <tr>
            <td colSpan={2}>Real Estate Agent</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{data.real_estate_agent.name ? data.real_estate_agent.name : 'N/A'} </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{data.real_estate_agent.email ? <a target="_blank" href={`mailto:${data.real_estate_agent.email}`}>{data.real_estate_agent.email}</a> : 'N/A'} </td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>{data.agency_fee ? formatPriceInEUR(data.agency_fee) : 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default UnitViewDetails
