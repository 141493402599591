import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import { LIST_UNIT_INSURANCE } from '../../../graph-ql/unit_insurance';
import UnitInsuranceListTable from './insurance/UnitInsuranceListTable.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';
import UnitInsuranceMutatePage from '../../../pages/property/insurance/UnitInsuranceMutatePage.js';
import RouteActionType from '../../../enums/RouteActionType.js';

const UnitViewInsurance = ({unit_id}) => {
  const [insuranceID, setInsuranceID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_INSURANCE, {unit_id: unit_id, is_active: true});
      setData(response.unit_insurance_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData]);

  const editHandler = (id) => {
    setInsuranceID(id);
    setSubpage('edit');
  }

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  return (
    <>
      <UnitViewContentButtons 
        addButtonLabel={'Add insurance'} 
        viewButtonLabel={'View insurances'}
        cancelButtonLabel={cancelButtonLabel} 
        subpage={subpage} 
        setSubpageFn={setSubpage}
      />
      {subpage === 'list' && isLoaded && <UnitInsuranceListTable 
        data={data} 
        editHandlerFn={editHandler}
        addHandlerFn={() => setSubpage('add')}
      />}
      {subpage === 'list' && !isLoaded && <Loading />}
      {subpage === 'add' && <UnitInsuranceMutatePage 
        formSubmitCallbackFn={refreshData} 
        action={RouteActionType.add} 
        extraData={{'unit-id': unit_id}} 
      />}
      {subpage === 'edit' && <UnitInsuranceMutatePage 
        formSubmitCallbackFn={() => {
          refreshData(); 
          setCancelButtonLabel('View insurances');
        }} 
        action={RouteActionType.edit} 
        extraData={{'unit-id': unit_id}} 
        id={insuranceID}
      />}
    </>

  )
}

export default UnitViewInsurance
