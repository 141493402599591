const ServiceProviderType = {
  handyman: 'handyman',
  plumber: 'plumber',
  electrician: 'electrician',
  heating: 'heating',
  builder: 'builder',
  cleaner: 'cleaner',
  // notary: 'notary',
  // utility: 'utility',
  // real_estate_agent: 'real_estate_agent',
}

export default ServiceProviderType;