import React from 'react';
import UtilityList from '../components/utility/UtilityList.js';
import GraphQLRequest from '../services/GraphQLRequest.js';
import {LIST_UTILITY} from '../graph-ql/utility.js';

const UtilitiesContainer = ({queryParams}) => {
  return <GraphQLRequest 
    query={LIST_UTILITY}
    componentToRender={UtilityList}
    queryParams={queryParams} 
    />
}

export default UtilitiesContainer
