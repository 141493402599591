import React, {useState, useEffect} from 'react'
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { formatMonths } from '../../../../services/Format';
import { clockIcon, emailIcon, governmentDocumentIcon, hourGlassIcon, pdfIcon, profileTypeIcon, whatsAppIcon } from '../../../../assets/svg/icons.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';
import {formatPriceInEUR, formatOrdinalNumber, formatDate, formatPercentage} from '../../../../services/Format.js';
import {DELETE_UNIT_TENANT} from '../../../../graph-ql/unit_tenant.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import MarkTenantAsCheckedOutAction from '../../../common/action/MarkTenantAsCheckedOutAction.js';
import ConfirmRentReceivedAction from '../../../common/action/ConfirmRentReceivedAction.js';
import RenewTenancyContractAction from '../../../common/action/RenewTenancyContractAction.js';
import DownloadFileAction from '../../../common/action/DownloadFileAction.js';
import ClickAction from '../../../common/action/ClickAction.js';
import TenantMovedOutIndicator from '../../../tenant/TenantMovedOutIndicator.js';
import CircularProgressBar from '../../../common/chart/CircularProgressBar.js';
import { gql } from 'graphql-request';
import Loading from '../../../common/Loading.js';

const UnitTenantListTable = ({data, unit_id, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [isInsightsLoaded, setIsInsightsLoaded] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const fetchInsights = async () => {

    const QUERY = gql `query view_unit(
      $id: ID!, 
    ) {
      unit(
        id: $id,
      ) {
        current_number_of_tenants,
        tenant_retention_rate,
        tenant_contract_fulfillment_rate,
        occupancy_rate,
        remaining_contract_period_in_months,
        tenant_average_stay_duration_in_months
      }
    }`;

    try {
      const response = await graphQLClient.request(QUERY, {id: unit_id});
      if (response.unit) {
        setInsightsData(response.unit);
        setIsInsightsLoaded(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchInsights();
  }, []);

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are currently no tenants in this property!'
      messageStringEnd=''
      callToActionString='Add a tenant'
      callToActionFn={addHandlerFn}
    />
  }

  const getActions = (id) => {
    return [
      // <ConfirmRentReceivedAction objectID={id} postProcessorFunc={() => setHiddenComponents(prevHiddenComponents => [...prevHiddenComponents, id])} />,
      <MarkTenantAsCheckedOutAction objectID={id} postProcessorFn={() => setHiddenComponents(prevHiddenComponents => [...prevHiddenComponents, id])} />,
      <RenewTenancyContractAction objectID={id} />,
      <DownloadFileAction 
        label={'Download tenancy contract'} 
        data={{
          id: id,
          type: 'unit_tenant',
          identifier: 'tenancy-contract',
        }} 
      />,
      <ClickAction label="Edit tenant details" handleClickFunc={() => editHandlerFn(id)} />,
      <ClickAction label="Delete record" handleClickFunc={() => handleOpenModal(id)} />
    ];
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_TENANT, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  return <>
    <div className="d-flex mt-4 mb-4">
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? insightsData.current_number_of_tenants : <Loading />} currentValue={insightsData.current_number_of_tenants} label={insightsData.current_number_of_tenants === 1 ? 'Active tenant' : 'Active tenants'} />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? formatPercentage(insightsData.occupancy_rate) : <Loading />} currentValue={insightsData.occupancy_rate} label="-1Y Occupancy rate" />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? formatPercentage(insightsData.tenant_retention_rate) : <Loading />} currentValue={insightsData.tenant_retention_rate} label="Tenant retention rate" />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? formatPercentage(insightsData.tenant_contract_fulfillment_rate) : <Loading />} currentValue={insightsData.tenant_contract_fulfillment_rate} label="Lease fulfillment rate" />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? formatMonths(insightsData.tenant_average_stay_duration_in_months, true) : <Loading />} currentValue={insightsData.tenant_average_stay_duration_in_months} label="Average stay duration" />
      </div>
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={isInsightsLoaded ? formatMonths(insightsData.remaining_contract_period_in_months) : <Loading />} currentValue={insightsData.remaining_contract_period_in_months} label="Lease ending" />
      </div>
    </div>
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th></th>
          <th scope="col">Tenant</th>
          <th scope="col">Rent price</th>
          <th scope="col">Rent due day</th>
          <th scope="col">Contract start date</th>
          <th scope="col">Contract end date</th>
          <th scope="col">Deposit value</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>{
        data.map(tenant => {
          return !hiddenComponents.includes(tenant.id) && (<>
            <tr 
              key={tenant.id} 
              className={tenant.move_out_date !== null ? 'moved-out' : ''}
            >
              {visibleModal === tenant.id && 
                <ConfirmationModal 
                  key={`confirmation-modal-${tenant.id}`}
                  id={tenant.id}
                  title="Delete?"
                  body="This operation can't be undone." 
                  cancelButtonLabel="Cancel" 
                  confirmButtonLabel="Delete"
                  cancelButtonFn={handleCloseModal}
                  confirmButtonFn={
                    async () => await handleDeleteObject(tenant.id)
                  }
                  isLoading={isDeletionLoading}
                />
            }
              <td>
                <a className="btn btn-secondary" data-bs-toggle="collapse" href={`#income-details-${tenant.id}`} role="button" aria-expanded="false" aria-controls={`#income-details-${tenant.id}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-arrow-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5"/>
                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                  </svg>
                </a>
              </td>
              <td>
                <span>{tenant.tenant.name}</span>
                {tenant.move_out_date !== null && <TenantMovedOutIndicator moveOutDate={tenant.move_out_date} />}
              </td>
              <td>{
                formatPriceInEUR(tenant.rent_price)
              }</td>
              <td>{
                formatOrdinalNumber(tenant.rent_due_day)
              }</td>
              <td>{
                formatDate(tenant.contract_start_date)
              }</td>
              <td>{
                formatDate(tenant.contract_end_date)
              }</td>
              <td>{
                formatPriceInEUR(tenant.deposit_value)
              }</td>
              <td>
                <div className="nav-item dropdown position-relative">
                  <a
                    className="position-absolute top-100 start-50 translate-middle mt-1"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false">
                    {threeDotMenuIcon}
                  </a>
                  <ul className="dropdown-menu">{getActions(tenant.id).map(action => <li>{action}</li>)}</ul>
                </div>
              </td>
            </tr>
            <tr className="collapse" key={`income-details-${tenant.id}`} id={`income-details-${tenant.id}`}>
              <td colSpan="2">
                <div>
                  <h3>Rent history</h3>
                  <OverlayTrigger key={`income-details-1-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-1`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
            
                  <OverlayTrigger key={`income-details-2-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-2`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
                  <OverlayTrigger key={`income-details-3-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-3`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
                  <OverlayTrigger key={`income-details-4-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-4`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
                  <OverlayTrigger key={`income-details-5-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-5`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
                  
                  <OverlayTrigger key={`income-details-6-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-6`}>
                    Rent due date missed</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#DC3444" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
                  </svg>
                  </OverlayTrigger>
                  
                  <OverlayTrigger key={`income-details-7-${tenant.id}`} placement="top"
                    overlay={<Tooltip id={`tooltip-top-7`}>
                    Rent paid on time</Tooltip>}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198753" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </OverlayTrigger>
                </div>
                <p className="mt-3">Forecasted income: <b>{formatPriceInEUR(tenant.tenant.forecasted_remaining_income)}</b></p>
              </td>
              <td colSpan="2">
                <p>
                  <span className="me-1">{profileTypeIcon}</span>
                  <span>{tenant.tenant.profile_type_display}</span>
                </p>
                <p>
                  <span className="me-1">{clockIcon}</span>
                  <span>{formatMonths(tenant.tenant.length_of_stay_in_months)}</span>
                </p>
                <p>
                  <span className="me-1">{hourGlassIcon}</span>
                  <span>{formatMonths(tenant.tenant.remaining_contract_period_in_months)}</span>
                </p>
              </td>
              <td colSpan="2">
                <p>
                <span className="me-1">{emailIcon}</span>
                  <a target="_blank" href={`mailto:${tenant.tenant.email}`}>{tenant.tenant.email}</a>
                </p>
                <p>
                  <span className="me-1">{whatsAppIcon}</span>
                  {tenant.tenant.phone && <a target="_blank" href={`http://wa.me/${tenant.tenant.phone}`}>{tenant.tenant.phone}</a> || <span>Not specified</span>}
                </p>
              </td>
              <td colSpan="2">
                <p>
                  <span className="me-1">{pdfIcon}</span>
                  {tenant.tenant.current_tenancy_contract_url ? <a target="_blank" href={tenant.tenant.current_tenancy_contract_url}>View tenancy contract</a> : 'No tenancy contract'}
                </p>
                <p>
                  <span className="me-1">{governmentDocumentIcon}</span>
                  {tenant.tenant.government_document_url ? <a target="_blank" href={tenant.tenant.government_document_url}>View government document</a> : 'No government document'}
                </p>
              </td>
            </tr></>)
      })
      }</tbody>
    </table>
  </>
}

export default UnitTenantListTable