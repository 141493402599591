import React, {useEffect, useState} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import {useNavigate} from "react-router-dom";
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials';
import { LIST_UNIT } from '../../graph-ql/unit.js';
import MapWithPins from '../../components/common/map/MapWithPins.js';
import UnitListNavigationTabs from '../../components/unit/view/navigation/UnitListNavigationTabs.js';
import UnitCardList from '../../components/unit/UnitCardList.js';
import CityFilter from '../../components/common/filter/CityFilter.js';
import { LIST_CITIES_WITH_UNITS } from '../../graph-ql/city.js';
import UnitListTable from '../../components/unit/UnitListTable.js';

const PropertiesPage = () => {
  const [filterData, setFilterData] = useState({});
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('card');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleChange = (event) => {
    if (event.target.value === '0') {
      setFilterData((prevSelectedValues) => {
        const { [event.target.name]: _, ...updatedValues } = prevSelectedValues;
        return updatedValues;
      });
    } else {
      setFilterData((prevSelectedValues) => ({
        ...prevSelectedValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      const response = await graphQLClient.request(LIST_UNIT, filterData);
      setData(response.unit_list);
      setIsLoaded(true);
    };
    fetchData();

  }, [filterData]);

  const addPropertyHandler = () => {
    navigate('/property/add')
  }

  return (
    <>
      <PageHeading heading="My properties"
        buttonLabel={'Add property'}
        onClickHandler={addPropertyHandler}/>
      <div className="d-flex">
        <div className="p-2 flex-grow-1">
        <CityFilter 
            query={LIST_CITIES_WITH_UNITS} 
            handleChange={handleChange}
            dataItemKey={'unit_list'}
          />
        </div>
        <div className="p-2">
          <UnitListNavigationTabs
            currentPage={currentPage}
            handleClick={setCurrentPage}/>
        </div>
      </div>
      {currentPage === 'card' && <UnitCardList data={data} loading={!isLoaded}/>}
      {currentPage === 'list' && <UnitListTable data={data} isLoaded={isLoaded} addHandlerFn={addPropertyHandler} />}
      {currentPage === 'map' && <MapWithPins data={data} isLoaded={isLoaded} />}
    </>
  )
}

export default PropertiesPage
