import React, {useState} from 'react';
import TaskActions from './TaskActions.js';
import { formatDate } from '../../services/Format';
import { calendarIcon, triangleIcon, houseIcon } from '../../assets/svg/icons.js';
import UnitIdentifier from '../unit/fragments/UnitIdentifier.js';

const TaskCard = ({
  id,
  type,
  title,
  urgency,
  due_date,
  unit,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  
  const setVisibility = (isVisible) => {
    setIsVisible(isVisible);
  };

  if (!isVisible) {
    return null;
  }

  const alertClassName = {
    0: 'text-bg-danger',
    1: 'text-bg-warning',
    2: 'text-bg-info'
  };

  const urgencyMapping = {
    0: 'red',
    1: 'orange',
    2: '#eddb1e',
    3: 'blue',
  }

  const containerClass = 'card mb-1'; // + alertClassName[urgency];
  // const actions = TaskActions[type];

  return (
    <div key={id}
      className={containerClass}
    >
      <div className="card-body">
        <div className="d-flex">
          <h5 className="card-title">
            {triangleIcon(urgencyMapping[urgency])}
            <span className="ms-1">{title}</span>
          </h5>
          <div className="d-flex ms-auto">
            {unit && <p className="card-text me-4">
              <span className="me-1">{houseIcon}</span>
              <UnitIdentifier key={unit.id} id={unit.id} identifier={unit.identifier} />
            </p>}
            <p className="card-text">
              <span className="me-1">{calendarIcon}</span>
              <span className="task-due-date">{formatDate(due_date)}</span>
            </p>
          </div>
        </div>
        <div className="btn-group" role="group">
          <TaskActions taskType={type} taskID={id} isVisibleHandler={setVisibility} />  
        </div>
      </div>
    </div>
  )
}

export default TaskCard
