import React from 'react'

const ClickAction = ({label, handleClickFunc}) => {
  return (
    <a 
      className="dropdown-item"
      onClick={handleClickFunc}
    >
      <span>{label}</span>
    </a>
  )
};

export default ClickAction;
