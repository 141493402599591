import React, { useState, useEffect } from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import {useNavigate} from "react-router-dom";
import TenantsInsightsPage from './TenantsInsightsPage.js';
import TenantNavigationTabs from '../../components/tenant/TenantNavigationTabs';
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import { LIST_TENANT } from '../../graph-ql/tenant.js';
import TenantList from '../../components/tenant/TenantList.js';
import Loading from '../../components/common/Loading.js';
import { LIST_UNIT } from '../../graph-ql/unit.js';
import UnitFilter from '../../components/common/filter/UnitFilter.js';
import { LIST_UNIT_TENANT } from '../../graph-ql/unit_tenant.js';

const TenantsPage = () => {
  const [filterData, setFilterData] = useState({});
  const [currentPage, setCurrentPage] = useState('tenants-list');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFilterLoaded, setIsFilterLoaded] = useState(false);
  const [profileTypeDistribution, setProfileTypeDistribution] = useState({});
  const [remainingContractLengthDistribution, setRemainingContractLengthDistribution] = useState({});
  const [remainingContractLengthDistributionDetails, setRemainingContractLengthDistributionDetails] = useState({});
  const [profileTypeDistributionDetails, setProfileTypeDistributionDetails] = useState({});
  const [lengthOfStayDistribution, setLengthOfStayDistribution] = useState({});
  const [lengthOfStayDistributionDetails, setLengthOfStayDistributionDetails] = useState({});
  const navigate = useNavigate();

  const addTenantHandler = () => {
    navigate('/tenant/add')
  }

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    setIsFilterLoaded(false);
    const fetchData = async (filterData) => {
      let response = [];
      if (filterData.hasOwnProperty('unit_id')) {
        const result = await graphQLClient.request(LIST_UNIT_TENANT, filterData);
        
        result.unit_tenant_list.map(v => {
          response.push(v.tenant);
        });
      } else {
        const result = await graphQLClient.request(LIST_TENANT);
        response = result.tenant_list;
      }
      
      setData(response);
      let profileTypeDistribution_ = {};
      let profileTypeDistributionDetails_ = {};

      let lengthOfStayDistribution_ = {};
      let lengthOfStayDistributionDetails_ = {};

      let remainingContractLengthDistribution_ = {};
      let remainingContractLengthDistributionDetails_ = {};

      response.map(item => {
        // profile type
        if (!profileTypeDistribution_.hasOwnProperty(item.profile_type_display)) {
          profileTypeDistribution_[item.profile_type_display] = 0;
        }
        if (!profileTypeDistributionDetails_.hasOwnProperty(item.profile_type_display)) {
          profileTypeDistributionDetails_[item.profile_type_display] = [];
        }

        profileTypeDistribution_[item.profile_type_display]++;
        profileTypeDistributionDetails_[item.profile_type_display][item.id] = item;
        setProfileTypeDistribution(profileTypeDistribution_);
        setProfileTypeDistributionDetails(profileTypeDistributionDetails_);
        
        // remaining contract length
        const remaining_contract_length_bucket = getRemainingContractBucket(item.remaining_contract_period_in_months);
        if (!remainingContractLengthDistribution_.hasOwnProperty(remaining_contract_length_bucket)) {
          remainingContractLengthDistribution_[remaining_contract_length_bucket] = 0;
        }
        if (!remainingContractLengthDistributionDetails_.hasOwnProperty(remaining_contract_length_bucket)) {
          remainingContractLengthDistributionDetails_[remaining_contract_length_bucket] = [];
        }

        remainingContractLengthDistribution_[remaining_contract_length_bucket]++;
        remainingContractLengthDistributionDetails_[remaining_contract_length_bucket][item.id] = item;
        setRemainingContractLengthDistribution(remainingContractLengthDistribution_);
        setRemainingContractLengthDistributionDetails(remainingContractLengthDistributionDetails_);

        // length of stay
        const length_of_stay_bucket = getLengthOfStayBucket(item.length_of_stay_in_months);
        if (!lengthOfStayDistribution_.hasOwnProperty(length_of_stay_bucket)) {
          lengthOfStayDistribution_[length_of_stay_bucket] = 0;
        }
        if (!lengthOfStayDistributionDetails_.hasOwnProperty(length_of_stay_bucket)) {
          lengthOfStayDistributionDetails_[length_of_stay_bucket] = [];
        }

        lengthOfStayDistribution_[length_of_stay_bucket]++;
        lengthOfStayDistributionDetails_[length_of_stay_bucket][item.id] = item;
        setLengthOfStayDistribution(lengthOfStayDistribution_);
        setLengthOfStayDistributionDetails(lengthOfStayDistributionDetails_);
      });
      setIsLoaded(true);
      setIsFilterLoaded(true);
    };
    fetchData(filterData);

  }, [filterData]);

  // todo order the buckets ascending
  const getLengthOfStayBucket = (length_of_stay_in_months) => {
    if (length_of_stay_in_months > 5 * 12) {
      return 'More than 5 years';
    }

    if (length_of_stay_in_months > 3 * 12) {
      return 'Between 3 and 5 years';
    }

    if (length_of_stay_in_months > 12) {
      return 'Between 1 and 3 years';
    }

    if (length_of_stay_in_months < 12) {
      return 'Less than 1 year';
    }
  }

  // todo order the buckets ascending
  const getRemainingContractBucket = (input) => {
    if (input <= 1) {
      return 'Less than 1 month';
    }

    if (input <= 3) {
      return 'Less than 3 months';
    }

    if (input <= 6) {
      return 'Less than 6 monhts';
    }

    if (input <= 12) {
      return 'Less than 1 year';
    }

    if (input > 12) {
      return 'More than 1 year';
    }
  }

  if (!isLoaded) {
    return <Loading/>;
  }

  const handleFilterChange = (event) => {
    if (event.target.value === '0') {
      setFilterData((prevSelectedValues) => {
        const { [event.target.name]: _, ...updatedValues } = prevSelectedValues;
        return updatedValues;
      });
    } else {
      setFilterData((prevSelectedValues) => ({
        ...prevSelectedValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  return (
    <>
      <PageHeading heading="My tenants"
        buttonLabel={'Add tenant'}
        onClickHandler={addTenantHandler}/>
      <TenantNavigationTabs
        currentPage={currentPage}
        handleClick={setCurrentPage}/>
      <div className="d-flex flex-row">
        <div className="p-2 mt-2">
          <span>Show</span>
        </div>
        <div className="p-2">
          <UnitFilter 
            query={LIST_UNIT} 
            handleChange={handleFilterChange}
            dataItemKey={'unit_list'}
          />
        </div>
      </div>
      {currentPage === 'tenants-list' && <TenantList data={data} loading={!isFilterLoaded} />}
      {currentPage === 'insights' && <TenantsInsightsPage 
        profileTypeDistribution={profileTypeDistribution} 
        profileTypeDistributionDetails={profileTypeDistributionDetails}
        lengthOfStayDistribution={lengthOfStayDistribution}
        lengthOfStayDistributionDetails={lengthOfStayDistributionDetails}
        remainingContractLengthDistribution={remainingContractLengthDistribution} 
        remainingContractLengthDistributionDetails={remainingContractLengthDistributionDetails}
      />}
    </>
  )
}

export default TenantsPage
