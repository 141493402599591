import {gql} from 'graphql-request';

export const DELETE_NOTARY = gql `mutation notary_delete(
  $id: ID!
) {
  notary_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_NOTARY = gql `query notary_list{
  notary_list
    {
    id, 
		city {
			id, 
			name
		}, 
		name, 
		email,
    phone
  }
}`;

export const VIEW_NOTARY = gql `query view_notary(
  $id: ID!
){
  notary(id: $id) {
    id, 
		city {
			id, 
			name
		}, 
		name, 
		email
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION