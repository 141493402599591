import React, {useState, useEffect} from 'react';
import Loading from '../common/Loading.js';
import {formatPriceInEUR, formatPercentage} from '../../services/Format.js'
import UnitsWithNoTenantsContainer from '../../containers/UnitsWithNoTenantsContainer.js';
import PieChartWithText from '../common/chart/PieChartWithText.js';
import { LIST_TASK } from '../../graph-ql/unit_task.js';
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';
import TaskList from '../task/TaskList.js';
import PayoutDays from '../common/PayoutDays.js';
import { gql } from 'graphql-request';
import CircularProgressBar from '../common/chart/CircularProgressBar.js';
import { GET_INCOME } from '../../graph-ql/dashboard.js';
import { convertToIncomeData } from '../../services/Income.js';
import FinancialInsights from '../income/FinancialInsights.js';

const Dashboard = ({data, error, loading}) => {
  const [totalPotentialIncome, setTotalPotentialIncome] = useState(0);
  const [incomeExpenseData, setIncomeExpenseData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [unitsByRentedStatus, setUnitsByRentedStatus] = useState([]);
  const [rentDueDays, setRentDueDays] = useState([]);
  const [incomeData, setIncomeData] = useState([]);

  const graphQLClient = GraphQLClientWithCredentials();

  const fetchTasks = async () => {
    try {
      const response = await graphQLClient.request(LIST_TASK, {status: "todo"});
      if (response.unit_task_list && response.unit_task_list.length) {
        setTasks(response.unit_task_list);
      }
    } catch (err) {}
  };

  const groupPropertiesByTenants = (properties) => {
    let rentedCount = 0;
    let vacantCount = 0;
  
    properties.forEach(property => {
      if (property.current_number_of_tenants > 0) {
        rentedCount++;
      } else {
        vacantCount++;
      }
    });
  
    return [
      { label: 'Rented', value: rentedCount, color: '#4CAF50' },
      { label: 'Vacant', value: vacantCount, color: '#FFC107' }
    ];
  }

  const formatRentDueDaysData = (data) => {
    let result = {};
  
    data.forEach(v => {
      if (v.rent_due_day === null) {
        return;
      }
      if (!result.hasOwnProperty(v.rent_due_day)) {
        result[v.rent_due_day] = v.rent_price;
        return;
      }

      result[v.rent_due_day] += v.rent_price;
    });
    return result;
  }

  const fetchProperties = async () => {

    const LIST_UNIT_QUERY = gql `query unit_list($city_id: ID){
      unit_list(city_id: $city_id)
        {
        id, 
        current_number_of_tenants,
      }
    }`;

    try {
      const response = await graphQLClient.request(LIST_UNIT_QUERY);
      if (response.unit_list && response.unit_list.length) {
        setUnitsByRentedStatus(groupPropertiesByTenants(response.unit_list));
      }
    } catch (err) {}
  };

  const fetchIncomeData = async () => {

    try {
      const response = await graphQLClient.request(GET_INCOME);
      if (response.unit_income_list && response.unit_income_list.length) {
        setIncomeData(convertToIncomeData(response.unit_income_list, 0));
      }
    } catch (err) {}
  };

  const fetchRentDays = async () => {
    const LIST_UNIT_TENANT_QUERY = gql `query unit_tenant_list($move_out_date: String) {
      unit_tenant_list(move_out_date: $move_out_date) {
        rent_price, 
        rent_due_day
      }
    }`;

    try {
      const response = await graphQLClient.request(LIST_UNIT_TENANT_QUERY, {'move_out_date': null});
      if (response.unit_tenant_list && response.unit_tenant_list.length) {
        setRentDueDays(formatRentDueDaysData(response.unit_tenant_list));
      }
    } catch (err) {}
  }

  useEffect(() => {
    fetchTasks();
    fetchProperties();
    fetchRentDays();
    fetchIncomeData();
  }, []);

  if (loading) {
    return <Loading/>
  }
  
  return <>
    {incomeExpenseData && <div className="d-flex mt-4">
      <div className='me-4'>
        <CircularProgressBar currentValueDisplay={formatPriceInEUR(data.dashboard.monthly_income)} currentValue={data.dashboard.monthly_income} targetValue={data.dashboard.monthly_income_goal} label="Monthly income" targetValueLabel={`Goal: ${formatPriceInEUR(data.dashboard.monthly_income_goal)}`} />
      </div>
      <div className='me-4'>
        <div>
          <CircularProgressBar currentValueDisplay={formatPercentage(data.dashboard.occupancy_rate)} currentValue={data.dashboard.occupancy_rate} targetValue={100} label="Occupancy rate" />
          {data.dashboard.occupancy_rate < 100 && totalPotentialIncome > 0 &&
            <p className="mt-2 alert alert-info p-2" style={{width: '125px'}} role="alert">Earn <b>{formatPriceInEUR(totalPotentialIncome)}</b> additional income by renting vacant properties.</p>}
        </div>
      </div>
      <div className='me-4'>
        <PieChartWithText data={unitsByRentedStatus} centerLabel="Properties" />
      </div>
      <div className="mt-2 ms-5" style={{width: "100%"}}>
        <FinancialInsights data={incomeData} title="Net income" showLegend={false} />
      </div>
    </div>}
    <div className="mt-3">
      {data.dashboard.occupancy_rate < 100 &&
      <>
        <h3>Vacant properties</h3>
        <UnitsWithNoTenantsContainer setTotalPotentialIncomeFn={setTotalPotentialIncome}/>
      </>}
    </div>
    <div className="d-flex mt-3">
      <div className="w-50">
        <h3>Pay days</h3>
        <PayoutDays payoutDaysWithAmounts={rentDueDays} />
      </div>
      {tasks.length > 0 && <div className="ms-0 w-50">
        <h3>Tasks</h3>
        <TaskList data={tasks} />
      </div>}
    </div>
    
  </>
};

export default Dashboard;
