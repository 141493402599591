import React from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType.js'

const UnitIncomeMutatePage = ({id, action, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update income' : 'Add income'}/>
      <Form 
        formID="unit-income-mutate"
        successMessage={action === RouteActionType.edit ? 'Income data was updated' : 'Income was added'}
        objectID={parseInt(id) || 0}
        extraData={extraData}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitIncomeMutatePage
