import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const RangeSlider = ({label}) => {
  const [value, setValue] = useState(0); // Initial value

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Form>
      <Form.Group controlId="formRange">
        <Form.Label>{label}</Form.Label>
        <Form.Control type="range"
          value={value}
          onChange={handleChange}
          min={0}
          max={25}
          step={1}/>
        <Form.Text className="text-muted">
          {
          value * 100
        }m</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default RangeSlider;
