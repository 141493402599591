import React from 'react'

const NoContentAlert = ({
  messageStringStart,
  messageStringEnd,
  callToActionString,
  callToActionFn
}) => {
  return (
    <div className="alert alert-info" role="alert">
      {messageStringStart} <a href="" onClick={callToActionFn}>{callToActionString}</a> {messageStringEnd}
    </div>
  )
}

export default NoContentAlert