import React from 'react'

const TopNavigationItemWithDropdown = ({label, requiresLogin, isLoggedIn, items}) => {
  if (requiresLogin && !isLoggedIn || (!requiresLogin && isLoggedIn)) {
    return;
  }

  return (
    <li className="nav-item">
      <div className="dropdown mt-2">
        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {label} </button>
        <ul className="dropdown-menu">
          {
          items.map(item => {
            return (
              <li 
                className="dropdown-item"
                key={item.label}
              >{item.value}</li>
            );
          })
        } </ul>
      </div>
    </li>
  )
}

export default TopNavigationItemWithDropdown
