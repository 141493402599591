import {gql} from 'graphql-request';

export const DELETE_UNIT_UTILITY = gql `mutation unit_utility_delete(
  $id: ID!
) {
  unit_utility_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_UTILITY = gql `query unit_utility_list($unit_id: ID){
  unit_utility_list
    (unit_id: $unit_id){
    id, 
		utility {
			id, 
			name, 
			type,
			type_display,
			platform_url, 
			phone
		}
  }
}`;

export const VIEW_UNIT_UTILITY = gql `query view_unit_utility(
  $id: ID!
){
  unit_utility(id: $id) {
    id, 
		unit {
			id, 
			identifier, 
			address, 
			purchase_timestamp, 
			purchase_price, 
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants, 
			image_urls
		}, 
		utility {
			id, 
			name, 
			type, 
			platform_url, 
			phone
		}
  }
}`

// START MANUAL SECTION


// END MANUAL SECTION