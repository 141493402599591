import React, {useState} from 'react'
import {DELETE_UNIT_LISTING} from '../../../../graph-ql/unit_listing.js';
import {formatPriceInEUR, formatOrdinalNumber, formatDate} from '../../../../services/Format.js';
import GraphQLClientWithCredentials from '../../../../services/GraphQLClientWithCredentials.js';
import ConfirmationModal from '../../../common/ConfirmationModal.js';
import NoContentAlert from '../../../common/NoContentAlert.js';
import { threeDotMenuIcon } from '../../../../assets/svg/common.js';


const UnitListingListTable = ({data, addHandlerFn, editHandlerFn}) => {
  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(DELETE_UNIT_LISTING, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  const actions = [
    {
      key: 'record-visit',
      label: 'Record visits',
      handleClick: () => {
        console.log('renew t cont');
      }
    }, {
      key: 'record-offer',
      label: 'Record offer',
      handleClick: () => {
        console.log('rent paid');
      }
    }, {
      key: 'update-price',
      label: 'Update asking price',
      handleClick: () => {
        console.log('mark vacated');
      }
    }, {
      key: 'edit-details',
      label: 'Edit details',
      handleClick: (id) => {
        editHandlerFn(id);
      }
    }, {
      key: 'delete',
      label: 'Delete record',
      handleClick: (id) => {
        handleOpenModal(id);
      }
    },
  ];

  if (!data.length) {
    return <NoContentAlert 
      messageStringStart='There are no listings!'
      messageStringEnd='to this property'
      callToActionString='Add your first listing'
      callToActionFn={addHandlerFn}
    />
  }

  return <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th scope="col">Real Estate Agent</th>
        <th scope="col">Asking price</th>
        <th scope="col">Date</th>
        <th scope="col">Visits</th>
        <th scope="col">Offers</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>{
      data.map(item => {
        return !hiddenComponents.includes(item.id) && (<><tr key={
            item.id
          }>
            {visibleModal === item.id && 
              <ConfirmationModal 
                key={`confirmation-modal-${item.id}`}
                id={item.id}
                title="Delete?"
                body="This operation can't be undone." 
                cancelButtonLabel="Cancel" 
                confirmButtonLabel="Delete"
                cancelButtonFn={handleCloseModal}
                confirmButtonFn={
                  async () => await handleDeleteObject(item.id)
                }
                isLoading={isDeletionLoading}
              />
          }
            <td>
              <a className="btn btn-secondary" data-bs-toggle="collapse" href={`#income-details-${item.id}`} role="button" aria-expanded="false" aria-controls={`#income-details-${item.id}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-arrow-down" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5"/>
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                </svg>
              </a>
            </td>
            <td>{
              item.real_estate_agent.name
            }</td>
            <td>{
              formatPriceInEUR(item.asking_price)
            }</td>
            <td>{
              formatDate(item.date)
            }</td>
            <td>{item.visit_count}</td>
            <td>{item.offer_count}</td>
            <td>
              <div className="nav-item dropdown position-relative">
                <a
                  className="position-absolute top-100 start-50 translate-middle mt-1"
                  data-bs-toggle="dropdown"
                  role="button"
                  aria-expanded="false">
                  {threeDotMenuIcon}
                </a>
                <ul className="dropdown-menu">
                  {
                  actions.map(action => {
                    return <li key={
                      action.key
                    }>
                      <a className="dropdown-item"
                        onClick={
                          () => action.handleClick(item.id)
                      }>
                        {
                        action.label
                      }</a>
                    </li>
                })
                } </ul>
              </div>
            </td>
          </tr>
          <tr className="collapse" key={`income-details-${item.id}`} id={`income-details-${item.id}`}>
            <td colSpan="3">
              
              <p className="mt-3">Offer history: </p>
            </td>
            <td colSpan="3">
              Ad links:
            </td>
          </tr></>)
    })
    }</tbody>
  </table>
}

export default UnitListingListTable