import React, {useState} from 'react'

const FormPasswordElement = ({
  name, 
  id, 
  onChangeHandler,
  additionalClassName,
  isDisabled,
}) => {
  const [value, setValue] = useState('');

  return (
    <input type="password"
      value={value}
      onChange={
        (e) => {setValue(e.target.value); onChangeHandler(e);}
      }
      name={name}
      className={`form-control ${additionalClassName}`}
      id={id}
      disabled={isDisabled}
    />
  )
}

export default FormPasswordElement
