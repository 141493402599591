import React, { useState } from 'react';
import '../../styles/infobox.css';

const InfoBox = ({ text }) => {
  const [copied, setCopied] = useState(false);

  // Function to handle copying text to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch(() => {
        console.error("Failed to copy text.");
      });
  };

  return (
    <div className="info-box-container">
      <div className="info-box">
        {text}
      </div>
      <button className="copy-button" onClick={handleCopy}>
        {copied ? 'Copied!' : 'Copy'}
      </button>
    </div>
  );
};

export default InfoBox;
