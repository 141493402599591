import React from 'react';
import UtilityCard from './UtilityCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import { DELETE_UTILITY } from '../../graph-ql/utility.js';

const UtilityList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data.utility_list
    }
    error={error}
    loading={loading}
    componentToRender={UtilityCard}
    deleteGQL={DELETE_UTILITY}/>
};

export default UtilityList;
