import React from 'react';
import { useNavigate } from 'react-router-dom';
import { emailIcon, locationIcon, whatsAppIcon } from '../../assets/svg/icons.js';
import CardTitleWithThreeDotMenu from '../common/card/CardTitleWithThreeDotMenu.js';
import CardContentItem from '../common/card/CardContentItem.js';

const NotaryCard = (props) => {
  const navigate = useNavigate();
  const redirectToEdit = (id) => {
    navigate('/notary/edit/' + id);
  }

  const actions = [
    {
      key: 'edit',
      label: 'Edit details',
      handleClick: (id) => {
        redirectToEdit(id);
      }
    }, {
      key: 'delete',
      label: 'Delete record',
      handleClick: (id) => {
        props.handleOpenModal(id);
      }
    },
  ];

  return (
    <div className="card mb-3">
      <div className="card-body">
        <CardTitleWithThreeDotMenu id={props.id} title={props.name} actions={actions} />
        <CardContentItem label={locationIcon} text={props.city.name} />
        <CardContentItem label={emailIcon} text={<a target="_blank" href={`mailto:${props.email}`}>{props.email}</a>} />
        <CardContentItem label={whatsAppIcon} text={<a target="_blank" href={`http://wa.me/${props.phone}`}>{props.phone}</a>} />
      </div>
    </div>
  )
}

export default NotaryCard
