import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'

const RegisterPage = () => {
  return (
    <PageHeading heading="Register"/>
  )
}

export default RegisterPage
