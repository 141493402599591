import React, {useState, useEffect, useRef} from 'react'
import Loading from '../components/common/Loading.js';
import GraphQLClientWithCredentials from './GraphQLClientWithCredentials.js';

const GraphQLRequest = ({
  query,
  queryParams = {},
  componentToRender: Component,
  dataFunction,
  formDataCallback,
  formSubmitCallback,
  mutation,
  mutationName,
  objectID,
  formComponentToRender,
  formID,
  extraData,
  onChangeHandler,
  afterLoadingAction,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const prevQueryParamsString = useRef(null);

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const doRequest = async () => {
      setLoading(true);
      try {
        const response = await graphQLClient.request(query, queryParams);
        if (formDataCallback) {
          formDataCallback(response);
        }
        if (dataFunction) {
          setData(dataFunction(response));
        } else {
          setData(response);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    const queryParamsString = JSON.stringify(queryParams);
    if (queryParamsString !== prevQueryParamsString.current) {
      prevQueryParamsString.current = queryParamsString;
      doRequest();
    }
  }, [queryParams, query, dataFunction]);

  if (loading) {
    return <Loading/>
  }

  return <Component data={data}
    loading={loading}
    error={error}
    mutation={mutation}
    mutationName={mutationName}
    objectID={objectID}
    formComponentToRender={formComponentToRender}
    formID={formID}
    extraData={extraData}
    onChangeHandler={onChangeHandler}
    afterLoadingAction={afterLoadingAction}
    formSubmitCallback={formSubmitCallback}
    {...rest}/>
}
export default GraphQLRequest
