import React, {useState} from 'react'
import Form from '../../../services/Form.js';
import LinkWithModal from '../modal/LinkWithModal.js';

const RenewTenancyContractAction = ({label = 'Renew tenancy contract', objectID, type='link', postProcessorFn}) => {
  const content = () => {
    return <Form 
      formID="unit-tenant-renew-tenancy-contract"
      successMessage={'Tenancy contract was updated'}
      objectID={objectID}
      formSubmitCallback={postProcessorFn}
    />
  }

  switch (type) {
    case 'link':
      return (
        <a className="dropdown-item">
          <span>
            <LinkWithModal 
              label={label}
              content={content}
            />
          </span>
        </a>)
    case 'button':
      return (
        <button type="button" className="btn btn-outline-primary">
          <span>
            <LinkWithModal 
              label={label}
              content={content}
            />
        </span>
      </button>)
  };
};

export default RenewTenancyContractAction;
