import React from 'react'
import PieChartComponent from '../common/chart/PieChartComponent.js';

const TenantRemainingContractLengthDistribution = ({data}) => {
  if (!Object.keys(data).length) {
    return <div className="alert alert-info" role="alert">
      There is no data!
  </div>;
  }
  return <>
    <h4>Remaining contract length</h4>
    <PieChartComponent data={
      Object.keys(data).map(key => {
        return {name: key, value: data[key]};
      })
    }/>
  </>
}

export default TenantRemainingContractLengthDistribution