import {gql} from 'graphql-request';

export const DELETE_UNIT_INSURANCE = gql `mutation unit_insurance_delete(
  $id: ID!
) {
  unit_insurance_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_INSURANCE = gql `query unit_insurance_list(
  $unit_id: ID, 
  $is_active: Boolean,
){
  unit_insurance_list(
    unit_id: $unit_id,
    is_active: $is_active,
  ){
    id, 
		provider_name,
    description,
    fee,
    policy_number,
    start_date,
    end_date
  }
}`;

export const VIEW_UNIT_INSURANCE = gql `query view_unit_insurance(
  $id: ID!
){
  unit_insurance(id: $id) {
    id, 
		provider_name,
    description,
    fee,
    policy_number,
    start_date,
    end_date
  }
}`

// START MANUAL SECTION


// END MANUAL SECTION