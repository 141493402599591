import React, {useEffect, useState} from 'react';
import GraphQLClientWithCredentials from '../services/GraphQLClientWithCredentials.js';
import Loading from '../components/common/Loading.js';
import UnitsWithNoTenantsList from '../components/unit/UnitsWithNoTenantsList';
import {gql} from 'graphql-request';

export const LIST_UNIT = gql `query {
  unit_list{
    id,
    identifier, 
    identifier_image_url, 
    current_number_of_tenants,
    potential_income,
  }
}`;

export const LIST_LISTING = gql `query unit_listing_list($unit_ids: [ID]){
  unit_listing_list(
    unit_ids: $unit_ids,
  ){
    id, 
    unit {
      id,
    },
		real_estate_agent {
			id, 
      name,
		}, 
		time_in_market_days, 
		visit_count, 
		offer_count,
  }
}`;

const UnitsWithNoTenantsContainer = ({setTotalPotentialIncomeFn}) => {
  const [data, setData] = useState({});
  const [listings, setListings] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isListingsLoaded, setIsListingsLoaded] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const unitIDs = [];
  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT);
      setData(response.unit_list);

      let totalPotentialIncome = 0;
      response.unit_list.map(item => {
        if (item.current_number_of_tenants <= 0) {
          unitIDs.push(item.id);
          totalPotentialIncome += item.potential_income;
        }
      });
      setTotalPotentialIncomeFn(totalPotentialIncome);
      fetchListings(unitIDs);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  const fetchListings = async (unitIDs) => {
    const response = await graphQLClient.request(LIST_LISTING, {unit_ids: unitIDs});
    
    if (response.unit_listing_list === undefined || response.unit_listing_list === null) {
      return;
    }


    const listings = response.unit_listing_list.reduce((result, listing) => {
      const keyValue = listing.unit.id;
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      result[keyValue].push(listing);
  
      return result;
    }, {});
    setListings(listings);
    setIsListingsLoaded(true);
  };

  if (!isLoaded) {
    return <Loading/>;
  }
  
  return <UnitsWithNoTenantsList data={data} listings={listings} isListingsLoaded={isListingsLoaded} />
}

export default UnitsWithNoTenantsContainer
