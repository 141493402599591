import { isFloat } from './Number';

export const formatPriceInEUR = (input) => {
  if (input === undefined || input === null) {
    return 'N/A';
  }

  const fractionDigits = !isFloat(input) ? 0 : 2;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
  }).format(input);
}

export const formatPercentage = (input) => {
  const fractionDigits = !isFloat(input) ? 0 : 2;
  
  input = Math.floor(input * 100) / 100;
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(input / 100);
}

export const formatDate = (input) => {
  if (input === null) {
    return 'N/A';
  }
  const date = new Date(input);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export const formatOrdinalNumber = (input) => {
  if (input === null) {
    return 'N/A';
  }
  if (input % 100 >= 11 && input % 100 <= 13) {
    return input + "th";
  } else {
    switch (input % 10) {
      case 1:
        return input + "st";
      case 2:
        return input + "nd";
      case 3:
        return input + "rd";
      default:
        return input + "th";
    }
  }
}

export const formatMonths = (input, shorten = false) => {
  if (input === null) {
    return 'N/A';
  }

  const years = Math.floor(input / 12);
  const remainingMonths = Math.floor(input % 12);

  const monthsText = shorten ? 'm' : remainingMonths === 1 ? ' month' : ' months';
  const yearsText = shorten ? 'y' : years === 1 ? ' year' : ' years';

  if (years === 0) {
    return `${remainingMonths}${monthsText}`;
  } else if (remainingMonths === 0) {
    return `${years}${yearsText}`;
  } else {
    return `${years}${yearsText} ${remainingMonths}${monthsText}`;
  }
}

export const formatDays = (days) => {
  const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const weeks = Math.floor(((days % 365) % 30) / 7);
    const remainingDays = ((days % 365) % 30) % 7;

    if (years > 1) {
        return `${years} years`;
    } else if (years === 1) {
      return '1 year';
    } else if (months > 1) {
      return `${months} months`;
    } else if (months === 1) {
      return '1 month';
    } else if (weeks > 1) {
      return `${weeks} weeks`;
    } else if (weeks === 1) {
      return '1 week';
    } else if (remainingDays > 1) {
      return `${remainingDays} days`;
    } else if (remainingDays === 1) {
      return '1 day';
    } else {
      return 'Imminently';
    }
}

export const formatURL = (input) => {
  if (!input.startsWith('http://') && !input.startsWith('https://')) {
    return 'http://' + input;
  }
  return input;
}


export const formatDateToDDMMYYY = (input) => {
  const dateObj = new Date(input);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
}