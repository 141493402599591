import React, {useState, useEffect} from "react";
import FormComponent from "../components/common/form/FormComponent.js";
import GraphQLRequest from './GraphQLRequest';

const Form = ({
  formID,
  mutation,
  mutationName,
  objectID,
  onChangeHandler,
  formDataCallback,
  formSubmitCallback,
  options,
  componentToRender,
  successMessage,
  extraData,
  formInfoElements,
  updatedFormValues,
  disabledElements,
  hiddenElements
}) => {
  const initializeFormState = () => {
    let initialFormState = {};
    const formElements = document.querySelectorAll('input, select, textarea');
    formElements.forEach((element) => {
      initialFormState[element.name] = element.value;
    });
    setElementState(initialFormState);
  };

  const [elementState, setElementState] = useState({});
  const [lastChangedElement, setLastChangedElement] = useState(null);

  useEffect(() => {
    if (lastChangedElement && typeof onChangeHandler === 'function') {
      onChangeHandler(elementState, lastChangedElement);
    }
  }, [elementState, onChangeHandler, lastChangedElement]);

  const formChangeHandler = (e, name) => {
    let newValue;
    if (!e.hasOwnProperty('target')) {
      setLastChangedElement({target: {name: name}});
      newValue = { ...elementState, [name]: e };
    } else {
      setLastChangedElement(e);
      if (e.target.hasOwnProperty('checked')) {
        newValue = { ...elementState, [e.target.name]: e.target.checked ? 'on' : 'off' };
      } else {
        if (e.target.multiple && e.target.type === 'file') {
          newValue = { ...elementState, [e.target.name]: e.target.files };
        } else {
          newValue = { ...elementState, [e.target.name]: e.target.value };
        }
      }
    }
    
    setElementState(newValue);
  };

  let GET_FORM = `
{
  form(name: "${formID}"`;

  if (objectID !== null && objectID !== undefined) {
    GET_FORM += `,
    object_id: ${objectID}`;
  }

  if (extraData !== null && extraData !== undefined) {
    const ed = JSON.stringify(extraData).replace(/"/g, '\\"');
    GET_FORM += `,
    extra_data: "${ed}"`;
  }

  GET_FORM += `) {
        elements {
            id,
            name,
            type,
            label,
            value,
            validations,
            options {
              value,
              label,
            },
            cards {
              id,
              image,
              title,
              text,
              isSelected
            },
            accept,
            children {
              id,
              name,
              type,
              label,
              value,
              validations,
              options {
                value,
                label,
              },
            }
        },
        buttons {
            name,
            type,
            label
        }
    }
}
`;

  return <GraphQLRequest query={GET_FORM}
    formID={formID}
    componentToRender={FormComponent}
    mutation={mutation}
    mutationName={mutationName}
    objectID={objectID}
    onChangeHandler={(e, name) => formChangeHandler(e, name)}
    options={options}
    formDataCallback={formDataCallback}
    formSubmitCallback={formSubmitCallback}
    formComponentToRender={componentToRender}
    successMessage={successMessage}
    extraData={extraData}
    formInfoElements={formInfoElements}
    disabledElements={disabledElements}
    hiddenElements={hiddenElements}
    afterLoadingAction={initializeFormState}
    updatedFormValues={updatedFormValues}
  />
}

export default Form;
