import React from 'react'
import {formatPriceInEUR} from '../../../services/Format.js';

const UnitPurchasePrice = ({price}) => {
  return (
    <p className="card-text">
      {
      formatPriceInEUR(price)
    } </p>
  )
}

export default UnitPurchasePrice;
