import React from 'react';
import UnitCard from './UnitCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import {DELETE_UNIT} from '../../graph-ql/unit.js';

const UnitCardList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data
    }
    error={error}
    loading={loading}
    componentToRender={UnitCard}
    deleteGQL={DELETE_UNIT}/>
};

export default UnitCardList;
