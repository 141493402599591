export const findObjectByKey = (array, key, value) => {
  // Iterate over the array
  for (let i = 0; i < array.length; i++) {
    // Check if the current object has the specified key-value pair
    if (array[i][key] === value) {
      // If found, return the object
      return array[i];
    }
  }
  // If not found, return null
  return null;
}