import React from 'react'

const UnitViewContentButtons = ({
  subpage, 
  setSubpageFn, 
  addButtonLabel, 
  viewButtonLabel, 
  cancelButtonLabel = 'Cancel',
  secondaryButtons
}) => {
  return <div className="d-flex flex-row-reverse mt-2 mb-2 gap-1 content-buttons">
    {subpage === 'list' && <button 
      className="btn btn-secondary" 
      type="button"
      onClick={() => setSubpageFn('add')}
    >{addButtonLabel}</button>}
    {subpage === 'list' && secondaryButtons && secondaryButtons.map(button => <button 
      className="btn btn-light" 
      type="button"
      onClick={console.log('xxx')}
    >{button.label}</button>)}
    {subpage === 'add' && <button 
      className="btn btn-secondary" 
      type="button"
      onClick={() => setSubpageFn('list')}
    >{viewButtonLabel}</button>}
    {subpage === 'edit' && <button 
      className="btn btn-secondary" 
      type="button"
      onClick={() => setSubpageFn('list')}
    >{cancelButtonLabel}</button>}
  </div>
}

export default UnitViewContentButtons