import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';
import PrivateRoute from './components/common/router/PrivateRoute.js';
import RouteActionType from './enums/RouteActionType.js';
import store from './redux/store.js';

import TopNavigation from './components/common/navigation/TopNavigation.js';
import LeftNavigation from './components/common/navigation/LeftNavigation.js';

import DashboardPage from './pages/DashboardPage.js';
import LoginPage from './pages/user/LoginPage.js';
import LogoutPage from './pages/user/LogoutPage.js';
import RegisterPage from './pages/user/RegisterPage.js';
import ErrorPage from './pages/ErrorPage.js';
import FinancialsPage from './pages/FinancialsPage.js';
import PropertiesPage from './pages/property/PropertiesPage.js';
import TenantsPage from './pages/tenant/TenantsPage.js';
import TenantMutatePage from './pages/tenant/TenantMutatePage.js';
import RealEstateAgentsPage from './pages/real-estate-agent/RealEstateAgentsPage.js';
import RealEstateAgentAddPage from './pages/real-estate-agent/RealEstateAgentAddPage.js';
import RealEstateAgentEditPage from './pages/real-estate-agent/RealEstateAgentEditPage.js';
import ServicesPage from './pages/service-provider/ServicesPage.js';
import ServiceProviderAddPage from './pages/service-provider/ServiceProviderAddPage.js';
import ServiceProviderEditPage from './pages/service-provider/ServiceProviderEditPage.js';
import UtilitiesPage from './pages/utility/UtilitiesPage.js';
import UtilityMutatePage from './pages/utility/UtilityMutatePage.js';
import UnitMutatePage from './pages/property/UnitMutatePage';
import UnitViewPage from './pages/property/UnitViewPage';
import PropCheckerPage from './pages/propchecker/PropCheckerPage.js';
import PropFinderPage from './pages/propfinder/PropFinderPage.js';
import SettingsPage from './pages/settings/SettingsPage.js';
import UnitInventoryMutatePage from './pages/property/inventory/UnitInventoryMutatePage.js';
import ReportsPage from './pages/reports/ReportsPage.js';
import NotariesPage from './pages/notary/NotariesPage.js';
import NotaryAddPage from './pages/notary/NotaryAddPage.js';
import NotaryEditPage from './pages/notary/NotaryEditPage.js';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('loggedInUser'));
    if (user && user.hasOwnProperty('isLoggedIn') && user.isLoggedIn) {
      setIsLoggedIn(true);
      store.dispatch({
        type: 'actionLogin',
        payload: {
          userName: user.name,
          clearanceLevel: user.clearanceLevel,
          id: user.id
        }
      });
    }
    setIsLoading(false);
  }, [store]);

  if (isLoading) {
    return;
  }

  return (
    <>
      <TopNavigation/>
      <div className="container-fluid mt-3">
      <div className="row">
        <div className="col">
          {isLoggedIn && <LeftNavigation/>}
        </div>
        <div className="col-xxl-10">
          <Routes>
            <Route exact path="/"
              element={<PrivateRoute page={DashboardPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/register"
              element={<RegisterPage/>}
            />
            <Route path={'logout'}
              element={<LogoutPage/>}/>
            <Route path={'login'}
              element={<LoginPage/>}/>
            <Route path="/properties"
              element={<PrivateRoute page={PropertiesPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/property/add"
              element={<PrivateRoute page={UnitMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.add}/>}
            />
            <Route path="/property/edit/:id"
              element={<PrivateRoute page={UnitMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.edit}/>}
            />
            <Route path="/property/view/:id"
              element={<PrivateRoute page={UnitViewPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/tenants"
              element={<PrivateRoute page={TenantsPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/tenant/add"
              element={<PrivateRoute page={TenantMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.add} extraData={{'main-page': 'tenant'}}/>}
            />
            <Route path="/tenant/edit/:id"
              element={<PrivateRoute page={TenantMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.edit} extraData={{'main-page': 'tenant'}}/>}
            />
            <Route path="/real-estate-agents"
              element={<PrivateRoute page={RealEstateAgentsPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/real-estate-agent/add"
              element={<PrivateRoute page={RealEstateAgentAddPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/real-estate-agent/edit/:id"
              element={<PrivateRoute page={RealEstateAgentEditPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/notaries"
              element={<PrivateRoute page={NotariesPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/notary/add"
              element={<PrivateRoute page={NotaryAddPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/notary/edit/:id"
              element={<PrivateRoute page={NotaryEditPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/services"
              element={<PrivateRoute page={ServicesPage} isLoggedIn={isLoggedIn}/>}  
            />
            <Route path="/service-provider/add"
              element={<PrivateRoute page={ServiceProviderAddPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/service-provider/edit/:id"
              element={<PrivateRoute page={ServiceProviderEditPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/utilities"
              element={<PrivateRoute page={UtilitiesPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/utility/add"
              element={<PrivateRoute page={UtilityMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.add} />}
            />
            <Route path="/utility/edit/:id"
              element={<PrivateRoute page={UtilityMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.edit}/>}
            />
            <Route path="/property/inventory/add/:unit_id"
              element={<PrivateRoute page={UnitInventoryMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.add} />}
            />
            <Route path="/property/inventory/edit/:id"
              element={<PrivateRoute page={UnitInventoryMutatePage} isLoggedIn={isLoggedIn} action={RouteActionType.edit} />}
            />
            <Route path="/financials"
              element={<PrivateRoute page={FinancialsPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/propchecker"
              element={<PrivateRoute page={PropCheckerPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/propfinder"
              element={<PrivateRoute page={PropFinderPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/settings"
              element={<PrivateRoute page={SettingsPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route path="/reports"
              element={<PrivateRoute page={ReportsPage} isLoggedIn={isLoggedIn}/>}
            />
            <Route component={<ErrorPage/>}/>
          </Routes>
          <Outlet/>
        </div>
      </div>
      </div>
      <footer>
        <i>&copy; Copyright PropertyPal 2024. All rights reserved.</i>
      </footer>
    </>
  );
}

export default App;
