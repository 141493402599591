import React from 'react'

const FormTextareaElement = ({
  name, 
  initialValue, 
  placeholder, 
  onChangeHandler,
  id,
  additionalClassName,
  isDisabled
}) => {

  return (
    <textarea placeholder={placeholder}
      onChange={(e) => onChangeHandler(e)}
      name={name}
      defaultValue={initialValue}
      className={`form-control ${additionalClassName}`}
      id={id}
      disabled={isDisabled}>
    </textarea>
  )
}

export default FormTextareaElement
