import React from 'react'
import TenantProfileTypeDistribution from '../../components/tenant/TenantProfileTypeDistribution.js'
import TenantLengthOfStayDistribution from '../../components/tenant/TenantLengthOfStayDistribution.js'
import DistributionDetails from '../../components/common/table/DistributionDetails.js'
import TenantRemainingContractLengthDistribution from '../../components/tenant/TenantRemainingContractLengthDistribution.js'
import Timeline from '../../components/common/timeline/Timeline.js'

const TenantsInsightsPage = ({
  profileTypeDistribution, 
  lengthOfStayDistribution, 
  profileTypeDistributionDetails,
  lengthOfStayDistributionDetails,
  remainingContractLengthDistribution,
  remainingContractLengthDistributionDetails
}) => {

  const events = [
    { title: 'Snagov 1', date: 0.05, description: 'Contract will expire 14.01.2024' },
    { title: 'Fray Pedro Vives', date: 0.12, description: 'Contract will expire 28.03.2024' },
    { title: 'Nou Moles', date: 0.13, description: 'Contract will expire 14.01.2024.' },
    { title: 'Calle Lerida', date: 0.55, description: 'Contract will expire 28.03.2024.' },
    { title: 'Titulescu', date: 0.79, description: 'Contract will expire 28.03.2024.' },
    { title: 'Iugoslaviei', date: 0.89, description: 'Contract will expire 28.03.2024.' }
  ];

  return (
    <>
      <div className="mb-5">
        <h4>Expiring lease contracts</h4>
        <Timeline events={events} />
      </div>
      <div className="d-flex flex-row">
        <div className="p-2">
          <TenantProfileTypeDistribution data={profileTypeDistribution} />
        </div>
        <div className="p-2">
          <TenantLengthOfStayDistribution data={lengthOfStayDistribution} />
        </div>
        <div className="p-2">
          <TenantRemainingContractLengthDistribution data={remainingContractLengthDistribution} />
        </div>
      </div>
      <DistributionDetails 
        data={profileTypeDistributionDetails}
        data2={remainingContractLengthDistributionDetails} 
        data3={lengthOfStayDistributionDetails}
        title="Distribution details"
      />
    </>
  )
}

export default TenantsInsightsPage