import {gql} from 'graphql-request';

export const ADD_SETTING = gql `mutation setting_create(
  $account_id: ID!, 
	$monthly_income_goal: Int, 
	$unit_count_goal: Int
) {
  setting_create(
    account_id: $account_id, 
		monthly_income_goal: $monthly_income_goal, 
		unit_count_goal: $unit_count_goal
  ) {
    data {
      id,
    }
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

export const UPDATE_SETTING = gql `mutation setting_update(
  $id: ID!,
  $account_id: ID!, 
	$monthly_income_goal: Int, 
	$unit_count_goal: Int
) {
  setting_update(
    id: $id,
    account_id: $account_id, 
		monthly_income_goal: $monthly_income_goal, 
		unit_count_goal: $unit_count_goal
  ) {
    data {
      id
    }
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

export const DELETE_SETTING = gql `mutation setting_delete(
  $id: ID!
) {
  setting_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_SETTING = gql `query setting_list{
  setting_list
    {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		monthly_income_goal, 
		unit_count_goal
  }
}`;

export const VIEW_SETTING = gql `query view_setting(
  $id: ID!
){
  setting(id: $id) {
    id, 
		account {
			id, 
			name, 
			creation_timestamp
		}, 
		monthly_income_goal, 
		unit_count_goal
  }
}`

// START MANUAL SECTION

export const SAVE_SETTINGS = gql `mutation replace_setting(
  $monthly_income_goal: Int, 
  $unit_count_goal: Int
) {
  replace_setting(
    monthly_income_goal: $monthly_income_goal, 
    unit_count_goal: $unit_count_goal
  ) {
    data {
      id,
      monthly_income_goal,
      unit_count_goal
    }
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;

// END MANUAL SECTION