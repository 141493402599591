import React from 'react'
import TooltipComponent from '../common/TooltipComponent.js'

const DashboardCard = ({title, value, tooltipText}) => {
  return (
    <div className="card">
      <div className="card-header">
        <TooltipComponent tooltipTriggerText={title}
          tooltipText={tooltipText}/></div>
      <div className="card-body">
        <p>{value}</p>
      </div>
    </div>
  )
}

export default DashboardCard
