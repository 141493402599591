import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'

const NotaryAddPage = () => {
  return (
    <>
      <PageHeading heading="Add notary"/>
      <Form formID="notary-add"
        successMessage={'Notary was added'}/>
    </>
  )
}

export default NotaryAddPage
