import React from 'react';
import RealEstateAgentCard from './RealEstateAgentCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';
import { DELETE_REAL_ESTATE_AGENT } from '../../graph-ql/real_estate_agent.js';

const RealEstateAgentList = ({data, error, loading}) => {
  return <ComponentWithLoading data={
      data
    }
    error={error}
    loading={loading}
    componentToRender={RealEstateAgentCard}
    deleteGQL={DELETE_REAL_ESTATE_AGENT}/>
};

export default RealEstateAgentList;
