import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import {LIST_UNIT_UTILITY} from '../../../graph-ql/unit_utility.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import UnitUtilityViewTable from './utility/UnitUtilityViewTable.js';
import UnitUtilityMutatePage from '../../../pages/property/utility/UnitUtilityMutatePage.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';
import RouteActionType from '../../../enums/RouteActionType.js';

const UnitViewUtilities = ({unit_id}) => {
  const [utilityID, setUtilityID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_UTILITY, {unit_id: unit_id});
      setData(response.unit_utility_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData]);

  const editHandler = (id) => {
    setUtilityID(id);
    setSubpage('edit');
  }

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  return (
    <>
      <UnitViewContentButtons 
        addButtonLabel={'Update utilities'} 
        viewButtonLabel={'View utilities'}
        cancelButtonLabel={cancelButtonLabel} 
        subpage={subpage} 
        setSubpageFn={setSubpage}
      />
      {subpage === 'list' && isLoaded && <UnitUtilityViewTable 
        data={data} 
        editHandlerFn={editHandler}
        addHandlerFn={() => setSubpage('add')}
      />}
      {subpage === 'list' && !isLoaded && <Loading />}
      {subpage === 'add' && <UnitUtilityMutatePage 
        formSubmitCallbackFn={refreshData} 
        action={RouteActionType.add} 
        extraData={{'unit-id': unit_id}} 
      />}
      {subpage === 'edit' && <UnitUtilityMutatePage 
        formSubmitCallbackFn={() => {
          refreshData(); 
          setCancelButtonLabel('View utilities');
        }} 
        action={RouteActionType.edit} 
        extraData={{'unit-id': unit_id}} 
        id={utilityID}
      />}
    </>
  )
}

export default UnitViewUtilities
