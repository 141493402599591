import React, {useState} from 'react'
import Loading from './Loading.js';
import Error from './Error.js';
import ConfirmationModal from '../common/ConfirmationModal.js';
import GraphQLClientWithCredentials from '../../services/GraphQLClientWithCredentials.js';

const ComponentWithLoading = ({
  data,
  error,
  loading,
  componentToRender: Component,
  deleteGQL
}) => {

  const [hiddenComponents, setHiddenComponents] = useState([]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const graphQLClient = GraphQLClientWithCredentials();

  const handleOpenModal = (id) => {
    setVisibleModal(id);
  }

  const handleCloseModal = () => {
    setVisibleModal(null);
  }

  const handleDeleteObject = async (id) => {
    setIsDeletionLoading(true);
    try {
      const data = await graphQLClient.request(deleteGQL, {id: id});
      setHiddenComponents(prevHiddenComponents => [
        ...prevHiddenComponents,
        id
      ]);
      setVisibleModal(null);
    } catch (error) {
      console.error('Error performing mutation:', error);
      throw error;
    }
    setIsDeletionLoading(false);
  }

  return (
    <> 
      {loading && <Loading/>}
      {error && <Error error={error}/>}
      {data.length === 0 && <div className="alert alert-info" role="alert">
        There is no data!
      </div>}
      {!loading && !error && data.map(data_item => {
        return !hiddenComponents.includes(data_item.id) && <div key={
          data_item.id
        }>
          {visibleModal === data_item.id && <ConfirmationModal
            key={`confirmation-modal-${data_item.id}`}
            id={data_item.id}
            title="Delete?" 
            body="This operation can't be undone." 
            cancelButtonLabel="Cancel" 
            confirmButtonLabel="Delete"
            cancelButtonFn={handleCloseModal}
            confirmButtonFn={
              async () => await handleDeleteObject(data_item.id)
            }
            isLoading={isDeletionLoading}/>
        }
          <Component {...data_item}
            handleOpenModal={() => handleOpenModal(data_item.id)}/>
        </div>;
      })
    } </>
  );
}

export default ComponentWithLoading
