import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectableCard from './SelectableCard.js';

const FormSelectableCardListElement = ({id, name, isHidden, cards}) => {
  let initialValue = [];
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].isSelected) {
      initialValue.push(cards[i].id);
    }
  }

  const [selectedCardIds, setSelectedCardIds] = useState(initialValue);

  const handleSelect = (id) => {
    setSelectedCardIds((prevSelectedCardIds) =>
      prevSelectedCardIds.includes(id)
        ? prevSelectedCardIds.filter((cardId) => cardId !== id)
        : [...prevSelectedCardIds, id]
    );
  };

  if (isHidden) {
    return;
  }

  return (
    <>
      <input 
        type="hidden" 
        name={name}
        id={id} 
        value={JSON.stringify(selectedCardIds)} 
      />
      <Container>
        <Row>
          {cards.map((card) => (
            <Col key={card.id} sm={12} md={6} lg={4} className="d-flex mb-3">
              <SelectableCard
                card={card}
                onSelect={handleSelect}
                isSelected={selectedCardIds.includes(card.id)}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default FormSelectableCardListElement