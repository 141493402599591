import React, {useState, useEffect} from 'react'
import Loading from '../../../components/common/Loading.js';
import {LIST_UNIT_INCOME} from '../../../graph-ql/unit_income.js';
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import UnitIncomeListTable from './income/UnitIncomeListTable.js';
import UnitIncomeMutatePage from '../../../pages/property/income/UnitIncomeMutatePage.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';
import RouteActionType from '../../../enums/RouteActionType.js';

const UnitViewIncome = ({unit_id, unit_data}) => {
  const [incomeID, setIncomeID] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [subpage, setSubpage] = useState('list');
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');

  const graphQLClient = GraphQLClientWithCredentials();

  const editIncomeHandler = (income_id) => {
    setIncomeID(income_id);
    setSubpage('edit');
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(LIST_UNIT_INCOME, {unit_id: unit_id});
      setData(response.unit_income_list);
      setIsLoaded(true);
      setShouldRefreshData(false);
    };
    fetchData();
  }, [shouldRefreshData]);

  const refreshData = () => {
    setShouldRefreshData(true);
    setIsLoaded(false);
  }

  return (
    <>
      {/* <p>graph with income per year</p> */}
      <UnitViewContentButtons 
        addButtonLabel={'Add income'} 
        viewButtonLabel={'View income'}
        cancelButtonLabel={cancelButtonLabel} 
        subpage={subpage} 
        setSubpageFn={setSubpage}
      />
      {subpage === 'list' && isLoaded && <UnitIncomeListTable 
        unit_data={unit_data}
        data={data} 
        editIncomeToUnitHandlerFn={editIncomeHandler}
        addIncomeToUnitHandlerFn={() => setSubpage('add')}
      />}
      {subpage === 'list' && !isLoaded && <Loading />}
      {subpage === 'add' && <UnitIncomeMutatePage 
        formSubmitCallbackFn={refreshData} 
        action={RouteActionType.add} 
        extraData={{'unit-id': unit_id}} 
      />}
      {subpage === 'edit' && <UnitIncomeMutatePage 
        formSubmitCallbackFn={() => {
          refreshData(); 
          setCancelButtonLabel('View income');
        }} 
        action={RouteActionType.edit} 
        extraData={{'unit-id': unit_id}} 
        id={incomeID}
      />}
    </>
  )
}

export default UnitViewIncome
