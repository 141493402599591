import {gql} from 'graphql-request';

export const SUBMIT_FORM = gql `mutation form_submit(
  $form_identifier: String!, 
	$post_data: String!, 
  $object_id: ID,
  $extra_data: String,
) {
  form_submit(
    form_identifier: $form_identifier, 
		post_data: $post_data, 
		object_id: $object_id,
    extra_data: $extra_data,
  ) {
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;