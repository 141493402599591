import React, {useEffect, useState} from 'react'
import GraphQLClientWithCredentials from '../../../services/GraphQLClientWithCredentials.js';
import Loading from '../Loading.js';

const UnitFilter = ({query, handleChange, dataItemKey}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await graphQLClient.request(query);
        let data_ = [];
        let visited = [];
        data_ = response[dataItemKey].map(item => {
          if (visited.includes(item.id)) {
            return null;
          }
          visited.push(item.id);
          return {
            id: item.id, 
            name: item.identifier
          };
        });
        setData(data_.filter((element) => element !== null));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error performing mutation:', error);
        throw error;
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <Loading/>
  }

  return (
    <select className="form-select" name="unit_id" onChange={handleChange}>
      <option value="0">All Properties</option>
      {Object.entries(data).map(([key]) => {
        return <option key={data[key].id} value={data[key].id}>{data[key].name}</option>
      })}
    </select>
  )
}

export default UnitFilter