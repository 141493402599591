import React from 'react'
import {prettyStringEnumValue} from '../../../utils/String';

const EnumFilter = ({label, enumData, handleChange}) => {
  return (
    <select className="form-select" name="type" onChange={handleChange}>
      <option value="0">{label}</option>
      {Object.entries(enumData).map(([key]) => {
        return <option key={key} value={key}>{prettyStringEnumValue(key)}</option>
      })}
    </select>
  )
}

export default EnumFilter