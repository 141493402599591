import React from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType';

const UnitTaskMutatePage = ({id, action, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update task' : 'Add task'}/>
      <Form 
        formID="unit-task-mutate"
        successMessage={action === RouteActionType.edit ? 'Task data was updated' : 'Task was added'}
        objectID={parseInt(id) || 0}
        extraData={extraData}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitTaskMutatePage
