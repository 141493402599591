import React from 'react'
import { formatDate } from '../../../services/Format.js'

const UnitPurchaseDate = ({purchaseDate}) => {
  return (
    <p className="card-text">
      Purchased on {formatDate(purchaseDate)} </p>
  )
}

export default UnitPurchaseDate
