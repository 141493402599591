import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import { useParams } from 'react-router-dom'
import RouteActionType from '../../enums/RouteActionType.js'

const UtilityAddPage = ({action, extraData}) => {
  const {id} = useParams();
  return (
    <>
      <PageHeading heading="Add utility"/>
      <Form 
        objectID={parseInt(id) || 0}
        formID={'utility-mutate'}
        successMessage={action === RouteActionType.edit ? 'Utility data was updated' : 'Utility was added'}
        extraData={extraData}
      />
    </>
  )
}

export default UtilityAddPage
