import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TooltipComponent = ({
  tooltipText, 
  tooltipTriggerText, 
  icon, 
  iconPosition='right',
}) => {

  if (icon === null || icon === undefined) {
    icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0B58CA" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </svg>;
  } else if (icon === 'none') {
    icon = null;
  }

  const tooltip = <Tooltip>{tooltipText}</Tooltip>;

  return (
    <OverlayTrigger key="top" placement="top"
      overlay={tooltip}>
      <span style={{cursor: 'pointer'}}>
        {iconPosition === 'right' && <span className="me-1">{tooltipTriggerText}</span>}
        {icon}
        {iconPosition === 'left' && <span className="ms-1">{tooltipTriggerText}</span>}
      </span>
    </OverlayTrigger>
  );

};

export default TooltipComponent;
