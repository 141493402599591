import React, {useState, useEffect} from "react";
import Loading from "../Loading.js";
import FormElement from "./FormElement.js";
import FormButton from "./FormButton.js";
import GraphQLClientWithCredentials from "../../../services/GraphQLClientWithCredentials.js";
import { SUBMIT_FORM } from "../../../graph-ql/form.js";

export default function FormComponent({
  loading,
  error,
  data,
  mutation,
  mutationName,
  objectID,
  formSubmitCallback,
  formComponentToRender: Component,
  successMessage,
  formID,
  extraData,
  onChangeHandler,
  afterLoadingAction,
  formInfoElements,
  updatedFormValues,
  disabledElements = [],
  hiddenElements = [],
  ...rest
}) {
  const [isErrorAlertViewable, setIsErrorAlertViewable] = useState(false);
  const [isRenderComponentVisible, setIsRenderComponentVisible] = useState(false);
  const [isSuccessAlertViewable, setIsSuccessAlertViewable] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mutationResultData, setMutationResultData] = useState(null);
  const [mutationExceptions, setMutationExceptions] = useState({});
  const [showLegend, setShowLegend] = useState(false);

  const onFormElementChange = (e, name) => {
    const elementName = name !== undefined && name !== null ? name : e.target.name;
    const exceptions = mutationExceptions;
    if (exceptions.hasOwnProperty(elementName)) {
      delete exceptions[elementName];
    }
    setMutationExceptions(exceptions);
    onChangeHandler(e);
  }

  useEffect(() => {
    
    if (typeof afterLoadingAction === 'function') {
      afterLoadingAction();
    }
  }, []);

  if (loading) {
    return;
  }
  

  const graphQLClient = GraphQLClientWithCredentials();

  const handlePreSubmitImpl = async (e) => {
    // todo do more stuff here if needed
    await handleSubmit(e);
  }

  const handleSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();

    const formSubmitData = Array.from(e.target.elements)
      .filter(element => element.type !== 'file') // filter out file inputs as GraphQL doesn't support them and we handle it separately using /upload endpoing
      .filter((input) => input.name)
      .reduce((obj, input) => Object.assign(obj, {
      [input.name]: isNaN(input.value) ? input.value : (input.dataset.type === 'int' ? parseInt(input.value) : (input.dataset.type === 'float' || input.dataset.type === 'number' ? parseFloat(input.value) : input.value))
    }), {});
    
    let x = {};
    x['form_identifier'] = formID;
    
    if (objectID != undefined && objectID != null) {
      formSubmitData['id'] = parseInt(objectID);
      x['object_id'] = parseInt(objectID);
    }

    if (extraData != undefined && extraData != null) {
      formSubmitData['extra_data'] = JSON.stringify(extraData);
      x['extra_data'] = JSON.stringify(extraData);
    }

    x['post_data'] = JSON.stringify(formSubmitData);

    let result = null;
    try {
      if (mutation !== null && mutation !== undefined) {
        result = await graphQLClient.request(mutation, formSubmitData);
      } else {
        result = await graphQLClient.request(SUBMIT_FORM, x);
        mutationName = 'form_submit';
      }
      
      setSubmitLoading(false);
      if (result !== null) {
        if (result[mutationName].result || result[mutationName].length) {
          setIsSuccessAlertViewable(true);
          setIsErrorAlertViewable(false);
          setMutationResultData(result[mutationName]);
          setIsRenderComponentVisible(true);
        } else {
          setIsSuccessAlertViewable(false);
          setIsErrorAlertViewable(true);
          
          let exceptions = {};
          for (let i = 0; i < result[mutationName].exceptions.length; i++) {
            const ex = result[mutationName].exceptions[i];
            if (!exceptions.hasOwnProperty(ex.property)) {
              exceptions[ex.property] = [];
            }
            for (let j = 0; j < ex.failed_rules.length; j++) {
              exceptions[ex.property].push(ex.failed_rules[j].message);
            }
          }
          setMutationExceptions(exceptions);
        }

        if (formSubmitCallback) {
          formSubmitCallback(result[mutationName]);
        }
      }
    } catch (error) {
      console.error('Error performing mutation:', error);
      setSubmitLoading(false);
      setIsSuccessAlertViewable(false);
      setIsErrorAlertViewable(true);
      throw error;
    }
  }

  const getChildren = ({
    children: children, 
    updatedFormValues: updatedFormValues, 
    mutationExceptions: mutationExceptions, 
    disabledElements: disabledElements, 
    hiddenElements: hiddenElements, 
    onFormElementChange: onFormElementChange
  }) => {

    if (children.length <= 0) {
      return [];
    }

    const result = children.map((child) => {
      const childExceptions = mutationExceptions.hasOwnProperty(child.name) ? mutationExceptions[child.name] : [];
      const childAdditionalClassName = childExceptions.length ? 'is-invalid' : '';
      const isChildDisabled = disabledElements.indexOf(child.name) !== -1;
      const isChildHidden = hiddenElements.indexOf(child.name) !== -1;
  
      const childName = child.name;
      const childOptions_ = {
        [childName]: child.options
      };
  
      return (
        <FormElement
          key={child.id}
          initialValue={updatedFormValues && updatedFormValues.hasOwnProperty(child.id) && updatedFormValues[child.id] !== null ? updatedFormValues[child.id] : child.value}
          onChangeHandler={onFormElementChange} 
          exceptions={childExceptions}
          additionalClassName={childAdditionalClassName}
          isDisabled={isChildDisabled}
          isHidden={isChildHidden}
          isChildElement={true}
          {...child} 
          options={childOptions_}
        />
      );
    });

    return result;
  }

  return (
    <> 
      {isSuccessAlertViewable && <div className="alert alert-success alert-dismissible fade show" role="alert">
        <strong>{successMessage}</strong>
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"
          onClick={
            () => setIsSuccessAlertViewable(false)
        }></button>
      </div>}
      {isErrorAlertViewable && <div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>You have errors!</strong>
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"
          onClick={
            () => setIsErrorAlertViewable(false)
        }></button>
      </div>}
      <form onSubmit={handlePreSubmitImpl}
        method="POST"
        className="form w-75"
      >
        <input type="hidden" name="form_identifier"
          value={formID}/>
        <table className="table">
          <tbody>
          {data.form.elements.map((element) => {
            const exceptions = mutationExceptions.hasOwnProperty(element.name) ? mutationExceptions[element.name] : [];
            let additionalClassName = [];
            if (exceptions.length) {
              additionalClassName.push('is-invalid');
            }
            const isDisabled = disabledElements.indexOf(element.name) !== -1;
            const isHidden = hiddenElements.indexOf(element.name) !== -1;
            const hasChildren = element.children.length > 0;
            if (!showLegend && element.validations.includes('required')) {
              setShowLegend(true);
            }
            if (hasChildren) {
              additionalClassName.push('w-50');
            }
            
            const elementName = element.name;
            const options_ = rest.options !== null && rest.options !== undefined && rest.options.hasOwnProperty(elementName) ? rest.options : {
              [elementName]: element.options
            };

            if (element.type === 'hidden') {
              return <tr key={element.name} style={{display: 'none', visibility: 'hidden'}}><td>
                <FormElement 
                  initialValue={updatedFormValues && updatedFormValues.hasOwnProperty(element.id) && updatedFormValues[element.id] !== null ? updatedFormValues[element.id] : element.value}
                  key={element.name}
                  additionalClassName={''}
                  exceptions={[]} // no exceptions for hidden fields
                  {...element} 
                  {...rest}
                />
              </td></tr>
            }

            return (
              <tr 
                className={`${isHidden ? 'hidden' : 'no-border'}`}
                key={
                element.name
              }>
                {element.label && <td className="w-25">
                  <label className="input-group-text input-label h-100" htmlFor={element.id}>
                      {element.label}
                      {element.validations.includes('required') && <span className="ms-1 validation-indicator">*</span>}
                  </label>
                </td>}
                <td className="w-75" {...(!element.label && { colSpan: 2 })}>
                  <FormElement
                    initialValue={updatedFormValues && updatedFormValues.hasOwnProperty(element.id) && updatedFormValues[element.id] !== null ? updatedFormValues[element.id] : element.value}
                    onChangeHandler={onFormElementChange} 
                    exceptions={exceptions}
                    additionalClassName={additionalClassName.join(' ')}
                    isDisabled={isDisabled}
                    isHidden={isHidden}
                    {...element} 
                    {...rest}
                    options={options_}
                    childElements={getChildren({
                      updatedFormValues: updatedFormValues, 
                      onFormElementChange: onFormElementChange, 
                      children: element.children, 
                      mutationExceptions: mutationExceptions, 
                      disabledElements: disabledElements,
                      hiddenElements: hiddenElements,
                    })}
                  />
                  {formInfoElements && formInfoElements.hasOwnProperty(element.id) && <span className="form-info-element">{formInfoElements[element.id]}</span>}
                </td>
              </tr>
            );
          })
        }
        {showLegend && <tr className="no-border">
          <td colSpan="2"><span className="form-legend">* mandatory fields</span></td>
        </tr>}
        {data.form.buttons.map((button) => {
            return (
              <tr className="no-border" key={
                button.name
              }>
                <td colSpan={2}>
                  <FormButton
                    name={button.name}
                    type={button.type}
                    label={button.label}
                    isDisabled={submitLoading}
                    showAdditionalInfo={submitLoading}
                    additionalInfo={<Loading />}
                  />
                </td>
              </tr>
            );
          })
        }</tbody></table>
      </form>
      {isRenderComponentVisible && Component !== undefined && <Component data={mutationResultData}/>} 
    </>
  );
}
