import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const FormDateElement = ({
  name, 
  initialValue, 
  id, 
  onChangeHandler, 
  additionalClassName,
  isDisabled
}) => {
  const [value, setValue] = useState(initialValue !== null ? moment(initialValue, 'DD/MM/YYYY', true).toDate() : null);

  useEffect(() => {
    if (initialValue !== null) {
      setValue(moment(initialValue, 'DD/MM/YYYY', true).toDate())
    }
  }, [initialValue]);
  
  return (
    <DatePicker
      name={name}
      showIcon 
      disabled={isDisabled}
      selected={value}
      onChange={(date) => {setValue(date); onChangeHandler({target: {name: name, value: date}});}}
      toggleCalendarOnIconClick 
      dateFormat="dd/MM/yyyy"
      autoComplete="off"
      className={`form-control ${additionalClassName}`}
      id={id}
    />  )
}

export default FormDateElement
