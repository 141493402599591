import React from 'react'

const FormSubmitButton = ({
  name, 
  label,
  isDisabled,
  showAdditionalInfo,
  additionalInfo
}) => {
  return (
    <div className="d-inline-flex">
      <div className="input-group p-0">
        <input type="submit" name={name} value={label} className="btn btn-primary" disabled={isDisabled} />
      </div>
      <div className="ms-3">{showAdditionalInfo && additionalInfo}</div>
    </div>
  )
}

export default FormSubmitButton
