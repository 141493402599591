import React, {useState, useEffect} from 'react'
import TooltipComponent from '../../../common/TooltipComponent.js';

const UnitViewListingsSimple = ({listings}) => {
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalOffers, setTotalOffers] = useState(0);
  const [agentCount, setAgentCount] = useState(0);
  const [timeInMarketDays, setTimeInMarketDays] = useState(0);
  const [agentNames, setAgentNames] = useState([]);

  useEffect(() => {
    let totalVisitsCnt = 0;
    let totalOffersCnt = 0;
    let agentIDs = [];
    let agentNames_ = [];
    let timeInMarketDays_ = 0;

    for (let i = 0; i < listings.length; i++) {
      let listing = listings[i];
      totalVisitsCnt += listing.visit_count;
      totalOffersCnt += listing.offer_count;
      agentIDs.push(listing.real_estate_agent.id);
      agentNames_.push(listing.real_estate_agent.name);
      timeInMarketDays_ = timeInMarketDays_ > listing.time_in_market_days ? timeInMarketDays_ : listing.time_in_market_days;
    }

    agentIDs = [...new Set(agentIDs)];
    agentNames_ = [...new Set(agentNames_)];

    setTotalVisits(totalVisitsCnt);
    setTotalOffers(totalOffersCnt);
    setAgentCount(agentIDs.length);
    setAgentNames(agentNames_);
    setTimeInMarketDays(timeInMarketDays_);
  }, [listings]);

  return (
    <p>Listed <b>{timeInMarketDays}</b> days ago, with <TooltipComponent tooltipTriggerText={agentCount}
    tooltipText={agentNames.join(', ')}/> real estate agent{agentCount > 1 && 's'}, with a total of <b>{totalVisits}</b> visits and received <b>{totalOffers}</b> offers.</p>
  )
}

export default UnitViewListingsSimple