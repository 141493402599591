import React from 'react'
import Loading from './Loading.js';
import Error from './Error.js';

const SingleComponentWithLoading = ({data, error, loading, componentToRender: Component, ...rest}) => {
  return (
    <> {
      loading && <Loading/>
    }

      {
      error && <Error error={error}/>
    }
      {
      !loading && !error && <Component data={data} {...rest} />
    } </>
  );
}

export default SingleComponentWithLoading
