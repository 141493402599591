import {gql} from 'graphql-request';

export const DELETE_SERVICE_PROVIDER = gql `mutation service_provider_delete(
  $id: ID!
) {
  service_provider_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_SERVICE_PROVIDER = gql `query service_provider_list($city_id: ID, $type: String){
  service_provider_list(city_id: $city_id, type: $type)
    {
    id, 
		city {
			id, 
			name
		}, 
		type, 
    type_display,
		name, 
		email, 
		phone
  }
}`;

export const VIEW_SERVICE_PROVIDER = gql `query view_service_provider(
  $id: ID!
){
  service_provider(id: $id) {
    id, 
		city {
			id, 
			name
		}, 
		type, 
		name, 
		email, 
		phone
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION