import {gql} from 'graphql-request';

export const DO_ACTION = gql `mutation do_action(
  $action_type: String!, 
  $object_id: ID,
  $extra_data: String,
) {
  do_action(
    action_type: $action_type, 
		object_id: $object_id,
    extra_data: $extra_data,
  ) {
    result,
    exceptions {
      property,
      failed_rules {
        message
      }
    }
  }
}`;