import {gql} from 'graphql-request';

export const LIST_REGION = gql `query region_list($country_id: ID){
  region_list
    (country_id: $country_id){
    id, 
		country {
			id, 
			name
		}, 
		name
  }
}`;

export const VIEW_REGION = gql `query view_region(
  $id: ID!
){
  region(id: $id) {
    id, 
		country {
			id, 
			name
		}, 
		name
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION