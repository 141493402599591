import React from 'react'
import PieChartComponent from '../common/chart/PieChartComponent.js';

const TenantProfileTypeDistribution = ({data}) => {
  if (!Object.keys(data).length) {
    return <div className="alert alert-info" role="alert">
      There is no data!
    </div>;
  }

  return <>
    <h4>Profile type distribution</h4>
    <PieChartComponent data={
      Object.keys(data).map(profile_type_display => {
        return {name: profile_type_display, value: data[profile_type_display]};
      })
    }/>
  </>
}

export default TenantProfileTypeDistribution