import React from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import Form from '../../services/Form.js'
import {useParams} from "react-router-dom";

const RealEstateAgentEditPage = () => {
  const {id} = useParams();

  return (
    <>
      <PageHeading heading="Update real estate agent"/>
      <Form formID="real-estate-agent-edit"
        objectID={id}
        successMessage={'Real estate agent data was updated'}/>
    </>
  )
}

export default RealEstateAgentEditPage
