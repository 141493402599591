import React, {useEffect, useState} from 'react'
import PageHeading from '../../components/common/PageHeading.js'
import store from '../../redux/store.js'
import {useNavigate} from 'react-router-dom'
import {sleep} from '../../services/Utils.js'

const LogoutPage = () => {
  const [sleepVal, setSleep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch({type: 'actionLogout', payload: {}});
    const sleepFn = async () => {
      await sleep(1500);
      setSleep(1);
    };
    sleepFn();
  }, []);

  if (sleepVal === 1) {
    navigate('/');
    window.location.reload();
  }

  return (
    <>
      <PageHeading heading="You are now logged out"/>
      <p>See you soon!</p>
    </>
  )
}

export default LogoutPage
