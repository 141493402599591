import React, {useState} from 'react'

const DistributionDetails = ({data, data2, data3, title}) => {
  const [finalData, setFinalData] = useState({});
  const [selectedItem1, setSelectedItem1] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [selectedItem3, setSelectedItem3] = useState(null);
  
  const handleChange1 = (event) => {
    setSelectedItem1(event.target.value);
    setFinalData(data[event.target.value]);
  };

  const handleChange2 = (event) => {
    setSelectedItem2(event.target.value);

  };

  const handleChange3 = (event) => {
    setSelectedItem3(event.target.value);

  };

  return (
    <>
      <h2>{title}</h2>
      <div className="d-flex flex-row">
        <div className="p-2">
          <select className="form-select" onChange={handleChange1}>
            <option value="0">Select a profile type</option>
            {Object.entries(data).map(([key]) => {
              return <option key={key} value={key}>{key}</option>
            })}
          </select>
        </div>
        <div className="p-2">
          <select className="form-select" onChange={handleChange3}>
            <option value="0">Select a remaining contract length period</option>
            {Object.entries(data2).map(([key]) => {
              return <option key={key} value={key}>{key}</option>
            })}
          </select>
        </div>
        <div className="p-2">
          <select className="form-select" onChange={handleChange3}>
            <option value="0">Select a length of stay period</option>
            {Object.entries(data3).map(([key]) => {
              return <option key={key} value={key}>{key}</option>
            })}
          </select>
        </div>
      </div>
      {selectedItem1 !== null && selectedItem1 !== '0' && <ul className="list-group list-group-flush">
        {data[selectedItem1].map(detail => {
          return <li key={detail.name} className="list-group-item">{detail.name}</li>
        }
        )}
      </ul>}
    </>
  )
}

export default DistributionDetails