import {gql} from 'graphql-request';

export const USER_LOGIN = gql `query auth(
  $email: String!, 
  $password: String!
) {
  auth(
    email: $email, 
    password: $password
  ) {
    result,
    user_id,
    user_name,
    clearance_level
  }
}`;
