import React, {useState, useEffect} from 'react'
import PageHeading from '../components/common/PageHeading.js';
import MonthlyIncomeCalendar from '../components/income/MonthlyIncomeCalendar.js';
import IncomeNavigationTabs from '../components/income/IncomeNavigationTabs.js';
import GraphQLClientWithCredentials from '../services/GraphQLClientWithCredentials.js';
import Loading from '../components/common/Loading.js';
import { GET_STATS, GET_INCOME } from '../graph-ql/dashboard.js';
import IncomeOverview from '../components/income/IncomeOverview.js';
import { convertToIncomeData, getIncomeByMonth, getIncomeForCurrentMonthByUnit } from '../services/Income.js';
import FinancialInsights from '../components/income/FinancialInsights.js';

const FinancialsPage = () => {
  const [currentPage, setCurrentPage] = useState('overview');
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [currentMonthUnitIncome, setCurrentMonthUnitIncome] = useState({});
  const [incomeByMonth, setIncomeByMonth] = useState({});
  const [stats, setStats] = useState(null);

  const graphQLClient = GraphQLClientWithCredentials();

  useEffect(() => {
    const fetchData = async () => {
      const response = await graphQLClient.request(GET_INCOME);
      const stats_response = await graphQLClient.request(GET_STATS);

      setStats(stats_response.dashboard);

      setData(convertToIncomeData(response.unit_income_list, stats_response.dashboard.monthly_income));
      setIncomeByMonth(getIncomeByMonth(response.unit_income_list));
      setCurrentMonthUnitIncome(getIncomeForCurrentMonthByUnit(response.unit_income_list));
      setIsLoaded(true);
    };

    fetchData();
  }, []);

  if (!isLoaded) {
    return <Loading/>;
  }

  return (
    <>
      <PageHeading heading="Financials"/>
      <IncomeNavigationTabs
        currentPage={currentPage}
        handleClick={setCurrentPage}/>
      {incomeByMonth && currentPage === 'overview' && <IncomeOverview data={incomeByMonth} />}
      {currentPage === 'calendar' && <MonthlyIncomeCalendar />}
      {currentPage === 'insights' && <FinancialInsights data={data} goal={stats.monthly_income_goal} currentMonthUnitIncome={currentMonthUnitIncome} />}
    </>

  )
}

export default FinancialsPage
