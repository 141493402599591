import {gql} from 'graphql-request';

export const DELETE_UTILITY = gql `mutation utility_delete(
  $id: ID!
) {
  utility_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UTILITY = gql `query utility_list($type: String, $country_id: ID){
  utility_list(type: $type, country_id: $country_id) {
    id, 
		country {
			id, 
			name
		}, 
		name, 
		type, 
    type_display,
		platform_url, 
		phone,
    units {
      id,
      identifier
    }
  }
}`;

export const VIEW_UTILITY = gql `query view_utility(
  $id: ID!
){
  utility(id: $id) {
    id, 
		country {
			id, 
			name
		}, 
		name, 
		type, 
		platform_url, 
		phone
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION