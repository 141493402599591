import React from 'react'
import LeftNavigationItem from './LeftNavigationItem.js';
// import LeftNavigationItemWithAccordion from './LeftNavigationItemWithAccordion.js';

const LeftNavigation = () => {

  // const toolsMenuItems = [
  //   {
  //     name: 'Yield calculator',
  //     url: 'tools/yield-calculator',
  //     key: 'yield-calculator'
  //   }, {
  //     name: 'Mortgage calculator',
  //     url: 'tools/mortgage-calculator',
  //     key: 'mortgage-calculator'
  //   },
  // ];

  return (
    <>
      <ul className="nav flex-column">
        <LeftNavigationItem urlPath='' itemName='Dashboard'/>
        <LeftNavigationItem urlPath='financials' itemName='Financials'/>
        <LeftNavigationItem urlPath='properties' itemName='Properties'/>
        <LeftNavigationItem urlPath='tenants' itemName='Tenants'/>
        <LeftNavigationItem urlPath='utilities' itemName='Utilities'/>
        <LeftNavigationItem urlPath='services' itemName='Services'/>
        <LeftNavigationItem urlPath='real-estate-agents' itemName='Real estate agents'/>
        <LeftNavigationItem urlPath='notaries' itemName='Notaries'/>
        <LeftNavigationItem urlPath='listings' itemName='Ad listings'/>
        <LeftNavigationItem urlPath='reports' itemName='Reports'/>
        <LeftNavigationItem urlPath='watchlist' itemName='Properties watchlist'/>
        <LeftNavigationItem urlPath='propfinder' itemName='PropFinder ©'/>
        <LeftNavigationItem urlPath='propchecker' itemName='PropChecker ©'/>
        <LeftNavigationItem urlPath='market-pulse' itemName='Market Pulse'/>
        <LeftNavigationItem urlPath='investment-simulator' itemName='Investment simulator'/>
        {/* <LeftNavigationItemWithAccordion itemName='Calculators'
          items={toolsMenuItems}/> */}
        {/* <LeftNavigationItem urlPath='mortgages' itemName='Mortgages'/> */}
        <LeftNavigationItem urlPath='tax-documents' itemName='Tax documents'/>
        <LeftNavigationItem urlPath='template-documents' itemName='Template documents'/>
        <LeftNavigationItem urlPath='settings' itemName='Settings'/>
      </ul>
    </>
  )
}

export default LeftNavigation
