import React from 'react'
import { formatDays } from '../../../services/Format';
import TooltipComponent from '../../common/TooltipComponent.js';
import { redTriangleIcon, yellowTriangleIcon } from '../../../assets/svg/icons.js';

const UnitInventoryTimeToReplace = ({value}) => {
  let icon = null;
  if (value <= 90) {
    icon = yellowTriangleIcon;
  } else if (value <= 30) {
    icon = redTriangleIcon;
  }
  return (
    <p>
      {icon && <TooltipComponent 
        tooltipTriggerText={formatDays(value)}
        icon={icon}
        tooltipText={`This item is expected to need replacement${value > 0 ? ' in' : ''} ${formatDays(value).toLowerCase()}`}
        iconPosition='left'
      />}
    </p>
  )
}

export default UnitInventoryTimeToReplace