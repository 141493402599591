import React from 'react'
import { Link } from 'react-router-dom'

const UnitIdentifier = ({identifier, id}) => {
  return (
    <Link 
      to={`/property/view/${id}`}
      state={{id: id}}>
      {identifier}
    </Link>
  )
}

export default UnitIdentifier
