import React from 'react';
import ListingCard from './ListingCard.js';
import ComponentWithLoading from '../common/ComponentWithLoading.js';

const ListingList = ({data, error, loading}) => {
  return <ComponentWithLoading data={data}
    error={error}
    loading={loading}
    componentToRender={ListingCard}/>
};

export default ListingList;
