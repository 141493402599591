import React from 'react'

const PropFinderPage = () => {
  return (
    <>
      <div>PropFinder</div>
      <p>
        It will answer the question: what is the property on sale with the highest rentability in a given area / city?
      </p>
    </>
  )
}

export default PropFinderPage
