import React, {useState} from 'react'

const FormCheckboxElement = ({
  name,
  initialValue, 
  id, 
  onChangeHandler, 
  additionalClassName,
  isDisabled,
}) => {
  if (initialValue === null || initialValue === undefined) {
    initialValue = 'off';
  }
  
  const [checked, setChecked] = useState(initialValue);
  return (
    <input type="checkbox"
      checked={checked === 'on'}
      onChange={
        (e) => {setChecked(e.target.checked ? 'on' : 'off'); onChangeHandler(e);}
      }
      value={checked === 'on' ? 'on' : 'off'}
      name={name}
      className={`form-check-input ${additionalClassName}`}
      id={id}
      disabled={isDisabled}
    />
  )
}

export default FormCheckboxElement
