import React, {useState} from 'react'

const FormHiddenElement = ({
  name, 
  value,
}) => {
  if (value === null || value === undefined) {
    value = '';
  }

  return (
    <input type="hidden"
      value={value}
      name={name}/>
  )
}

export default FormHiddenElement
