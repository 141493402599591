import {gql} from 'graphql-request';

export const DELETE_UNIT_INVENTORY = gql `mutation unit_inventory_delete(
  $id: ID!
) {
  unit_inventory_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_INVENTORY = gql `query unit_inventory_list($unit_id: ID){
  unit_inventory_list
    (unit_id: $unit_id){
    id,
		title, 
		description, 
		purchase_timestamp, 
		lifetime_in_days,
		expected_days_until_replacement_needed,
		came_with_unit,
  }
}`;

export const VIEW_UNIT_INVENTORY = gql `query view_unit_inventory(
  $id: ID!
){
  unit_inventory(id: $id) {
    id, 
		unit {
			id, 
			identifier, 
			address, 
			purchase_timestamp, 
			purchase_price, 
			identifier_image_url, 
			monthly_income, 
			monthly_income_weight, 
			time_in_portfolio_in_years, 
			expected_anual_yield, 
			lifetime_income, 
			ytd_income, 
			lifetime_expenses, 
			ytd_expenses, 
			lifetime_net_profit, 
			ytd_net_profit, 
			current_number_of_tenants, 
			image_urls
		}, 
		unit_expense {
			id, 
			type, 
			billing_timestamp, 
			value, 
			description
		}, 
		title, 
		description, 
		purchase_timestamp, 
		lifetime_in_days
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION