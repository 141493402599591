import {gql} from 'graphql-request';

export const LIST_COUNTRY = gql `query country_list{
  country_list
    {
    id, 
		name
  }
}`;

export const VIEW_COUNTRY = gql `query view_country(
  $id: ID!
){
  country(id: $id) {
    id, 
		name
  }
}`

export const LIST_COUNTRIES_WITH_UTILITIES = gql `query utility_list{
  utility_list{
    country {
			id, 
			name
		}, 
  }
}`;

// START MANUAL SECTION



// END MANUAL SECTION