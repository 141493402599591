import { useCallback } from 'react';
import { DOWNLOAD_URL } from '../Config.js';

export const useFileDownload = () => {
  const downloadFile = useCallback((data) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = DOWNLOAD_URL;
    form.target = '_blank'; 

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  }, []);

  return downloadFile;
};