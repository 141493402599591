import {gql} from 'graphql-request';

export const DELETE_UNIT_LISTING = gql `mutation unit_listing_delete(
  $id: ID!
) {
  unit_listing_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_UNIT_LISTING = gql `query unit_listing_list($unit_id: ID, $unit_ids: [ID]){
  unit_listing_list(
    unit_id: $unit_id,
    unit_ids: $unit_ids,
  ){
    id, 
		real_estate_agent {
			id, 
			name, 
			email, 
			phone, 
		}, 
		date, 
		asking_price, 
		status, 
		visit_count, 
		offer_count, 
		asking_price_history,
    ad_urls,
  }
}`;

// START MANUAL SECTION

// END MANUAL SECTION