import React, {useState} from 'react'
import Loading from '../Loading.js';
import ActionCaller from '../../../services/ActionCaller.js';

const ConfirmRentReceivedAction = ({
  label = 'Confirm rent received for this month', 
  objectID,
  postProcessorFunc,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <a 
      className="dropdown-item"
      onClick={() => ActionCaller({
        object_id: objectID, 
        action_type: 'confirm_rent_received', 
        loadingFunc: (v) => setIsLoading(v),
        postProcessorFunc: postProcessorFunc,
      })}
    >
      <span>{label}</span>
      {isLoading && <span className='ms-1'><Loading size="small"/></span>}
      </a>
  )
};

export default ConfirmRentReceivedAction;
