import React, {useState} from 'react'
import UnitImages from '../fragments/UnitImages.js';
import UnitViewContentButtons from './navigation/UnitViewContentButtons.js';
import UnitMediaAddPage from '../../../pages/property/media/UnitMediaAddPage.js';

const UnitViewMedia = ({data}) => {
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel');
  const [subpage, setSubpage] = useState('list');
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  
  const refreshData = () => {
    setShouldRefreshData(true);
    // setIsLoaded(false);
  }

  return <>
    <UnitViewContentButtons 
      addButtonLabel={'Add media assets'} 
      viewButtonLabel={'View media assets'} 
      cancelButtonLabel={cancelButtonLabel}
      subpage={subpage}
      setSubpageFn={setSubpage}
    />
    {subpage === 'list' && <UnitImages 
      imageURLs={data.image_urls}
    />}
    {subpage === 'add' && <UnitMediaAddPage 
      formSubmitCallbackFn={refreshData} 
      extraData={{'unit-id': data.id}}
    />}
  </>
}

export default UnitViewMedia
