import React from 'react';
import '../../styles/payout_days.css';
import { formatPriceInEUR } from '../../services/Format';

const PayoutDays = ({ payoutDaysWithAmounts }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0 for January, 11 for December
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate(); // Current day of the month

  // Get the number of days in the current month
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Weekday names starting from Monday
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  // Get the weekday of the 1st of the current month (adjusting for Monday start)
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const adjustedFirstDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

  // Create an array of days for the month
  const daysArray = Array.from({ length: daysInMonth }, (_, index) => index + 1);

  return (
    <div className="calendar-container">
      {/* Render day names */}
      <div className="day-names-row">
        {dayNames.map((dayName, index) => (
          <div key={index} className="day-name">
            {dayName}
          </div>
        ))}
      </div>

      {/* Render day numbers */}
      <div className="days-grid">
        {/* Empty slots before the first day of the month */}
        {Array.from({ length: adjustedFirstDay }).map((_, index) => (
          <div key={`empty-${index}`} className="day-box empty"></div>
        ))}

        {/* Days of the month */}
        {daysArray.map(day => (
          <div
            key={day}
            className={`day-box ${payoutDaysWithAmounts[day] ? 'payout-day' : ''} ${day === currentDay ? 'current-day' : ''}`}
          >
            <span>{day}</span>
            {payoutDaysWithAmounts[day] && (
              <div className="amount">
                {formatPriceInEUR(payoutDaysWithAmounts[day])}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PayoutDays;
