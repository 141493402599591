import React from 'react'
import PageHeading from '../../../components/common/PageHeading.js'
import Form from '../../../services/Form.js'
import RouteActionType from '../../../enums/RouteActionType.js'

const UnitContactMutatePage = ({action, id, extraData, formSubmitCallbackFn}) => {
  return (
    <>
      <PageHeading heading={action === RouteActionType.edit ? 'Update contact' : 'Add contact'}/>
      <Form 
        formID="unit-contact-mutate"
        objectID={parseInt(id) || 0}
        extraData={extraData}
        successMessage={action === RouteActionType.edit ? 'Contact was updated' : 'Contact was added'}
        formSubmitCallback={formSubmitCallbackFn}
      />
    </>
  )
}

export default UnitContactMutatePage
