import React from 'react'
import { pencilIcon } from '../../assets/svg/icons';

const PageHeading = ({heading, buttonLabel, onClickHandler, actions}) => {
  return (
    <div className="row mb-3">
      <div className="col-8 d-flex">
        <h2>{heading}</h2>
        <div className="col-5 nav-item dropdown ms-3 mt-2">
        {actions && <>
          <a
            className="translate-middle"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false">
            {pencilIcon}
          </a>
          <ul className="dropdown-menu">{actions}</ul>
        </>}
      </div>
      </div>

      {
      buttonLabel && <div className="col-4">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button className="btn btn-primary" type="button"
            onClick={onClickHandler}>
            {buttonLabel}</button>
        </div>
      </div>
    } </div>

  )
}

export default PageHeading
