import {gql} from 'graphql-request';

export const DELETE_REAL_ESTATE_AGENT = gql `mutation real_estate_agent_delete(
  $id: ID!
) {
  real_estate_agent_delete(id: $id) {
    data {
      id
    },
    result,
    exceptions {
        property,
        failed_rules {
            message
        }
    }
  }
}`

export const LIST_REAL_ESTATE_AGENT = gql `query real_estate_agent_list($city_id: ID){
  real_estate_agent_list(city_id: $city_id)
    {
    id, 
		city {
			id, 
			name
		}, 
		name, 
		email, 
		person_of_contact,
    phone,
    buy_commission,
    rent_commission,
  }
}`;

export const VIEW_REAL_ESTATE_AGENT = gql `query view_real_estate_agent(
  $id: ID!
){
  real_estate_agent(id: $id) {
    id, 
		city {
			id, 
			name
		}, 
		name, 
		email, 
		person_of_contact
  }
}`

// START MANUAL SECTION



// END MANUAL SECTION