import React from 'react'
import ImageGrid from '../../common/ImageGrid.js';

const UnitImages = ({imageURLs}) => {
  if (!imageURLs.length) {
    return <div className="alert alert-info" role="alert">
      There is no media!
    </div>;
  }
  return (
    <ImageGrid images={imageURLs} />
    // <div className="container text-center">
    //   <div className="row row-cols-4">
    //     {
    //     imageURLs.map(image_url => {
    //       return <div className="col"
    //         key={image_url}>
    //         <img src={image_url}
    //           className="img-fluid rounded-start"
    //           alt="..."/></div>
    //   })
    //   } </div>
    // </div>
  )
}

export default UnitImages
